import React from 'react';
import {  Link } from 'react-router-dom';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';

import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


import { makeStyles } from '@material-ui/core/styles';



import Conn_Signing from './Conn_Signing';

import PM_FormController from "components/PM_FormController";



const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
<app onSubmit="props.getDoPasswordReset()(state.values, props.otp);">
<group>

  <question id="password" name="Password">
    <config type="password" />
    <validator id="isRequired" />
    <validator id="isMinLength">
      <config message="Must be at least 6 characters, Please make it more secure" value="6" />
    </validator>
  </question>

  <question id="password2" name="Password Confirmation">
    <config type="password" />
    <validator id="isRequired" />
    <validator id="isEqual">
      <config message="Your passwords don’t match" dynamicValue="values['.password']" />
    </validator>
  </question>

  </group>
</app>
`;

const Reset = (props) => {

  function getDoPasswordReset() {
    return doPasswordReset;
  }


  const FormController = PM_FormController({xmlData: xmlData, getDoPasswordReset: getDoPasswordReset, otp:props.otp}); //getDoLogin:getDoLogin
  const {  doPasswordReset  } = Conn_Signing(FormController.dispatch);


  let form = (FormController.Flow.children.length > 0 && (
      <form {...FormController.getFormProps()}>

          <Grid container direction="column" alignItems="stretch" spacing={2}>
        <Grid container item spacing={1} alignItems="flex-end">
          <Grid item>
              <LockIcon />
          </Grid>
          <Grid item xs >
            <TextField
               {...FormController.getFieldProps(FormController.getItemByKey('.password'))}
              variant="standard"
              fullWidth
            />
        </Grid>
        </Grid>
        <Grid container item spacing={1} alignItems="flex-end">
          <Grid item>
              <LockOutlinedIcon />
          </Grid>
          <Grid item xs >
            <TextField
               {...FormController.getFieldProps(FormController.getItemByKey('.password2'))}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>


          <Grid item xs={12} >
          <Box mt={2}>
            <Button variant="contained" fullWidth color="primary" type="submit">
               Reset Password
             </Button>
            </Box>
         </Grid>
        </Grid>

      </form>
    ));

    if ('passwordReset' in FormController.state.values) {
      form = <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>Your password has been reset.</Typography>
        </Grid>
      <Grid item>
        <Typography>You can now <Link to='/auth/login'>login</Link> using the password you just specified.</Typography>
        </Grid>
      </Grid>;
    }


    return form || <></>;
}

export default Reset;
