// import React from 'react';

import * as externalValidators from 'calidators';
import  WordLimit from './validators/WordLimit';
import  isComplete from './validators/isComplete';
//
// export const ValidationContext = React.createContext({});
//
// export const ValidationProvider = props => {
//   const context = (props.context) ? props.context : PM_Validation(props.config, props.externalState, props.name);
//   const memoizedContext = useMemo(() => context, [context]);
//   return (
//     <ValidationContext.Provider value={memoizedContext}>
//       {props.children}
//     </ValidationContext.Provider>
//   );
// };
//
// export function useValidatorContext() {
//   const context = React.useContext(ValidationContext);
//   return context;
// }

const validators = {...externalValidators, hasWordLimit:WordLimit, isComplete: isComplete};

function validateQuestion(values, question, getKey) {
  const value = values[getKey(question)];
  let errorMessage = null;
  question.validators.some(validator => {
    const config = (validator.configs.length > 0) ? validator.configs[0] : {message: 'Please enter a value'};
    if (validator.id === 'isBlacklisted') {
      config.blacklist = config.value.split('|').map(item => eval(item));
    }
    if (validator.id === 'isEqual' && 'dynamicValue' in config) {
      config.value = eval(config.dynamicValue);
    }
    if (validator.id === 'isMinLength') {
      config.length = parseInt(config.value);
    }
    if (validator.id === 'isExactLength') {
      config.length = parseInt(config.length);
    }
    const configuredValidator = validators[validator.id](config); //eval(validator.settings)
    errorMessage = configuredValidator(value);
    return errorMessage !== null;
  });
  return errorMessage;
}

function validateQuestions(values, questions, getKey) {

  const errors = {};
  questions.forEach(question => {
      errors[getKey(question)] = validateQuestion(values, question, getKey);
  });
  // for (let fieldName in fieldConfigs) {
  //   const fieldConfig = fieldConfigs[fieldName];
  //   const fieldValue = fieldValues[fieldName];
  //
  //   errors[fieldName] = validateField(fieldValue, fieldConfig);
  // }
  return errors;
}

function validateField(fieldValue = '', fieldConfig) {

  for (let validatorName in fieldConfig.validators) {
    const validatorConfig = fieldConfig.validators[validatorName];
    const validator = validators[validatorName];
    const configuredValidator = validator(validatorConfig);
    const errorMessage = configuredValidator(fieldValue);

    if (errorMessage) {
      return errorMessage;
    }

  }
  return null;
}

function validateFields(fieldValues, fieldConfigs) {

  const errors = {};
  for (let fieldName in fieldConfigs) {
    const fieldConfig = fieldConfigs[fieldName];
    const fieldValue = fieldValues[fieldName];

    errors[fieldName] = validateField(fieldValue, fieldConfig);
  }
  return errors;
}

function processServerErrors(state, config, dispatch) {
  // const fieldConfigs = config.fields;
  for (let field in state.serverErrors) {
    const error = state.serverErrors[field];
    switch (error.type) {
      case 'duplicate': // add to blacklist
        dispatch({
          type: 'change',
          //payload: { ['.'+field+'_isnotequal'] : [...(state.values['.'+field+'_isnotequal'] || []), error.value] }
            payload: { ['.'+field+'_isnotequal'] : error.value }
        });
        break;
        case 'incorrect':
          dispatch({
            type: 'change',
            payload: { ['.'+field+'_isnotequal'] : error.value }
          });
        break;
        default:
        break;
    }
    dispatch({
      type: "serverValidate",
      payload: {}
    });
  }
  return config;
}

// find the errors that need displaying
function processErrors(state, config, overrideConfig = null) {
  // if (Object.keys(state.serverErrors).length !== 0) {
  //   return state.serverErrors;
  // }
  const showErrors = overrideConfig || 'hybrid';

  if (showErrors === 'always') {
    return state.errors;
  }

  if (showErrors === 'blur') {
    return Object.entries(state.blurred)
      .filter(([, blurred]) => blurred)
      .reduce((acc, [name]) => ({
        ...acc,
        [name]: state.errors[name]
      }), {});
  }

  if (showErrors === 'hybrid') {
    return Object.entries(state.errors)
      .reduce((acc, [fieldName]) => {
        switch ('blur') { //config.fields[fieldName].showErrors
          case 'always' :
            return {
              ...acc,
              [fieldName]: state.errors[fieldName]
            }
          case 'blur' :
            // return (state.touched[fieldName] || state.submitted) ? {
            return (true || state.submitted) ? {
                  ...acc,
                  [fieldName]: state.errors[fieldName]
                } : acc;
          case 'submit' :
            return (state.submitted) ? {
              ...acc,
              [fieldName]: state.errors[fieldName]
            } : acc;

            default:
            break;
        }
        return acc;
      }, {});
  }
  // return state.submitted ? state.errors : {};
  return state.submitted ? state.errors : {};
}


const PM_Validation = (props) => {

  // const [ postSubmit, setPostSubmit ] = React.useState();



//   const getErrors = () => {
// console.log("%%%%%%%%%%%%%%%%%%%");
//     return validateFields(eState.values, config.fields);
//   }

  const numberOfErrorFileds = (config, state) => {
    const errors = validateFields(state.values, config.fields);
    const count = Object.values(errors).filter((val) => (val !== null)).length;
    return count;
  };

  // const doValidate = (config, state, dispatch) => {
  //
  //   const errors = validateFields(state.values, config.fields);
  //   // dispatch({
  //   //   type: 'validate',
  //   //   payload: errors
  //   // });
  //   // if (postSubmit) {
  //   //   if (Object.values(errors).every(error => error === null) ) { postSubmit['v'](); }
  //   //   setPostSubmit();
  //   // }
  // }

  // useDeepCompareEffect(() => {
  //
  //   const valid = doValidate();
  //
  // }, [eState.values, state.blurred, config.fields, state.submitted, state.serverErrors]);



  // const errors = useMemo(() => {
  //   const e = getErrors(state, config);
  //   // eDispatch({
  //   //   type: 'change',
  //   //   payload: { ['errorCount_'+name] : Object.values(e).filter((val) => (val !== null)).length }
  //   // });
  //   return e;
  //   }, [
  //   state.errors,
  //   state.blurred,
  //   state.submitted,
  //   state.config
  // ]);

  // useMemo(() => {  processServerErrors(state, config, dispatch); }, [
  //   state.serverErrors
  // ]);

  // const isFormValid = useMemo(
  //   () => Object.values(errors).every(error => error === null) && (true || state.submitted),
  //   [errors]
  // );
  // console.log(config.help_text);
  // const hintText = config.fields[pmKey.getConfigKey()].help_text && {
  //
  //   )
  // };

//   const getLabel = (name, help) => {
//     if (!help || true) return name;
//     return (
//       // endAdornment: (
//       //
//       <>{name}
//         <Tooltip title={help} aria-label={help} className={classes.help_icon}>
//           <HelpIcon fontSize="inherit" />
//         </Tooltip>
//         </>
//       // </InputAdornment>
//   // )
// )
// }


  // const blurredErrors = useMemo(() => {
  //   const returnValue = {};
  //   for (let fieldName in state.errors2) {
  //     returnValue[fieldName] = state.blurred[fieldName] ?
  //       state.errors[fieldName] :
  //       null;
  //   }
  //   return returnValue;
  // }, [state.errors, state.blurred]);

  return {
    // blurredErrors: blurredErrors,
    numberOfErrorFileds,
    processErrors,
    processServerErrors,
    validateFields,
    validateField,
    validateQuestions
  };
}


export {
  PM_Validation
};
