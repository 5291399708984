import React from 'react';

import { makeStyles, createTheme } from '@material-ui/core/styles';

import logo from 'icons/Festivale Stacked 2025 Logo_Grey.png';

import ViewApplication from 'components/summary/ViewApplication';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  logo: {
    padding: "32px",
    background: "#fff",
    position: "absolute",
    top: "0",
    left: 0 + "px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
    transformOrigin: "0 -20px",

  },
  logoShadow: {
    padding: "32px",
    background: "transparent",
    position: "absolute",
    zIndex: 1,
    top: "0",
    left: theme.spacing(3),
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
    transformOrigin: "0 -20px",
    boxShadow: "0 0 10px rgba(0,0,0,.5)",
    transition: "all 0.5s ease-out"
  },
  header: {
    "& * ": {
      transition: "all 0.5s ease-out"
    },
    zIndex: 10,
    borderBottom: '10px solid #fff',
    borderRight: '10px solid #fff',
    borderBottomRightRadius: '30px',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: 'auto'
  },
  headerIcon: {
    background: "#fff",
    color: "#793478",
    padding: "8px",
    marginLeft: "8px",
    "&:hover": {
      background: "#ee680f"
    }
  },

  buttonDark: {
    background: '#333',
    color: '#fff'
  },

  page: {
    padding: theme.spacing(5)
  }

}));


const outerTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "#793478",
    },
    secondary: {
      main: "#ee680f",
    },
    error: {
      main: "#ee680f"
    },
    text: {
      secondary: "#000",
      primary: "#000"
    }
  },
  shape: {
    borderRadius: "15px"
  },
  typography: {
    h5: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
      display: "flex",
      alignItems: "center",
      "&::after": {
        content: '""',
        borderTop: "2px solid #ee680f",
        margin: "0 0 0 10px",
        flex: "1 0 20px"
      }
    },
    h6: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
      fontSize: '24px'
    },
    h4: {
      fontFamily: 'Rambla',
      fontWeight: 'bold',
      fontSize: '18px'
    },
    body1: {
      '& a': {
        color: '#ee680f'
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "30px",
        padding: "1px 50px"
      }
    }
  }

});



export default function SummaryPage(props) {

  const [scrollPos, setScrollPos] = React.useState(0);

  const classes = useStyles();
  const myProps = {
    match: {
      params: { 
        id : props.id,
        year: props.year
      },
      url: ''
    },
    id: props.id
  };


  return (
    // <ThemeProvider theme={outerTheme}>
    //   <FormControllerProvider xmlData={xmlData}>
    //     <UserFeedbackProvider>
    //       <AdminHelpersProvider>
    //         <CookiesProvider>
<>
              <div style={{position: 'relative', height: '216px'}}>
                <div className={classes.logoShadow}>
                  <img src={logo} width="116px" height="180px"  />
                </div>
              </div>
             
              

              <div className={classes.page}>
                <ViewApplication {...myProps} doEdit={false} />
              </div>
    </>
  //           </CookiesProvider>
  //         </AdminHelpersProvider>
  //       </UserFeedbackProvider>
  //     </FormControllerProvider>
  //   </ThemeProvider>
  );
}
