import React from 'react';

import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useValidatorContext } from "components/PM_Validator2";

import { Grid, IconButton, Button, Paper, CardMedia, Modal, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CloseIcon from '@material-ui/icons/Close';


import useConfirmation from 'components/application_forms/Confirmation';

import Conn_Application from 'components/application_forms/Conn_Application';

import { useFormControllerContext } from "components/PM_FormController";

import  { useUserFeedback } from "components/UserFeedback";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    height: 240,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",

    backgroundBlendMode: "multiply",
    padding:  theme.spacing(2)+"px "+theme.spacing(3)+"px",
    color: "#fff"
  },
  icons: {
    alignSelf: "flex-end",
    "& > *": {
      marginLeft: theme.spacing(2)
    }
  },
  addPhoto: {
    width: '100%',
    height: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fullPhoto: {
    width: '100%',
    height: '100%'
  },
  heading: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 },
 yes: {
   background: '#793478',
   color: '#fff',
   borderRadius: '15px'
 },
 no: {
   background: '#333',
   color: '#fff',
   borderRadius: '15px'
 }

}));

export default function Input_Photo(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const FormController = useFormControllerContext();
  const { showMessage } = useUserFeedback();
  const { uploadFile, getFileInfo, getImageURL } = Conn_Application(FormController.state, FormController.dispatch, showMessage);
  const [fullPhoto, setFullPhoto] = React.useState(false);

  const question = props.question;

  const { ConfirmationWindow, askForConfirmation } = useConfirmation({title:'remove image', task: 'remove photo from your application', onYes:() => {removeFile(question)} });

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        text: {
          color: "#000",
          '&::after': {
            display: 'none'
          }
        }
      }
    }
  });


  const removeFile =(question) => {
    FormController.dispatch({
      type: 'change',
      payload: { [FormController.getKey(question)]: null,
        [FormController.getKey(question)+'_filename']: null
    }});
    // showMessage('file successfully removed', 'info')
  };




  const FullImage1= (props) => (
    <Modal
      open={fullPhoto}
      onClose={() => {setFullPhoto(false);}}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <img src={props.src} className={classes.fullPhoto}/>
    </Modal>
  );
  const FullImage= (props) => (
    <Dialog
      open={fullPhoto}
      onClose={() => {setFullPhoto(false)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.heading}>
      <Grid direction="row" container justify="space-between" alignItems="center" spacing={0}>
        <Grid item><Typography variant="h6">Preview Image</Typography></Grid>
        <Grid item><IconButton edge="end" size="small" color="inherit" onClick={() => {setFullPhoto(-1)}} aria-label="close">
                <CloseIcon fontSize="small"  />
              </IconButton></Grid>
      </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <img src={props.src} className={classes.fullPhoto} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  let style = {
      backgroundImage: 'url('+getImageURL(FormController.state.values[FormController.getKey(question)])+')',
      backgroundColor:  (FormController.state.values[FormController.getKey(question)]) ?"rgba(51,51,51,0.5)":"#fff"
  };


    return (
      <>
        <MuiThemeProvider theme={theme}>
          <DropzoneDialog
            acceptedFiles={['image/*']}
            cancelButtonText={"cancel"}
            submitButtonText={"save and upload"}
            maxFileSize={5000000}
            open={open}
            onClose={() => setOpen(false)}
            onSave={(files) => {
              uploadFile(files[0], FormController.getKey(question), true);
              setOpen(false);
            }}
            dialogTitle="Select Photo"
            dropzoneText="Drag and drop here, or browse files"
            showPreviews={true}
            showFileNamesInPreview={false}
         />
       </MuiThemeProvider>
      <ConfirmationWindow />

       <Paper
        className={classes.root}
        style={style}>

           { !FormController.state.values[FormController.getKey(question)] && <div onClick={() => setOpen(true)} className={classes.addPhoto}><AddAPhotoIcon color="primary" /></div>}

         { FormController.state.values[FormController.getKey(question)] && <>
           <Typography noWrap>{FormController.state.values[FormController.getKey(question)+'_filename'] || getFileInfo(FormController.state.values[FormController.getKey(question)], question, FormController.getKey)}</Typography>
           <div className={classes.icons}>
             <ZoomInIcon onClick={ () => { setFullPhoto(true); }} />
             <DeleteForeverIcon onClick={ () => { askForConfirmation(); } } />
           </div>
         </> }
       </Paper>
       <FullImage src={getImageURL(FormController.state.values[FormController.getKey(question)])} />
        {/* errors[props.fieldKey] && <p>{errors[props.fieldKey]}</p>*/}
      </>
    )
  }
