import React from 'react';

// import Stallholder2 from './Stallholder2';
// import Stallholder from './Stallholder';
// import TextInput from './inputs/TextInput';

import SkipNextIcon from '@material-ui/icons/SkipNext';

import SaveIcon from '@material-ui/icons/Save';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import { AppBar, Box,  Grid, Button, Paper } from '@material-ui/core';

import {  makeStyles } from '@material-ui/core/styles';

// import './Appform_layout.css';
// import ProgressBar from '../progressbar/ProgressBar';



import  { useUserFeedback } from "components/UserFeedback";


import GetStepper from './GetStepper';


// import { FilteredStallholderDS } from 'config/StallholderDS';

import DrawItem from './DrawItem';

import Conn_Application from "./Conn_Application";
// import {  getApplicationFlow, getFilteredStallholderDS, isRepeater2, ValidationProvider2, ShouldDisplay } from "../StallholderApplication";

import { useFormControllerContext } from "components/PM_FormController";

// import Flow_Step from '../PM_Flow/Flow_Step';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6
  },
  reversed: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: '#793478',
    '& *': {
      color: '#fff'
    }
  },
  form: {
    width: '100%',
    padding: theme.spacing(6),
    marginBottom: '200px',
    'box-sizing': 'border-box',
    '& .MuiFormHelperText-root': {
      color: '#888',
      fontSize: "10px",
      marginLeft: 0,
      "&.Mui-error": {
        color: "#ee680f",
        fontWeight: "bold"
      }
    }
  },
  button: {
   margin: theme.spacing(1)
 },
 magicAppbar: {
   zIndex: 4,
   borderBottom: "9px solid #fff",
   height: "73px",
   marginBottom: theme.spacing(4)
 },
 theButtons: {
   position: 'relative',
   zIndex: 8
 }
}));



function ApplicationForm(props) {
  const { showMessage } = useUserFeedback();


  const classes = useStyles();


  const FormController = useFormControllerContext();
  const { state, dispatch, shouldDisplay } = FormController;
  const [flow, setFlow] = React.useState({...FormController.Flow});
  const [ autoSaveId, setAutoSaveId ] = React.useState('');

  const { getLatestStallholder, saveStallholderApplication } = Conn_Application(state, dispatch, showMessage);


  const doDebugSave = () => {
    // turn off saving
    if (FormController.allowedToEdit()) {
      saveStallholderApplication();
    }
  };


  React.useEffect(() => {   
    // getLatestStallholder(FormController.initRepeaters);
    // console.log('set interval for save');
    const interval = setInterval(() => { console.log('do autosave'); doDebugSave()}, 1000 * 60 * 5);
    // console.log('set internval ', interval);
    return () => { 
      // console.log('clear internval ', interval);
      clearInterval(interval);
    }
  }, [state]
  );
  React.useMemo(() => {   
    getLatestStallholder(FormController.initRepeaters);

  }, []
  );

  const { drawStepper, activeStep, handleChangeStep } = GetStepper(saveStallholderApplication);

  let screen_tabs = FormController.Flow.children.filter((screen, index) => index === activeStep).map((screen) => {
    return (
        // <ValidationProvider2 key={screen.name} name={screen.name}>
        // <ValidationProvider key={screen.name} config={getFilteredStallholderDS(screen.name)} externalState={{eState: state, eDispatch:dispatch }} name={screen.name}>
          <FormContainer key={screen.name} classes={classes}>
          {

             screen.children
              .filter((group) => {
               return shouldDisplay(group) }) //&& group.children.filter((n) => shouldDisplay(n)).length > 0;
              .map((group) => (
               <Paper key={group.name} className={('className' in group) ? classes[group.className] : classes.paper}>
                  <DrawItem item={group} updateFlow={setFlow} />
               </Paper>
            ))
          }
          <Box display="flex" justifyContent="center" className={classes.theButtons}>
          <Button
            variant="contained"
             disabled={activeStep === 0}
             onClick={() => handleChangeStep(activeStep - 1)}
             startIcon={<SkipPreviousIcon />}
             style={{background: "#333",color: (activeStep === 0) ? "#666" : "#fff"}}
             className={classes.button}>
             Prev Step
           </Button>

           <Button onClick={doDebugSave} variant="contained" color="primary" startIcon={<SaveIcon />} className={classes.button} disabled={!FormController.allowedToEdit()} >
            Save
           </Button>

           <Button
            variant="contained"
            style={{backgroundColor: "#77ad30"}}
            onClick={() => handleChangeStep(activeStep + 1)}
            endIcon={<SkipNextIcon />}
            className={classes.button}
             disabled={activeStep === FormController.Flow.children.length - 2}>
             Next Step
           </Button>
         </Box>
        </FormContainer>

        // </ValidationProvider>

    );
  });



  return (<>
      { drawStepper() }
      <AppBar className={classes.magicAppbar}  position="sticky">
      </AppBar>
      <Grid container>
        <Grid item container xs={12} >
          { screen_tabs }
        </Grid>
      </Grid>
      </>
  )
}






function FormContainer(props) {
    const FormController = useFormControllerContext();
  // const { getFormProps, numberOfErrorFileds } = useValidatorContext();

  return (
    <form {...FormController.getFormProps()} className={props.classes.form}>
      {props.children}
    </form>
  )
}




export { ApplicationForm };
