import React from 'react';

import * as validators from 'calidators';

export default function PM_Conditionals() {
  return <></>;
}

class PM_Key {
  #keyName;
  #keyInstance;
  constructor(inKey, instance=-1) {
    this.keyName = PM_Key.getKeyName(inKey);
    this.keyInstance = instance;
  }


  static isConditional(inKey) {
    return (typeof inKey === 'object');
  }

  static getKeyName(inKey) {
    if (PM_Key.isConditional(inKey)) return Object.keys(inKey)[0];
    return inKey;
  }

  getConfigKey() {
    return this.keyName;
  }

  getUniqueKey() {
    if (this.keyInstance == -1) return this.keyName;
      return this.keyName+':'+this.keyInstance;
    }
  }

  function isRepeater(group) {
    return ('repeater' in group);
  }



  function shouldDisplay2(item, state, instance=-1) {
    if (!isConditional(item)) return true;


    const configKey = getConfigKey(item);
    const targetKey = Object.keys(item[configKey])[0];
    const validatorKey = Object.keys(item[configKey][targetKey])[0];

    const validator = validators[validatorKey];
    const validatorConfig = item[configKey][targetKey][validatorKey];
    const configuredValidator = validator(validatorConfig);


    const errorMessage = configuredValidator(state.values[getUniqueKey(targetKey, instance)]);

    return (errorMessage == null);
  }


  function shouldDisplay(item, state, instance=-1, getItemByKey, getKey) {


    
    if (!isConditional(item)) return true;


    console.log('item conditions', item);
      for (let cID in item.conditions) {
        let condition = item.conditions[cID];
        let targetKey = condition.target;

        // very hackist, only works for 1 parent repeater
        if (targetKey.includes('#')) {
          const inst = getKey(item).split('#')[1].split('.')[0];
          targetKey = targetKey.replace('#', '#'+inst);
        }

        const targetItem = getItemByKey(targetKey);
        if (targetItem === null) continue;
        const validator = validators[condition.validator];
        const validatorConfig = { value: condition.value, message: 'notempty'};
        if (condition.validator === 'isBlacklisted') {
          validatorConfig.blacklist = condition.value.split('|').map(item => eval(item));
        }
        const configuredValidator = validator(validatorConfig);

        const errorMessage = configuredValidator(state.values[getKey(targetItem)]);


      if (errorMessage !== null) return false;
    }
    return true;

  }

  function getConfigKey(inKey) {
    if (isConditional(inKey)) return getConfigKey(Object.keys(inKey)[0]);
    if (isCompoundKey(inKey)) return breakCompoundKey(inKey)[0];
    return inKey;
  }

  function getUniqueKey(inKey, instance=-1) {
    if (isConditional(inKey)) return getUniqueKey(Object.keys(inKey)[0], instance);
    if (isCompoundKey(inKey)) return inKey;
    if (instance != -1) return makeCompoundKey(inKey, instance);
    return inKey;
  }

  function breakCompoundKey(inKey) {
    if (!isCompoundKey(inKey)) return [inKey, -1];
    return inKey.split(':');
  }

  function makeCompoundKey(inKey, instance) {
    return inKey+':'+instance;
  }

  function isConditional(inField) {
    if (typeof inField === 'string') return false; // TODO remove this short circuit
    return ('conditions' in inField && inField.conditions.length > 0);
  }

  function isConditional2(inKey) {
    return (typeof inKey === 'object');
  }

  function isCompoundKey(inKey) {
    return inKey.includes(':');
  }

export { shouldDisplay, isRepeater, PM_Key }
