import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Config from '../../Config.js';

const instance = axios.create({
  withCredentials: true,
  baseURL: Config.server.backend,
  crossDomain: true
})

const getInstance = () => {
  return axios.create({
    withCredentials: true,
    baseURL: Config.server.backend,
    crossDomain: true
  })
}


function Conn_Admin() {
  const history = useHistory();

  function changeStatusByUser(user, newStatus, year, callback) {
    var params = new URLSearchParams();
    params.append('task', 'updateStatus');
    params.append('user', user);
    params.append('status', newStatus);
    params.append('year', year);
    getInstance().post("admin.php",params
      ).then((response) => {

      if ('success' in response.data) {
        callback({appid:response.data.success, user:user, status:newStatus, year:year});
      }
      if ('error' in response.data) {

      }

    });
  }

  function saveStallholderApplication(user_id, year, state, callback) {
    //alert('save stallholder application');
    var params = new URLSearchParams();
    params.append('task', 'saveApplication');
    params.append('user_id', user_id);
    params.append('year', year);
    params.append('status', state.values['.system.status']);
    params.append('payload', JSON.stringify(state.values));
    getInstance().post("admin.php",params
      ).then((response) => {
        // console.log("response.data");
        // console.log(response.data);
      if ('success' in response.data) {
        // console.log(showMessage);
        // showMessage("Save Successful!", "success");
        // setValues({
        //   ...values,
        //   stallholder: new Stallholder(response.data.stallholder)
        //   });
      //  setStallholder(new Stallholder(response.data.stallholder));
            // M.toast({html: 'Application Saved'})
          //setStallholder();
      }
      if ('error' in response.data) {
        // showMessage("Error: "+response.data.error, "error");
      }
      callback();
    });
  }


  // function unlockAndSetFlags(userId, flags) {
  //   var params = new URLSearchParams();
  //   params.append('task', 'flagApplicationFields');
  //   params.append('user_id', userId);
  //   params.append('flags', flags);
  //   getInstance().post(  "admin.php", params
  //   ).then((response) => {
  //     if ('success' in response.data) {

  //       // console.log(response.data);
  //       // callback(response.data.success);
  //     }
  //     alert('Application unlocked and message sent');
  //     history.push('/admin');
  //   });
  // }

  function getNotesByTarget(target_user_id, callback) {
    var params = new URLSearchParams();
    params.append('task', 'getAll');
    params.append('target_user_id', target_user_id);
    getInstance().post(  "notes.php", params
    ).then((response) => {
      if ('data' in response.data) {
        // console.log(response.data);
        callback(response.data.data);
      }
    });

  }

  function createNote(targetUser, note, file_id, callback) {
      var params = new URLSearchParams();
      params.append('task', 'create');
      params.append('target_user_id', targetUser);
      params.append('note', note);
      params.append('file_id', file_id);

      getInstance().post(  "notes.php", params
      ).then((response) => {
        if ('success' in response.data) {
          if (callback !== undefined) callback(response.data.success);
        } 
      });
  }


/* *********************** */
/* File Handling           */
/* *********************** */


  function getFileInfo(id, callback=()=>{}) {
    // var params = new URLSearchParams();
    // params.append('id', id);
    getInstance().get("fileinfo.php", 
      { params: {
        id: id
      }}
    ).then((response) => {
      if ('details' in response.data) {
        callback(response.data.details);
      }
    });
  }


  function getFileURL(id) {
    return Config.server.backend + "readfile.php?id=" + id;
  }

  function uploadFile(file, fieldKey, isImage = false, showMessage=()=>{}, callback=(id)=>{}) {
    var bodyFormData = new FormData();
    bodyFormData.set('submit', 'submit');
    if (isImage) bodyFormData.set('isImage', 'true');
    bodyFormData.append('file', file);
    axios({
      method: 'post',
      url: Config.server.backend + "upload.php",
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
      crossDomain: true,
    }
    ).then((response) => {
      if ('id' in response.data) {
        // dispatch({
        //   type: 'change',
        //   payload: { [fieldKey]: response.data.id }
        // });

        showMessage("Upload Success!", "info");
        callback(response.data.id);
      } else if ('error' in response.data) {
        showMessage("Upload Failed! : " + response.data.error, "error");
        callback(-1);
      }

    });
  }


  /* *********************** */
  /* Dashboard Functions     */
  /* *********************** */

  function onServer(task, inParams={}, callback=(response)=>{}, failCallback=(error)=>{ console.error(error)}) {
    var params = new URLSearchParams();
    params.append('task', task);

    Object.entries(inParams).forEach(([key, value]) => { 
      params.append(key, value);
    });
    // console.log(params);
    getInstance().post("admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        if (callback !== undefined) callback(response.data.success);
      } else {
        failCallback(response.data);
      }
    });

  }



  function createFile(name, file_id, callback) {
    var params = new URLSearchParams();
    params.append('task', 'createFile');
    params.append('name', name);
    params.append('file_id', file_id);

    getInstance().post("admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        if (callback !== undefined) callback(response.data.success);
      }
    });
  }


  function getFileList(callback) {
    var params = new URLSearchParams();
    params.append('task', 'getAllFiles');

    getInstance().post("admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        if (callback !== undefined) callback(response.data.success);
      }
    });
  }


  function saveDashboard(raw, html, callback) {
    var params = new URLSearchParams();
    params.append('task', 'saveDashboard');
    params.append('raw', raw);
    params.append('html', html);

    getInstance().post("admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        if (callback !== undefined) callback(response.data.success);
      }
    });
  }


  function getLatestDashboard(callback) {
    var params = new URLSearchParams();
    params.append('task', 'getLatestDashboard');
    
    getInstance().post("admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        if (callback !== undefined) callback(response.data.success);
      }
    });
  }




  /* *********************** */
  /* Application Functions   */
  /* *********************** */

  function getApplicationById(id, callback) {
    var params = new URLSearchParams();
    params.append('task', 'getById');
    params.append('id', id);
    getInstance().post(  "admin.php", params
    ).then((response) => {
      if ('success' in response.data) {
        // console.log(response.data);
        callback(response.data.success);
      }
    });
  }

  function getViews(callback) {
    var params = new URLSearchParams();
    params.append('task', 'load');
    getInstance().post(  "views.php", params
    ).then((response) => {
      if ('data' in response.data) {
        // console.log(response.data);
        callback(response.data.data);
      }
    });
  }


  function saveView(id, title, columns, default_sort, filters, callback) {
    var params = new URLSearchParams();
    params.append('task', 'save');
    params.append('id', id);
    params.append('title', title);
      let cols = columns.reduce((accum,col) => { accum+=col.id+'|'; return accum }, '');
      cols= cols.substring(0, cols.length - 1);
    params.append('columns', cols);
    params.append('default_sort', default_sort);
    params.append('filters', filters);
    getInstance().post(  "views.php", params
    ).then((response) => {
      if ('success' in response.data) {
        // console.log(response.data);
        callback(response.data.success);
      }

    });
  }


  function getApplications(callback) {
    getInstance().post(  "admin.php"
    ).then((response) => {
      if ('data' in response.data) {
        // console.log(response.data);
        callback(response.data.data);
      }

    });
  }

  function getApplicationsField(field, callback) {

    var params = new URLSearchParams();
    params.append('field', field);
    getInstance().post(  "admin.php", params
    ).then((response) => {
      if ('data' in response.data) {
        // console.log(field);
        // console.log(response.data);

        callback(field, response.data.data);
      }

    });
  }

  function getSpecailApplicationsField(id, callback) {

    var params = new URLSearchParams();
    params.append('id', id);
    params.append('task', 'customFields');
    getInstance().post(  "admin.php", params
    ).then((response) => {
      if ('data' in response.data) {
        // console.log(response.data);
        callback(id, response.data.data);
      }

    });
  }


  return {
    getNotesByTarget,
    createNote,
    
    onServer,
    getFileInfo,
    getFileURL,
    uploadFile,
    createFile,
    getFileList,
    saveDashboard,

    getApplicationById,
    getApplications,
    getApplicationsField,
    getSpecailApplicationsField,
    changeStatusByUser,
    saveStallholderApplication,

    saveView,
    getViews
  }

}

export default Conn_Admin;
