import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Fab, Grid, Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import Slide from '@material-ui/core/Slide';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import { ReactComponent as BeerIcon } from 'icons/beer.svg';
import { ReactComponent as WineIcon } from 'icons/wine.svg';
import { ReactComponent as CiderIcon } from 'icons/cider.svg';
import { ReactComponent as CoffeeIcon } from 'icons/coffee.svg';
import { ReactComponent as SpiritIcon } from 'icons/spirits.svg';
import { ReactComponent as DrinkIcon } from 'icons/drink.svg';

import { ReactComponent as FoodIcon } from 'icons/food.svg';

import DrawItem from './DrawItem';
import MenuPreview from './MenuPreview';

import { useFormControllerContext } from "components/PM_FormController";

import useConfirmation from 'components/application_forms/Confirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  preview: {
    "& .MuiDialog-paper": {
    },

  },
  heading2: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 },
  headingContainer: {
    display: 'flex'
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexGrow: 99,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  previewButton: {
    position: "absolute",
    top: "30px",
    right: "20px"
  }
}));


const countItems = (values) => {
  return Object.keys(values).filter((val) => { return val.includes('menu.item_type') }).length
  //menu.item_type
};


const MenuIcon = (props) => {
  const size = { width: "1em", height: "1em" };
  switch (props.type) {
    case "Beer" : return <BeerIcon {...size} />;
    case "Wine" : return <WineIcon {...size} />;
    case "Cider" : return <CiderIcon {...size} />;
    case "Spirit" : return <SpiritIcon {...size} />;
    case "Coffee" : return <CoffeeIcon {...size} />;
    case "Non Alcoholic" : return <DrinkIcon {...size} />;
    case "Dish" : return <FoodIcon {...size} />;
    default: return <></>;
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DrawItemAdmin = (props) => {
  const classes = useStyles();
  const {item, count, getValue} = props;
  const FormController = useFormControllerContext();
  const [expanded, setExpanded] = React.useState(false);


  if ('repeater' in item && FormController.getKey(item).includes("#0")) {

    let theKey = FormController.getKey(item).split("#")[0];
    // find highest matching count in values
    let theNumber = 0;
    Object.keys(FormController.state.values).forEach((valueKey) => {

      if (valueKey.includes(theKey)) {

        let newVal = parseInt(valueKey.split("#")[1].split(".")[0]);
        if (newVal > theNumber) {
          theNumber = newVal;
        }
      }
    });
    let count = item.parent.children.filter(child => child.id === item.id).length;

    for (let ii=count; ii<=theNumber; ii++) {
       FormController.addInstance(item);
    }
  }



  const af_space = item.children.reduce((accum, child) => {

    if (!FormController.shouldDisplay(child)) return accum;
    if ('af' in child) {
      accum[child.af] = {children: child.children, displays:[]};
    } else {
      accum.basic.children.push(child);
    }
    return accum;
  }, {basic:{children:[], displays:[]}});

  return (<>
  {/* <ExpansionPanel key={count+'menuiztem'} expanded={expanded} style={{width: '100%'}} onChange={(event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  }}> */}
  {/* <ExpansionPanelSummary
    className={classes.headingContainer}
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1bh-content"
    id="panel1bh-header"> */}
    <Typography  className={classes.heading}>{getValue('item_title', item)}</Typography>

  {/* </ExpansionPanelSummary>
  <ExpansionPanelDetails > */}

    <Grid container spacing={2} alignItems="flex-start">


      <Grid item container xs={6} spacing={0}>
        <Grid item xs={12} style={{padding: 0}}>
          <Typography><strong>Basic item details</strong></Typography>
        </Grid>
         <DrawItem item={af_space.basic} updateFlow={props.updateFlow} viewMode={props.viewMode}  adminView={props.adminView} />
         {('food_details' in af_space) && <DrawItem item={af_space.food_details} updateFlow={props.updateFlow} viewMode={props.viewMode} />}
         {('bev_details' in af_space) && <DrawItem item={af_space.bev_details} updateFlow={props.updateFlow} viewMode={props.viewMode} />}


      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={4}>
          <Typography><strong>Pricing</strong></Typography>
        </Grid>
         {('food_price' in af_space) && <DrawItem item={af_space.food_price} updateFlow={props.updateFlow} viewMode={props.viewMode}  adminView={props.adminView} />}
         {('bev_price' in af_space) && <DrawItem item={af_space.bev_price} updateFlow={props.updateFlow} viewMode={props.viewMode}  adminView={props.adminView} />}
      </Grid>
    </Grid>
  {/* </ExpansionPanelDetails> */}
  {/* </ExpansionPanel> */}
  </>);
}


export default function MenuRepeater(props) {
  const classes = useStyles();
  const FormController = useFormControllerContext();
  const [expanded, setExpanded] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const FieldContainer = props.FieldContainer;

  const itemCount = () => {
    let count = (FormController.state.values[props.name] || countItems(FormController.state.values))
    count = (count > 0) ? count : 1;
    return count;
  };

  const handleMenuOpen = () => {
     setMenuOpen(true);
   };

   const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useMemo(() => {
    setExpanded('panel'+(itemCount()-1));
  }, []);

  const handleAdd = (event) => {
     const count = itemCount();
     FormController.dispatch({
       type: 'change',
       payload: { [props.name]: count+1 }
     });
     setExpanded('panel'+count);
     FormController.addInstance(props.item.children[0]);
   };

   // console.log('item');
   // console.log(props.item);

  const controls =(
    <Fab variant="extended"
      size="medium"
      color="primary"
      aria-label="add"

      onClick={handleAdd}>
      <AddIcon />
      Add Menu Item
    </Fab>
  );
  const { ConfirmationWindow, askForConfirmation } = useConfirmation({title:'remove file', task: 'delete file from your application', onYes:() => {} });

  const openConfirmation = (item) => { askForConfirmation(
    {title:'remove menu item',
      task: 'delete menu item',
       onYes:() => {
         // FormController.dispatch({
         //   type: 'removeMenuItem',
         //   payload: item
         // });
         // console.log(item);
         FormController.removeInstance(item);
        }
     });
     return false;
   }

  // const display = <>{question.children && question.children.map((child) => {
  //    switch (child.type) {
  //      case "group": return (<DrawGroup group={child} />);
  //      case "question": return (<DrawQuestion question={child} />);
  //    }

  // function DrawQuestion(props) {
  //   const FormController = useFormControllerContext();
  //   const { shouldDisplay } = FormController;
  //
  //   const question = props.question;
  //   if (shouldDisplay(question)) {
  //     const fieldKey = new PM_Key(question.id);
  //     return <FieldContainer key={fieldKey.getUniqueKey()} question={question} />
  //   }
  //   return <>{question.name}</>;
  // }

  // function DrawGroup(props) {
  //   const FormController = useFormControllerContext();
  //   const { shouldDisplay } = FormController;
  //
  //   const group = props.group;
  //
  //   const headingSize = "h5";
  //   if (!shouldDisplay(group, props.instance)) {
  //     return <></>;
  //   }
  //
  //   const  display = <>{group.children && group.children.map((child) => {
  //       switch (child.type) {
  //         case "group": return (<DrawGroup group={child} instance={props.instance} />);
  //         case "question": return (<DrawQuestion question={child} />);
  //       }
  //     }
  //   )}</>;
  //
  //
  //
  //   return(
  //     <Grid container spacing={2}>
  //     {group.name && (
  //      <Grid item xs={12}>
  //        <Typography variant={headingSize}>{group.name}</Typography>
  //      </Grid>
  //      )}
  //      {group.description && (<Grid item xs={12}><Typography variant="body1" dangerouslySetInnerHTML={{ __html: group.description }}></Typography></Grid>)}
  //      {display}
  //
  // {
  //      // { ('description' in group) && <Grid item xs={12}><Typography variant="body1" dangerouslySetInnerHTML={{ __html: group.description }}></Typography></Grid> }
  //      // { (isRepeater(group))
  //      //     ? (<GroupRepeater key={group.name}  name={group.name} items={group.items} FieldContainer={FieldContainer} />)
  //      //      : ('menu-repeater' in group)
  //      //     ? (<MenuRepeater key={group.name} name={group.name} items={group.items} FieldContainer={FieldContainer} />)
  //      //      : ('layout' in group)
  //      //     ? [group.layout+'Layout'].map(x => { const V = x; return <SiteVisualiserLayout key={group.name} items={group.items} FieldContainer={FieldContainer} />})
  //      //      : ('layout2' in group)
  //      //     ? (() => { const TagName = group.layout+'Layout'; alert('jimmy'+TagName); return(<TagName key={group.name} items={group.items} FieldContainer={FieldContainer} />)})
  //      //      : group.items.filter((n) => shouldDisplay(n))
  //      //       .map(k => new PM_Key(k))
  //      //       .map(k => <FieldContainer key={k.getUniqueKey()} fieldKey={k} />)
  //      // }
  //  }
  //    </Grid>);
  // }
  //
  const getValue = (needle, item) => {
    return FormController.state.values[FormController.getKey(FormController.findChildById(needle, item))];
  }





//  <Button variant="outlined" color="secondary" onClick={(e) => { e.stopPropagation(); openConfirmation(ii); }} ><DeleteForeverIcon /></Button>
//  <DrawItem item={x} updateFlow={props.updateFlow} viewMode={props.viewMode} />
    if (props.viewMode) {
    //   props.item.children.map((x, ii) => {return(
    //     <DrawItem item={x} count={ii} getValue={getValue} viewMode={props.viewMode}  />
    // )})
    return   props.item.children.map((x, ii) => { return(
        <DrawItemAdmin item={x} count={ii} getValue={getValue} viewMode={props.viewMode}  />
    )})
  }

  


  return (
    <Box width={1} p={0}>
        
      {/* <Button variant="contained" className={classes.previewButton} color="primary" onClick={handleMenuOpen}>
        Preview my menu
      </Button> */}

          <Dialog
          open={menuOpen}
          TransitionComponent={Transition}
          className={classes.preview}
          onClose={handleMenuClose}
          maxWidth={'lg'}
          aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className={classes.heading2}>
                Menu Preview
            </DialogTitle>
            <DialogContent>
              <MenuPreview />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleMenuClose}  color="primary" variant="contained">
                close
              </Button>
            </DialogActions>
          </Dialog>


        <ConfirmationWindow  />
        {

          props.item.children.map((x,ii)=>{

//  <Button variant="outlined" color="secondary" onClick={(e) => { e.stopPropagation(); openConfirmation(ii); }} ><DeleteForeverIcon /></Button>



          return (<ExpansionPanel key={ii} expanded={expanded === 'panel'+ii} onChange={handleChange('panel'+ii)}>
          <ExpansionPanelSummary
            className={classes.headingContainer}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography  className={classes.heading}>{getValue('item_title', x)}</Typography>
            <div>
              <Button variant="outlined"><EditIcon/></Button>
              <Button variant="outlined" color="secondary" onClick={(e) => { e.stopPropagation(); openConfirmation(x); }} ><DeleteForeverIcon /></Button>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>

          <Grid container spacing={2}>
          {
            // props.items.filter((n) => FormController.shouldDisplay(n, ii))
             // .map(question => <props.FieldContainer key={question.getKey()} question={question} repeater={ii} />)
             // display



             //  key={FormController.getKey(child)}  item={child} updateFlow={props.updateFlow} viewMode={viewMode} adminView={adminView} hideFlags={hideFlags} PDFView={PDFView} 
          } 
                
                
                <DrawItem key={FormController.getKey(x)}  item={x} updateFlow={props.updateFlow} viewMode={props.viewMode}  adminView={props.adminView} />

            </Grid>
          </ExpansionPanelDetails>
          </ExpansionPanel>
      )
      })}
        <Grid item xs={12}>
          {controls}
      </Grid>

    </Box>
  );

}
/*
<Grid item xs={12}>
  <Button onClick={() => { openConfirmation(ii); }
  } color="secondary" variant="contained" startIcon={<DeleteForeverIcon />}>Delete This Menu Item</Button></Grid>

*/
