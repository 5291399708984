import React from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Box, AppBar, Divider, Toolbar, Typography, IconButton, Badge, Drawer, Grid, Button, Paper } from '@material-ui/core';

import ApplicationHeader from "../application_forms/ApplicationHeader";
import ApplicationFooter from "../application_forms/ApplicationFooter";

import {  FormControllerProvider } from "components/PM_FormController";

import PM_FormController from "components/PM_FormController";
import { UserFeedbackProvider } from "../UserFeedback";

import SaveIcon from '@material-ui/icons/Save';

import xmlData from 'config/StallholderApplication';

import Conn_Application from "../application_forms/Conn_Application";

import { useUserFeedback } from "components/UserFeedback";

import DrawItem from 'components/application_forms/DrawItem';
import { useFormControllerContext } from "components/PM_FormController";

import 'components/admin/dashboardStyles.css';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6
  },
  reversed: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: '#793478',
    '& *': {
      color: '#fff'
    }
  },
  form: {
    width: '100%',
    padding: theme.spacing(6),
    marginBottom: '200px',
    'box-sizing': 'border-box',
    '& .MuiFormHelperText-root': {
      color: '#888',
      fontSize: "10px",
      marginLeft: 0,
      "&.Mui-error": {
        color: "#ee680f",
        fontWeight: "bold"
      }
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  magicAppbar: {
    zIndex: 4,
    borderBottom: "9px solid #fff",
    height: "73px",
    marginBottom: theme.spacing(4)
  },
  theButtons: {
    position: 'relative',
    zIndex: 8
  },
  editor: {
    position: 'relative',
    zIndex: 9,
    marginBottom: '300px'
  },
  siteNumber: {
    fontSize: '34px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}));


function InnerDashboardPage(props) {

  const [dashboard, setDashboard] = React.useState('');

  const FormController = PM_FormController({ xmlData: xmlData}); 
  const { state, dispatch, shouldDisplay } = FormController;
  const { showMessage } = useUserFeedback();
  const { getLatestStallholder, saveStallholderApplication } = Conn_Application(state, dispatch, showMessage);
  const { getLatestDashboard } = Conn_Application({},{},{});
  React.useMemo(() => {
    getLatestDashboard((res) => { setDashboard(res)});
    dispatch({
      type: 'change',
      payload: { '.extra.active': true }
    });
  }, []);

  React.useMemo(() => {
    getLatestStallholder(FormController.initRepeaters);
  }, []
  );

  // const FormController = useFormControllerContext();

  const doSave = () => {
    // turn off saving
    if (FormController.allowedToEdit()) {
      saveStallholderApplication();
    }
  };



  const [flow, setFlow] = React.useState({ ...FormController.Flow });

  const classes = useStyles();

  return (
    <div>
      <FormControllerProvider xmlData={xmlData} FormController={FormController}>
               
        <ApplicationHeader small />
        
        <Box mx={16} mt={10} pb={20}>
          <Grid container>
            <Grid item xs={8} mb={500}>   
              <Box  mr={2}>
   
              <Paper mr={4}>
                <Box p={2}>
                    <Typography className={classes.siteNumber}>Site Number {FormController.state.values['.extra.site_number']}</Typography>
                </Box>
              </Paper>



              <Paper mr={4} className={classes.editor}>  
                <Box p={2}>
                  <div dangerouslySetInnerHTML={{ __html: dashboard }} className="dashboardStyles"></div>
                  {state.values['status'] === 4 &&<Link to="/applicationPage"><Button variant="contained" color="primary">edit my application</Button></Link>}
                </Box>
              </Paper>
              
              </Box>       
            </Grid>
            <Grid item xs={4} >
              <FormContainer >
                {FormController.Flow.children.length > 0 && 
                
                  FormController.Flow.children[7].children
                    .filter((group) => {
                      return shouldDisplay(group)
                    }) //&& group.children.filter((n) => shouldDisplay(n)).length > 0;
                    .map((group) => (
                      <Paper key={group.name} className={('className' in group) ? classes[group.className] : classes.paper}>
                        <DrawItem item={group} updateFlow={setFlow} />
                        <Box mt={4}>
                          <Button onClick={doSave} variant="contained" color="primary" startIcon={<SaveIcon />}  >
                          Save Changes to Files
                        </Button>
                        </Box>
                      </Paper>
                    ))
                }

                

              </FormContainer>
              
            </Grid>    
          </Grid>
        </Box>

        <ApplicationFooter />
        
      </FormControllerProvider>
    </div>
  )
}

function FormContainer(props) {
  const FormController = useFormControllerContext();
  // const { getFormProps, numberOfErrorFileds } = useValidatorContext();

  return (
    <form {...FormController.getFormProps()}>
      {props.children}
    </form>
  )
}


export default function DashboardPage(props) {
  return (
    <UserFeedbackProvider>
      <InnerDashboardPage {...props} />
    </UserFeedbackProvider>
    
  );
}
