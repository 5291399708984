import React, { useMemo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';


import {  Link } from 'react-router-dom';

// import StallholderDS, { FilteredStallholderDS } from 'config/StallholderDS';
//
import Config from 'Config.js';
import { shouldDisplay, isRepeater, PM_Key } from "./PM_Conditionals";
import { PM_Validation, ValidationProvider,  useValidatorContext } from "./PM_Validator2";
//
// import StallholderApplication, { useStallholderApplication } from "./StallholderApplication";
import xml2js from 'xml2js';
import convert from 'xml-js';

import $ from 'jquery';


const FormControllerContext = React.createContext({});

export const FormControllerProvider = (props) => {
  const context = (props.FormController) ?  props.FormController : PM_FormController(props);
  const memoizedContext = useMemo(() => context, [context]);
  return <FormControllerContext.Provider value={ memoizedContext } >{ props.children }</FormControllerContext.Provider>;
};

export function useFormControllerContext() {
  return React.useContext(FormControllerContext);
}


function formControllerReducer(state, action) {
  switch (action.type) {
    case 'change':
      const values = {
        ...state.values,
        ...action.payload
      };
      return {
        ...state,
        values,
      };
    case 'load':

      const values3 = Object.keys(action.payload).reduce((accum, key) => {
        accum.push({
          ['.system.id']: parseInt(key),
          ['.system.date']: action.payload[key]['application'][2],
          ['.system.status']: action.payload[key]['application'][3],
          ['.system.username']: action.payload[key]['username'],
          ['.system.fullname']: action.payload[key]['last_name'] + ', ' + action.payload[key]['first_name']
        });
        return accum;
      }, []);
      // return [...rows, ...values];
      const values2 = {
        ...state.values,
        ...action.payload,
        ...values3
      };

      return {
        ...state,
        values2,
      };
    case 'submit2':
      return {...state, submitted: state.submitted+1 };
    case 'serverValidate':
      return {...state, serverErrors: action.payload };
    case 'validate':
      return {...state, errors: action.payload };
    case 'touch':
      const touched = {
        ...state.touched,
        [action.payload]: true
      };
      return {...state, touched };
    case 'afterSubmit':
      action.payload(state);
      return state;
    case 'removeMenuItem' :
      const vals = {
        ...state.values
      };

      var re = /menu\.item_(.*?):(\d+)/g;
      const valz = Object.keys(vals).reduce((acc, val) => {
        var matches = re.exec(val);
        if (matches !== null) {
          if (matches[2] > action.payload) {
            acc['menu.item_'+matches[1]+':'+(matches[2]-1)] = vals[val];

          } else if (matches[2] < action.payload) {
            acc[val] = vals[val];

          }
        } else {
          acc[val] = vals[val];

        }
        return acc;
      },{});

      // console.log(vals);
      // console.log(valz);
      return {
        ...state,
        values:valz
      };
    case 'bulkChange':
    return {
      ...state,
      values: action.payload
    };

    default:
      throw new Error('Unknown action type');
  }
}
//   const t = convert.xml2js(xmlData, { compact: false, spaces: 0 });
//   return condenseXML(t.elements[0]);
// }



const initialState = {
  values: {},
  errors: {},
  serverErrors: {},
  touched: {},
  submitted: 0,
  instances: {}
};


function doClone(item) {

}


function updateParents(node) {
  node.children.forEach(child => {
    child.parent = node;
    updateParents(child);
  });
  // node.option.forEach(child => {
  //   console.log('update child');
  //   child.parent = node;
  //   updateParents(child);
  // });
}




function condenseXML(item, parent) {
  let config = {};
  const properties = ['validator', 'condition', 'config', 'option', 'display'];

  // console.log('condenseXML '+item.name);
  // console.log(item);
  config['type'] = item.name;
  config['parent'] = parent;
  config['children'] = [];


  // if ()

  // config['getKey'] = function() {
  //   // if ('instance' in this) {
  //   //   console.log(this.instance);
  //   // }
  //   let id = ('id' in config) ? "."+config.id+(('instance' in this) ? '#'+this.instance : '') : '';
  //   console.log(config);
  //   console.log(config.parent);
  //   if (config.parent !== null) {
  //     // console.log(config.parent);
  //     id = config.parent.getKey()+id;
  //   }
  //   return id;
  // };

  properties.forEach(prop => config[prop+'s'] = []);


  // map attributes to object properties
  if (item.attributes) {
    Object.keys(item.attributes).forEach((key) => config[key] = item.attributes[key]);
  }

  if ('repeater' in config) {
    config['instance'] = 0;
  }

  // let simple = true;
  // for (const num in item.elements) {
  //   const element = item.elements[num];
  // }

  // for (const num in item.elements) {
  //   const element = item.elements[num];
  //   if (element.type === "text") return element.text;
  //   if ((element.name in properties)) {
  //     // config = addBranch(config, element);
  //   } else {
  //     config[element.name] = mergeAttributes(element);
  //   }
  //   return config;
  // }
  for (const num in item.elements) {
    const element = item.elements[num];

    if ('elements' in element && element.elements[0].type === "text") {
      config[element.name] = element.elements[0].text;
    } else {
      //complex
        if (properties.includes(element.name)) {
        config[element.name+'s'].push(condenseXML(element, config));
      } else {
       // if ('children' in config) {
       // // if (element.name+'s' in config) {
       //  // if (!Array.isArray(config[element.name])) {
       //  //   config[element.name] = [config[element.name]];
       //  // }
        config.children.push(condenseXML(element, config));

      //   // if (typeof config[element.name] !== Array) {
      // //   //   config[element.name] = [config[element.name]];
      // //   // }
      // //   // config[element.name].push(condenseXML(child));
      //  } else {
      //   config['children'] = [condenseXML(element, item)];
       // }
     }



      // if (isText(element)) {
      //   console.log('simple '+element.name);
      //    config[element.name] = condenseXML(element);
      // } else {
      //   console.log("not simple ");
      //   console.log(element);
      //   config[element.name] = [];
      //   for (const kk in element.elements) {
      //     console.log(element.name+"<");
      //     config[element.name].push(  condenseXML(element.elements[kk]) );
      //   }
      // }

    }
  }
  return config;
}
// function isText(item) {
//   // console.log('simple test for '+item.name);
//   for (const ii in item.elements) {
//     const element = item.elements[ii];
//     if (element.type === "text") continue;
//     if ('elements' in element && element.elements[0].type === "text") continue;
//
//     return false;
//   }
//   return true;
// }

// const generateKey = (item) => {
//   return item.id;
// }

const PM_FormController = (props) => {

  // const { state2, dispatch2} = useStallholderApplication();
  const [state, dispatch] = React.useReducer(formControllerReducer, initialState);
  const [ postSubmit, setPostSubmit ] = React.useState();

  const [Flow, setFlow] = React.useState({children:[]});

  React.useEffect(() => {
    //console.log('ready');
    initFlow();
    // setFlow(condenseXML(convert.xml2js(props.xmlData, { compact: false, spaces: 0 }), null).children[0]);
  }, [])


  function initFlow() {
    if (Flow.children.length === 0) {
      setFlow(condenseXML(convert.xml2js(props.xmlData, { compact: false, spaces: 0 }), null).children[0]);
    }
    return Flow;
  }

  const getKey = function(item) {
    // if ('instance' in this) {
    //   console.log(this.instance);
    // }
    let id = (item && 'id' in item) ? "."+item.id+(('instance' in item) ? '#'+item.instance : '') : '';
    const parent = item && item.parent;

    if (parent !== null) {

      id = getKey(parent)+id;
    }
    // console.log(id);
    return id;
  };

  // function findParent(item) {
  //   return item.parent;
  //   return findInChildren(item, Flow);
  // }
  // function findInChildren(item, node) {
  //
  //   if (hasChild(item, node)) return node;
  //   let found = null;
  //   node.children.some(child => {
  //     found = findInChildren(item, child);
  //     return found !== null;
  //   });
  //
  //   return found;
  // }

  function getNodeByKey(key, node = Flow) {
    let found = null;
    node.children.some(child => {
      if (getKey(child) === key) {
        found = child;
       
      } else {
        found = getNodeByKey(key, child);
        
      }
      return found !== null;
    })
    return found;
  }

  function findChildById(id, item=Flow) {
    let found = null;
    item.children.some(child => {
      if (child.id === id || child.af === id) {
        found = child;
      } else {
        found = findChildById(id, child);

      }
      return found !== null;
    });
    
    return found;
  }

  function findOptionById(id, item) {
    let found = null;
    item.options.some(child => {
      // console.log(child.value + ' {{ }} '+id);
      if (child.value === id || child.name === id) {
        found = child;
      } else if (getKey(child) === id) {
        found = child;
      }
      return found !== null;
    });
    return found;
  }

  function findKeyInChildren(item,key) {
    for (let cID in item.children) {
      const child = item.children[cID];
      if (getKey(child) === key) {
        return child;
      } else {
        let found = findKeyInChildren(child, key);
        if (found !== null) return found;
      }
    }
    return null;
  }

  function hasChild(item, node) {
    const val = node.children.find(child => child === item) !== undefined;
    if (val) {
      // console.log('found in children ');
      // console.log(item);
      // console.log(node);

    }
    return val;
  }

  function getNameOf(key) {
    // getItemByKey(key);
  }

  function getItemByKey(id) {
    return findKeyInChildren(Flow, id);
  }

  function allowedToEdit() {
    // console.log('allowed to edit ', state.values);
     return (state.values['status'] === 1 || state.values['status'] === 5 || state.values['.extra.active']);
   }

  const GetName =(s=state) => {
    return s.values['.stall.stall_name']+((s.values['.stall.stall_name2'] !== undefined
        & s.values['.stall.co_branded'] === "yes2")
          ? ' & '+s.values['.stall.stall_name2'] : '');
  };



  function getValueOf(item, inVal=null, Admin_Helpers, ApplicationHelpers, year=2025) {
    
    if (typeof item === 'string') {
      if (item.lastIndexOf('.system.status') !== -1) return Admin_Helpers.getStatusList[inVal];
      if (item.lastIndexOf('.special.cost_estimate') !== -1) {
        const rows = ApplicationHelpers.getEstimateData();
        return ApplicationHelpers.toCurrency(rows[rows.length-1]);
      }
      if (item.lastIndexOf('.special.') !== -1) return inVal;
      if (item.lastIndexOf('.extra.site_number') !== -1) return inVal; 
      if (item.lastIndexOf('.extra.assigned_stall') !== -1) return inVal; 
      if (item.lastIndexOf('.extra.approval') !== -1) return inVal; 
      if (item.lastIndexOf('.system.updated') !== -1) return inVal; 
      if (item.lastIndexOf('.system.year') !== -1) return inVal; 
    }



    const question = (typeof item === 'string') ? getItemByKey(item) : item;
    const key = getKey(question);
    const value = (inVal === null) ? state.values[key] : inVal;
    if (!('configs' in question) || question.configs.length === 0) return value;
    if (['radio', 'select','check'].includes(question.configs[0].type)) {
      if (value !== undefined && value.lastIndexOf('|') !== -1) {
        let rtn = '';
        value.split('|').forEach((val) => {
          const option = findOptionById(val, question);
          rtn += (option !== null) ? option.name+'' : '';
          //alert(option);
          // rtn+=val;
        });
        return rtn;
      } else {
        const option = findOptionById(value, question);
        return (option !== null) ? option.name : '';
      }
    } else if (question.configs[0].type === 'file') {
      return (value && <a href={Config.server.backend+"readfile.php?id="+value}>File</a>);
    } else {
      return value;
    }
  }

  function deepClone(obj, hash = new WeakMap()) {
    if (Object(obj) !== obj) return obj; // primitives

    if (hash.has(obj)) return hash.get(obj); // cyclic reference

    const result = obj instanceof Set ? new Set(obj) // See note about this!
                 : obj instanceof Map ? new Map(Array.from(obj, ([key, val]) =>
                                        [key, deepClone(val, hash)]))
                 : obj instanceof Date ? new Date(obj)
                 : obj instanceof RegExp ? new RegExp(obj.source, obj.flags)
                 // ... add here any specific treatment for other classes ...
                 // and finally a catch-all:
                 : obj.constructor ? new obj.constructor()
                 : Object.create(null);
    hash.set(obj, result);
    return Object.assign(result, ...Object.keys(obj).map(
        key => ({ [key]: deepClone(obj[key], hash) }) ));
}


  function addInstance(node) {
    const parent = node.parent;

    let count = parent.children.filter(child => child.id === node.id).length;

    let newNode = deepClone(node);
    newNode.instance = count;
    updateParents(newNode);
    newNode.parent = parent;

    parent.children.push(newNode);

  }


  function removeInstance(node) {


    const parent = node.parent;
    // console.log('parent');
    // console.log(parent);
    // console.log(node);

    // update values
// const re = /(.*?)#(\d+)\./g;
const re = new RegExp('(.*?)'+node.id+'#(\\d+?)\\.(.*)');
const re2 = new RegExp('(.*?)'+node.id+'#(\\d+?)');
var index = re2.exec(getKey(node))[2];
const valz = Object.keys(state.values).reduce((acc, val) => {
  var matches = re.exec(val);

  if (matches !== null) {
    // console.log('matches');
    // console.log(matches);
    if (matches[2] > index) {
      acc[matches[1]+node.id+'#'+(matches[2]-1)+'.'+matches[3]] = state.values[val];
      // console.log(val);
      // console.log('becomes');
      // console.log(matches[1]+node.id+'#'+(matches[2]-1)+'.'+matches[3]);
    } else if (matches[2] < index) {
      acc[val] = state.values[val];
    }
  } else {
    acc[val] = state.values[val];
    // console.log('no match for '+val);
  }
  return acc;
},{});



    // remove from flow
    // console.log('parent.children');
    // console.log(parent);

    if (index > -1) {
      parent.children.pop();
      // console.log('parent.children');
      // console.log(parent.children);
    } else {
      // alert('can not find item in parent');
    }

    dispatch({
      type: 'bulkChange',
      payload: valz
    });


  }

  function touchAllNodes(node) {
    getAllActiveNodes(node, 'question').forEach(question => {
      dispatch({
        type: 'touch',
        payload: getKey(question)
      });
    });
  }



  function getAllNodes(type, node) {
    let activeNodes = [];
    if (node.type === type) {
      // activeNodes.push(node);
    }
    node.children.forEach(child => {
      if (child.type === type) {
        activeNodes.push(child);
      }
      activeNodes = [...activeNodes, ...getAllNodes(type, child)];
    });
    return activeNodes;
  }

  function getAllActiveNodes(node, type) {
    let activeQuestions = [];
    if (node.type === type) {
      activeQuestions.push(node);
    }
    node.children.filter(child => shouldDisplay(child, state, -1, getItemByKey, getKey)).forEach(child => {
      if (child.type === type) {
        activeQuestions.push(child);
      } else {
        activeQuestions = [...activeQuestions, ...getAllActiveNodes(child, type)];
      }
    });
    return activeQuestions;
  }

  const Validator = PM_Validation();

  // const configs = ApplicationFlow.map(screen => {
  //   let config = FilteredStallholderDS(screen.name);
  //   if (typeof config === 'function') {
  //     config = config(state.values);
  //   }
  //   return config;
  // });
  // let config = StallholderDS;

  let config = props.config;
  if (typeof config === 'function') {
    config = config(state.values);
  }

function isNodeValid(node) {
  const errors = Validator.validateQuestions(state.values, getAllActiveNodes(node, 'question'), getKey);
  return Object.values(errors).every(error => error === null)
}

  const isFormValid = useMemo(
      () => Object.values(state.errors).every(error => error === null) && (true || state.submitted),
      [state.errors]
    );


const finalSubmit = (finaliseApplication) => {

    finaliseApplication();
}

  // const errors = useMemo(() => {
  //   // const e = getErrors(state, config);
  //   //   // eDispatch({
  //   //   //   type: 'change',
  //   //   //   payload: { ['errorCount_'+name] : Object.values(e).filter((val) => (val !== null)).length }
  //   //   // });
  //   //   return e;
  //   return [];
  //     }, [
  //     state.errors,
  //     state.touched,
  //     state.submitted,
  //     state.config
  //   ]);


    const validateForm = (ignoreTouched = false) => {
      const errors = Validator.validateQuestions(state.values, getAllActiveNodes(Flow, 'question'), getKey);
      dispatch({
        type: 'validate',
        payload: errors
      });
      return errors;
    }

    const getAbsoluteErrorCount = (screenNum) => {
      const screenName = Flow.children[screenNum].id;
      let errors = Validator.validateQuestions(state.values, getAllActiveNodes(Flow, 'question'), getKey);
      errors = Object.keys(errors).filter((key) => key.startsWith('.'+screenName)).reduce((obj, key) => {
        obj[key] = errors[key];
        return obj;
      }, {});
      const count =  Object.values(errors).filter((val) => (val !== null)).length;
      return count;
    }


    useDeepCompareEffect(() => {
      validateForm(true);

    }, [state.touched, state.serverErrors, state.values]);

    const errorsToDisplay = useMemo(() => {
      return Validator.processErrors(state, Flow);
      }, [
       state.errors
    ]);

    // process server errors when there are errors in the queue
    useMemo(() => {  Validator.processServerErrors(state, Flow, dispatch); }, [
      state.serverErrors
    ]);


    const getFormProps = () => ({
      onSubmit: e => {
        e.preventDefault();
        //setPostSubmit({v:config.onSubmit});
        //wrong
        const errors = validateForm(true);

        if (Object.values(errors).every(error => error === null)) {
          //config.onSubmit();
          eval(Flow.onSubmit);
        }
        dispatch({
          type: 'submit2'
        });
        return false;
      },
      autoComplete: "off"
    });


  function getFieldProps(question) {

    const pmKey = getKey(question);

    return ({
      onChange: (e, val) => {

        if (e == null) return;

        // special case for sliders
        if (val != undefined && typeof val !== 'object' && !e._isAMomentObject) {
          e.target.value = val;
        }
        //special case for dates
        if (e._isAMomentObject) {
          e.target = { value: e }
        }
        //special case for checkboxes
        const payload = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
        dispatch({
          type: 'change',
          payload: {
            [pmKey]: payload
          }
        });
      },

      onBlur: () => {
        dispatch({
          type: 'touch',
          payload: pmKey
        });
      },
      type: question.configs[0].type || 'text',
      name: pmKey,
      value: state.values[pmKey] || '',

      className: ' validate',
      label: question.name,
      error: errorsToDisplay[pmKey],
      helperText: errorsToDisplay[pmKey],
      // required: (config.field[pmKey.getConfigKey()] && config.field[pmKey.getConfigKey()].validators && "isRequired" in config.field[pmKey.getConfigKey()].validators)

    })
  };

  return {
    allowedToEdit,
    GetName,
    getValueOf,
    initFlow,
    getNameOf,
    finalSubmit,
    getKey,
    addInstance,
    getItemByKey,
    findChildById,
    findOptionById,
    Flow,
    state,
    dispatch,
    config,
    getFieldProps,
    getFormProps,
    isFormValid,
    isNodeValid,
    touchAllNodes,
    removeInstance,
    getAllNodes,
    getNodeByKey,
    // ValidationProviders,
    // ApplicationFlow,
    getAbsoluteErrorCount,
    shouldDisplay: (item, instance = -1) => shouldDisplay(item, state, instance, getItemByKey, getKey),
    isRepeater: (group) => isRepeater(group)
  }
}

export default PM_FormController;
