import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Slider, FormControl, FormLabel, FormHelperText } from '@material-ui/core';

import { useFormControllerContext } from "components/PM_FormController";
import { useValidatorContext } from "components/PM_Validator2";

function valuetext(value) {
  return "${value}";
}

const useStyles = makeStyles((theme) => ({
  margin: {
    height: theme.spacing(6),
  },
}));

export default function Input_Slider(props) {
  const classes = useStyles();
  const FormController = useFormControllerContext();
  const question = props.question;
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const fieldProps = FormController.getFieldProps(question);
  let canEdit = ("canEdit" in props && props.canEdit);

    return (<>
      <div className={classes.margin} />
        <Slider
          disabled={!canEdit}
          defaultValue={0}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          value={FormController.state.values[FormController.getKey(question)] || 0}
          step={1}
          marks={[
            { value: question.configs[0].min, label: question.configs[0].min },
            { value: question.configs[0].max, label: question.configs[0].max }
          ]}
          min={parseInt(question.configs[0].min)}
          max={parseInt(question.configs[0].max)}
          onChange={fieldProps.onChange}
      />
      </>


   );
}
