import React from 'react';

import { useFormControllerContext } from "components/PM_FormController";


import { FormControl, FormLabel, FormControlLabel, Switch } from '@material-ui/core';

export default function Input_Switch(props) {

  const FormController = useFormControllerContext();
  const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const fieldProps = FormController.getFieldProps(props.fieldKey);
  delete fieldProps.helperText;
  let canEdit = ("canEdit" in props && props.canEdit);

    return (
        <FormControlLabel
            checked={FormController.state.values[props.fieldKey.getUniqueKey()]||false}
            {...fieldProps}
            control={<Switch color="primary"  />}
            label={item.options[0]}
            labelPlacement="end"
            disabled={!canEdit}
          />


   );
}
