import React from 'react';
import { useFormControllerContext } from "components/PM_FormController";
import { makeStyles } from '@material-ui/core/styles';
import { Typography} from '@material-ui/core';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import  tick  from "icons/icon_tick.jpg";
import  notick  from "icons/icon_notick.jpg";



import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({

}));



export default function Input_Checklist(props) {
  const FormController = useFormControllerContext();
  const question = props.question;
  const classes = useStyles();
  // const boxType= (FormController.state.values[FormController.getKey(question)]) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
  const completed = FormController.state.values[FormController.getKey(question)];
  return <TableRow className={(completed) ? 'completed' : ''}>
    <TableCell><img src={(completed) ? tick : notick} width="18px" height="18px"/></TableCell>
    <TableCell>{question.name}</TableCell>
  </TableRow>;
}
