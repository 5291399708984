import React from 'react';


import {  Link } from 'react-router-dom';

import { lighten, makeStyles } from '@material-ui/core/styles';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import ColumnSelect from 'components/admin/ColumnSelect';


import Conn_Admin from 'components/admin/Conn_Admin';
import ApplicationHelpers from 'components/application_forms/ApplicationHelpers';
import { useFormControllerContext } from "components/PM_FormController";
import Filters from 'components/admin/Filters';
import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';

// import {MessagingDialog, MessagingOutboxDialog} from './_Messaging';
import {StatusChangerDialog} from './StatusChanger';

import QuickSearch from './QuickSearch';

import { useCookies } from 'react-cookie';





function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    console.log(order);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}













const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grow: {
    flexGrow: 1,
  },
  buttonDark: {
    background: '#333',
    color: '#fff'
  },
}));





const headerCellsReducer = (cells, action) => {
  switch(action.type) {
    // case 'laod':
    //   return [...action.payload];

    case 'delete':
      cells.splice(action.payload, 1);
      return [...cells];

    case 'update':
      cells[action.payload.num] = action.payload.config;
      return [...cells];

    case 'append':
      return [...cells, {...action.payload}];
  }

}

const reducer = (rows, action) => {
  switch(action.type) {
    case 'load':
      const values = Object.keys(action.payload).reduce((accum, key) => { accum.push({
        ['.system.id']: parseInt(action.payload[key]['application'][1]),
        ['.system.user_id']: parseInt(action.payload[key]['application'][0]),
         ['.system.date']: action.payload[key]['application'][2],
         ['.system.status']:action.payload[key]['application'][3],
         ['.system.username']:action.payload[key]['username'],
         ['.system.fullname']:action.payload[key]['last_name']+', '+action.payload[key]['first_name'],
         ['.system.year']: action.payload[key]['application'][4]
       });
       return accum;
      }, []);
      console.log('load', values);
      return [...rows, ...values];
    case 'append':
      return [...rows.map(row => {
        row[action.payload.field] = action.payload.values[row['.system.id']];
        return row;
      })];
      // return [...rows];

    case 'update':

      return [...rows.map(row => {
        // console.log(row['.system.id']);
        if (row['.system.id'] === action.payload.userid) {
          row[action.payload.field] = action.payload.newValue;
        }
        return row;
      })];

    default:
      throw new Error('Unknown action type');
  }
  // if(action.type == 'revive') {
  //   return people.map(person => {
  //     if(person.name == action.payload) {
  //       person.alive = true;
  //     }
  //     return person;
  //   })
  // }
}




const ListView = (props) => {
  const classes = useStyles();

  const [cookies, setCookie] = useCookies(['open_'+props.num]);

   const [viewID, setViewID] = React.useState(0);
   const [order, setOrder] = React.useState('asc');
   const [orderBy, setOrderBy] = React.useState('calories');
   const [selected, setSelected] = React.useState([]);
   const [editColumns, setEditColumns] = React.useState(false);
   const [rows, setRows] = React.useState([]);

   const [state, dispatch] = React.useReducer(reducer, []);
   const [page, setPage] = React.useState(0);
   const [title, setTitle] = React.useState('Applications');
   const [dense, setDense] = React.useState(true);
   const [rowsPerPage, setRowsPerPage] = React.useState(100);
   const [applications, setApplications] = React.useState({});
   const [headerCells, headerCellsDispatch] = React.useReducer(headerCellsReducer, []); //...headCells
   const [loadedColumns, setLoadedColumns] = React.useState(['.system.id', '.system.date', '.system.fullname', '.system.username', '.system.status']);


   const { getApplications, getApplicationsField, getViews, getSpecailApplicationsField } = Conn_Admin();

   const FormController = useFormControllerContext();
   const Admin_Helpers = useAdminHelpersContext();
   const AppHelpers = ApplicationHelpers({FormController});

   const { loadFilters, filters, passesFilters, FilterMarkup } = Filters({headerCells:headerCells, disabled:!editColumns});
   const { saveView } = Conn_Admin();


   const handleSaveView = () => {
     saveView(viewID, title, headerCells, '', JSON.stringify(filters), setViewID);
     setEditColumns(false);
   }



   const doExport = () => {
     // Admin_Helpers.ExportAsCSV(title+'.csv', headerCells.map(cell => cell.label), state.filter(passesFilters).map(row => Object.values(row)));
     Admin_Helpers.ExportAsCSV(title+'.csv', headerCells, state.filter(passesFilters));//.map(row => Object.values(row)));
   }

   const addFieldCallback = (field, data) => {
    // appendColumn(field, data, rowz);
      // const question = FormController.getNodeByKey(field);
      data = Object.keys(data).reduce((accum, rowID) => {
        // if (["radio", "select"].includes(question.configs[0].type)) {
        //
        //   const option = FormController.findOptionById(data[rowID], question);
        //   accum[rowID] = (option !== null) ? option.name : '';
        // } else {
        //   accum[rowID] = data[rowID];
        // }
        accum[rowID] = FormController.getValueOf(field, data[rowID], Admin_Helpers, AppHelpers);

        return accum;
      }, {});

      // console.log('data',data);


      setLoadedColumns(loadedColumns.concat(field));

      dispatch({
        type: 'append',
        payload: {
          field: field,
          values: data
        }
      })
    }

    const addEmptyHeadCell = () => {
      headerCellsDispatch({
        type: 'append',
        payload: {}
      });
    }


  const costEstimateReturn = (id, data) => {
    let values = {};
    Object.keys(data).forEach(userId => {
      let estimate = AppHelpers.getEstimateData(data[userId]);
      console.log('the estimate ', estimate);
      values[userId] = "$"+AppHelpers.toCurrency(estimate[estimate.length -1].cost);
    })
    dispatch({
      type: 'append',
      payload: {
        field: '.special.cost_estimate',
        values: values
      }
    });

    setLoadedColumns(loadedColumns.concat('.special.cost_estimate'));

  }
  const costEstimateReturnFull = (id, data) => {
    (['.site.type','.stall.co_branded','.stall.site_type','.site.frontage','.site.depth','.site.power.outlets','.finalise.extra_staff_tickets']).forEach(field => {
      if (!loadedColumns.includes(field)) {
        let values = [];
        Object.keys(data).forEach(userId => {
          values[userId] = data[userId][field];
        })
        setLoadedColumns(loadedColumns.concat(field));

        dispatch({
          type: 'append',
          payload: {
            field: field,
            values: values
          }
        });
      }
    });


  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  // this needs to be a queued loader, but pauses should patch the hole for now
  async function reloadApplications() {
    for (let ii=0; ii<headerCells.length; ii++) {
      let cell = headerCells[ii];
      await sleep(500);
      if (!loadedColumns.includes(cell.id) && cell.id !== undefined) {
        if (cell.id.lastIndexOf('.special.cost_estimate') !== -1) {
          getSpecailApplicationsField('costEstimate', costEstimateReturn);
        } else {
          getApplicationsField(cell.id, addFieldCallback);
        }
      }
    }
  }

  function loadView(view) {
    setTitle(view.title);
    setViewID(view.id);
    view.columns.split('|').forEach(column => {
      headerCellsDispatch({
        type: 'append',
        payload: {id:column, label:Admin_Helpers.getQuestionTitleMap()[column]}
      });
    });

    loadFilters(view.filters);
  }

  function handleViewsLoaded(data) {

    data.map(view => {
      setTitle(view.title);
      setViewID(view.id);
      view.columns.split('|').forEach(column => {
        headerCellsDispatch({
          type: 'append',
          payload: {id:column, label:Admin_Helpers.getQuestionTitleMap()[column]}
        });
      });

      loadFilters(view.filters);
    });

  }


   const loadedApplications = (apps) => {
     // setApplications(apps);
     // const rows2 = Object.keys(apps).reduce((accum, key) => { accum.push({
     //    ['.system.id']:parseInt(key),
     //    ['.system.date']: apps[key]['application'][2],
     //    ['.system.status']: 'incomplete',
     //    ['.system.username']:apps[key]['username'],
     //    ['.system.fullname']:apps[key]['last_name']+', '+apps[key]['first_name']
     //  });
     //  return accum;
    //  console.log('the data', apps);
     // }, []);
     let apps2 = Object.keys(apps).reduce((accum, key) => {
       apps[key]['application'][3] = Admin_Helpers.getStatusList()[apps[key]['application'][3]];
        accum[key] = apps[key];
        return accum;
      }, []);
     dispatch({
       type: 'load',
       payload: apps2
     })

     //
     // getApplicationsField('.stall.stall_name', addFieldCallback);
     // getApplicationsField('.stall.site_type', addFieldCallback);
   }

   React.useMemo(() => {
     reloadApplications();
   }, [headerCells]);

   React.useMemo(() => {
     loadView(props.view);
   }, []);

   React.useMemo(() => {
      if (FormController.Flow.children.length !== 0) {
        getApplications(loadedApplications);
        // getViews(handleViewsLoaded);
      }
   }, [FormController.Flow]);





   const handleRequestSort = (event, property) => {
     const isAsc = orderBy === property && order === 'asc';
     setOrder(isAsc ? 'desc' : 'asc');
     setOrderBy(property);
   };

   const handleSelectAllClick = (event) => {
     if (event.target.checked) {
       const newSelecteds = state.map((n) => n.name);
       setSelected(newSelecteds);
       return;
     }
     setSelected([]);
   };

   const handleClick = (event, name) => {
     const selectedIndex = selected.indexOf(name);
     let newSelected = [];

     if (selectedIndex === -1) {
       newSelected = newSelected.concat(selected, name);
     } else if (selectedIndex === 0) {
       newSelected = newSelected.concat(selected.slice(1));
     } else if (selectedIndex === selected.length - 1) {
       newSelected = newSelected.concat(selected.slice(0, -1));
     } else if (selectedIndex > 0) {
       newSelected = newSelected.concat(
         selected.slice(0, selectedIndex),
         selected.slice(selectedIndex + 1),
       );
     }

     setSelected(newSelected);
   };

   const handleChangePage = (event, newPage) => {
     setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPage(0);
   };

   const handleChangeDense = (event) => {
     setDense(event.target.checked);
   };

   const appendColumn = (column, data, rowz) => {
     const rows2 = rowz.reduce((accum, row) => { row[column] = data[row.id]; accum.push(row); return accum;}, []);
     setRows(prev => [...prev, ...rows2]);
   }

   const handleEditColumns =(event) => {
     setEditColumns(true);
   };


  const handleChange =(e, exp) => {
    setCookie('open_'+props.num, exp);
  }

   const isSelected = (name) => selected.indexOf(name) !== -1;

   // const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.filter(passesFilters).length - page * rowsPerPage);

   const actionButton = (editColumns) ? (
     <Button onClick={handleSaveView} variant="contained"  color="primary">
       <Typography variant="h6" noWrap >
          Save View Changes
        </Typography>
     </Button>
     ) : (
     <Button  onClick={handleEditColumns} variant="contained" color="primary">
       <Typography variant="h6" noWrap >
         Edit Data View
       </Typography>
    </Button>);

  //<EnhancedTableToolbar numSelected={selected.length} />
   return (


     <div className={classes.root}>




     <Accordion  className={classes.paper}  expanded={cookies['open_'+props.num] === 'true'} onChange={handleChange}>

      <AccordionSummary  expandIcon={<ExpandMoreIcon />} >
            <Grid container spacing={2}>
            <Grid item xs={12}>
<Box display="flex">

         <Typography className={classes.title} variant="h6"  >
           data view [{props.num+1}]: {title}
         </Typography>
        <div className={classes.grow} />
        <Typography className={classes.title} variant="h6"  >
          [{state.filter(passesFilters).length}] items
        </Typography>



</Box>
</Grid>
</Grid>
</AccordionSummary>
<AccordionDetails>

     <Grid container spacing={2}>
     <Grid item xs={12}>
     <Box display="flex">
     {editColumns && (<TextField value={title} label="Data View Title" onChange={(e) => setTitle(e.target.value)} />)}
     <div className={classes.grow} />
       {actionButton}
       </Box>
      </Grid>
          <Grid item xs={12}>
          { FilterMarkup }
          </Grid>
                    <Grid item xs={12}>
         <TableContainer>
           <Table
             className={classes.table}
             aria-labelledby="tableTitle"
             size={dense ? 'small' : 'medium'}
             aria-label="enhanced table"
           >
             <EnhancedTableHead
               classes={classes}
               numSelected={selected.length}
               order={order}
               orderBy={orderBy}
               onSelectAllClick={handleSelectAllClick}
               onRequestSort={handleRequestSort}
               rowCount={state.filter(passesFilters).length}
               doEdit={editColumns}
               headCells={headerCells}
               addEmptyHeadCell={addEmptyHeadCell}
               handleEditColumns={handleEditColumns}
               headerCellsDispatch={headerCellsDispatch}
               handleSave={handleSaveView}
             />
             <TableBody>
               {stableSort(state, getComparator(order, orderBy))

                 .filter(passesFilters)
                 .map((row, index) => {
                   const isItemSelected = isSelected(row.name);
                   const labelId = `enhanced-table-checkbox-${index}`;
                   return (<Row headerCells={headerCells} row={row} id={row['.system.user_id']} match={props.match} dispatch={dispatch} index={index} key={index} />);
                 })}
             </TableBody>
           </Table>
         </TableContainer>


         <Box display="flex" justifyContent="flex-end">

           <Button onClick={doExport} variant="contained" className={classes.buttonDark}>
             <Typography variant="h6">
              Export as CSV
             </Typography>
           </Button>



         </Box>
         </Grid>
         </Grid>
         </AccordionDetails>
       </Accordion>

     </div>

   );
}





function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, doEdit, headCells, addEmptyHeadCell, handleEditColumns, headerCellsDispatch, reloadApplications, handleSave } = props;


  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleSaveChanges = (e) => {
    props.handleSave();
  };

  const handleAdd = (e) => {
    addEmptyHeadCell();
  }

  const handleChangeCell = (data) => {
    if (data.id === '.special.delete') {
      headerCellsDispatch({
        type: 'delete',
        payload: data.num
      });
    } else {
      headerCellsDispatch({
        type: 'update',
        payload: {
          num: data.num,
          config: { id: data.id, numeric: false, disablePadding: false, label:data.title }
        }
      });
    }
  }



  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell, ii) => (
          <TableCell
            key={headCell.id+ii}
            align={headCell.numeric ? 'right' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
          {(doEdit) ? (<ColumnSelect selected={headCell.id} number={ii} handleChange={handleChangeCell} />) :
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          }
          </TableCell>

        ))}
        {(doEdit) ? (<TableCell
        key="addColumn"
        align="right"
        padding="none">
        <IconButton onClick={handleAdd}><AddCircleIcon /></IconButton>
      </TableCell>) : <></>}
      </TableRow>
    </TableHead>
  );
}




function Row(props) {
  const { row, headerCells, match, index } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const {MessagingMarkup, handleOpenMessaging} = MessagingDialog();
  const {StatusChangerMarkup, handleOpenStatusChanger} = StatusChangerDialog();

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleViewMessages =() => {

    };

    const handleClose = () => {
      setAnchorEl(null);
    };

  return (<>
    <TableRow
      hover
      tabIndex={-1}
      key={row.name}
      onClick={() => {setOpen(!open)}}
    >
    <TableCell padding="checkbox">

    </TableCell>
    {/* {MessagingMarkup} */}
                  {StatusChangerMarkup}

      {
        headerCells.map(cell => { let value = row[cell.id]; value = (typeof value === 'object') ? '***' : value; return (<TableCell align="right" key={index+cell.id}>{value}</TableCell>)})
      }

<TableCell padding="checkbox">
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={`${match.url}/view/${props.id}/${row['.system.year']}`}>View Application</MenuItem>
        {/* <MenuItem onClick={() => {setAnchorEl(false); handleOpenMessagingOutbox(props.id)}}>View Sent Messages</MenuItem> */}
        <MenuItem onClick={() => {setAnchorEl(false); handleOpenStatusChanger([{id:props.id, label: row['.special.stall_name']}], row['.system.year'], props.dispatch)}}>Change Application Status</MenuItem>
          <MenuItem component={Link} to={`${match.url}/edit/${props.id}/${row['.system.year']}`}>Edit Application</MenuItem>
      </Menu>
    </TableCell>
    </TableRow>

    </>
  );
}

// <TableRow>
//     <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headerCells.length+1}>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         <Grid container>
//           <Grid item>
//             <Link to={`${match.url}/view/${props.id}`}><Button variant="contained"></Button></Link>
//             <Button variant="contained" onClick={() => handleOpenMessaging([{id:props.id, label: row['.special.stall_name']}])}>Message User</Button>
//             <Button variant="contained">View Menu</Button>
//           </Grid>
//         </Grid>
//     </Collapse>
//   </TableCell>
// </TableRow>


export default ListView;
