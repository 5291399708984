import React from 'react';

// import { ApplicationForm } from '../application_forms/ApplicationForm';
import { ApplicationForm } from '../application_forms/ApplicationForm';

import { makeStyles } from '@material-ui/core/styles';
import { Box, AppBar, Divider, Toolbar, Typography, IconButton, Badge, Drawer, Grid, Button } from '@material-ui/core';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Conn_Admin from 'components/admin/Conn_Admin';
import Slide from '@material-ui/core/Slide';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import ScrollToTop from 'components/ScrollToTop';

import { createTheme, ThemeProvider  } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';


import ChangePassword from '../actions/ChangePassword';
import MessagingInbox from '../application_forms/MessagingInbox';


// import { StallholderApplicationProvider, useStallholderApplication } from "../StallholderApplication";
import { useFormControllerContext, FormControllerProvider } from "components/PM_FormController";

import  { UserFeedbackProvider } from "../UserFeedback";
import Conn_Application from "../application_forms/Conn_Application";

import ApplicationHeader from "../application_forms/ApplicationHeader";
import ApplicationFooter from "../application_forms/ApplicationFooter";

// import StallholderDS from "config/StallholderDS";

import xmlData from 'config/StallholderApplication';

import './styles.css';





const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  magicbr: {
    whiteSpace: 'pre-line',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
   display: 'none',
   [theme.breakpoints.up('md')]: {
     display: 'flex',
   },
 },
 
  footer: {
    height: "243px",
    border: '10px solid #fff',
    borderBottom: 0,
    background: "#333",
    borderTopLeftRadius: "30px",
    borderTopRightRadius:"30px",
    margin: "0 "+theme.spacing(1)+'px',
    padding: '32px 64px',
    boxSizing: 'border-box',
    position: 'absolute',
    zIndex: 5,
    width: 'calc(100% - 16px)',
    boxSizing: 'border-box',
    bottom: 0
  },
  footerLayout: {
    display: 'grid',
    gridTemplateColumns: '22fr 22fr 22fr',
    '& > div:nth-child(2)': {
      justifySelf: 'center'
    },
  },
  save_the_date: {
    border: '5px solid #000',
    borderRadius: '25px',
    background: '#793478',

    display: 'flex'
  },
  info: {
    color: '#fff',
    fontSize: '20px',
    lineHeight: '1.1',
    marginBottom: '16px',
    paddingTop: '30px',
    '& strong': {
      color: '#ee680f',
      textTransform: 'uppercase'
    }
  },
  assistance: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    textTransform: 'uppercase',
    '& ul': {
      listStyle: 'none',
      margin: '1em 0',
      padding: 0,
      '& li': {
        margin: '.8em 0',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        '& a': {
          color: '#fff',
          textDecoration: 'none',
          marginLeft: '32px',
          textTransform: 'lowercase',
          lineHeight: '1.0'
        }
      }
    }
  },
  credit: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    '& a': {
      color: '#77ad30',
      textDecoration: 'none'
    },
  },
  submissions: {
    color: '#fff',
    fontSize: '12px'
  },
  tandc: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '30px'
  },

  footerShard: {
    width: '277px',
    height: '335px',
    position: 'absolute',
    bottom: '60px',
    right: '-17px',
    zIndex: 10,
    pointerEvents: 'none'
  },
  footerShardLeft: {
    width: '710px',
    height: '334px',
    position: 'absolute',
    bottom: '60px',
    left: '-17px',
    zIndex: 1,
    pointerEvents: 'none'
  },
  headerShard: {
    width: '477px',
    height: '255px',
    position: 'fixed',
    top: '40%',
    right: '0px',
    zIndex: 1
  },
  bgShard: {
    width: '709px',
    height: '365px',
    position: 'fixed',
    top: '20%',
    left: '-17px',
    zIndex: 1
  },
  toTop: {
    position: 'absolute',
    right: '30px',
    top: '30px',
    color: '#fff',
    background: '#793478',
    borderRadius: '50%',
    padding: '10px'
  },
  saveThe: {
    width: 910/2+'px',
    height: 130/2+'px'
  }
}));









const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const MessagingDialog = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [message, setMessage] = React.useState({});
  const {markMessageRead} = Conn_Application();

  const {handleUpdateMessage} = props;

  const callback = (msg) => {

    handleUpdateMessage(msg);
  }

  const classes = useStyles();
  return {
    MessagingMarkup: (<>

  <Dialog
  open={dialogOpen}
  TransitionComponent={Transition}
  onClose={() => setDialogOpen(false)}
  maxWidth={'lg'}
  aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" className={classes.heading} >
      {message.subject}
    </DialogTitle>
    <DialogContent>
      <Typography>Received : {message.date }</Typography>
      <Divider />
      <Typography className={classes.magicbr}>{message.message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDialogOpen(false)} color="secondary" variant="contained">
        <Typography variant="h6">Close</Typography>
      </Button>
    </DialogActions>
  </Dialog></>),
  handleOpenMessaging: (msg) => {
    setDialogOpen(true);
    setMessage(msg);
    if (msg.status === 1) {
      markMessageRead(msg, callback);
    }
   }
}
}

export default function ApplicationPage() {
  const classes = useStyles();

  const {ChangePasswordDialogue, ChangePasswordOpener} = ChangePassword();

  const [messages, setMessages] = React.useState([]);
  

const handleRequestChangePassword = () => { ChangePasswordOpener(); };




    return (
      <>
      {/* <ThemeProvider theme={outerTheme}> */}

      <FormControllerProvider xmlData={xmlData}>
      <UserFeedbackProvider>
      <ScrollToTop >

    
          <ApplicationHeader />

          <ApplicationForm  />


        { ChangePasswordDialogue }



          <ApplicationFooter />


        {/* <img src={process.env.PUBLIC_URL + '/shard_footer_left_yellow.png'} className={classes.footerShardLeft} />

      <Box boxShadow={12} className={classes.footer} spacing={16}>
        <img src={process.env.PUBLIC_URL + '/shard_footer_right.png'} className={classes.footerShard} />
        <div className={classes.footerLayout} id="dirtyFooterHook">
          <div>
            <Typography  className={classes.info}>
              <strong>Festivale 2023</strong> applications for Stallholders<br />
              closes on <strong>15 July 2022 at 5:00pm</strong>.
            </Typography>
            <Typography className={classes.submissions}>
              Submissions after this time are not permitted and will therefore not be evaluated.
            </Typography>
          </div>
          <div>
            <div className={classes.save_the_date}>
                <img src={process.env.PUBLIC_URL + '/festivale_2023_save_the_date.png'} className={classes.saveThe} />

            </div>
            <Typography className={classes.tandc}>
                  Stallholder <a href="/Stallholder Application TC Final_web version.pdf">Terms &amp; Conditions</a>
            </Typography>
          </div>
          <div>
          <div className={classes.assistance}>
            <Typography>Need assistance or have any questions, <br />then let us know:</Typography>
            <ul>
                <li><PhoneIcon /><Typography><a href="tel:63349990">6334 9990</a></Typography></li>
                <li><MailOutlineIcon /><Typography><a href="mailto:eventmanager@festivale.com.au">eventmanager@festivale.com.au</a></Typography></li>
            </ul>
          </div>
          
          <Typography className={classes.credit} align="right">
            created by <a href="https://purposemarketing.com.au">PURPOSE Marketing</a>
          </Typography>
          </div>
        </div>
        <ArrowUpwardIcon className={classes.toTop} onClick={toTop} />
      </Box> */}




      </ScrollToTop>
      </UserFeedbackProvider>
      </FormControllerProvider>


      {/* </ThemeProvider> */}

      </>
    )



}
