import React from 'react';

import clsx from 'clsx';

import StoreIcon from '@material-ui/icons/Store';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import WorkIcon from '@material-ui/icons/Work';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';

import {  Typography, Stepper, Step, StepLabel, StepConnector, Badge } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import useConfirmation from 'components/application_forms/Confirmation';

import { useFormControllerContext } from "components/PM_FormController";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: -3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    "&.MuiBadge-colorSecondary": {
      background: "#77ad30"
    },
    "&.MuiBadge-colorPrimary": {
      background: "#ee680f"
    }
  },
}))(Badge);


const useStyles = makeStyles((theme) => ({
  root: {
    background: "#333",
    marginBottom: "-64px",
    position: "relative",
    padding: "24px 106px 24px 350px",
    zIndex: 5,
    "& .MuiStepLabel-labelContainer span": {
      color: "#888",
      textTransform: "uppercase",
      fontSize: "12px",
      marginTop: "8px",
      "&.MuiStepLabel-completed": {
          color: "#fff"
      },
      "&.MuiStepLabel-active": {
        color: "#fff"
      }
    },
  },
 
}));
const useMyStyles = makeStyles((theme) => ({
  stepperWrapper: {
    position: 'relative'
  },
  title: {
    width: '160px',
    height: '60px',

    position: 'absolute',
    left: '200px',
    top: '24px',
    zIndex: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    '& *': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      lineHeight: '0.9'
    },
    '& h4': {
      fontSize: '20px'
    },
    '& h6': {
      fontSize: '20px'
    }
  }
 
}));


const GetIcon = (props) => {
  const { active, completed } = props;

  const FormController = useFormControllerContext();
  const classes = useColorlibStepIconStyles();
  const icons = [
      <HomeIcon />,
      <StoreIcon />,
      <PersonIcon />,
      <LocationOnIcon />,
      <WorkIcon />,
     <MenuBookIcon />,
     <AssignmentTurnedInIcon />
   ];
  const errorCount = FormController.getAbsoluteErrorCount(props.icon-1);// 0;//GetCount(props.icon-1);//state.values['errorCount_'+ApplicationFlow[props.icon-1].name];
  return (
    <div
     className={clsx(classes.root, {
       [classes.active]: active,
       [classes.completed]: completed,
     })}
   >
    <StyledBadge
        overlap="rectangular"
        badgeContent={(errorCount === 0) ? '✓' : errorCount}
        color={(errorCount === 0) ? 'secondary' : 'primary'} >
      { icons[props.icon-1] }
    </StyledBadge>
   </div>
  );
};

//



const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient(to right, #793478 0%, #ee680f 100%)',

    },
  },
  completed: {
    '& $line': {
      background: "#793478"
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#793478',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: '#793478',
    background: "#ee680f",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: "#793478",
    color: '#fff',
  },
});


export default function GetStepper(saveStallholderApplication) {
  const [activeStep, setActiveStepFunc] = React.useState(0);


  const classes = useStyles();
  const myClasses = useMyStyles();

  const { ConfirmationWindow, askForConfirmation } = useConfirmation({
    title:'Step not completed',
    task: 'switch to another step, you have not completed all the question in this step of the application',
    onYes:() => {  }
  });

  const FormController = useFormControllerContext();

  function setActiveStep(step) {
      setActiveStepFunc(step);
      // turn off saving
      if (FormController.state.values['status'] ===1 || FormController.state.values['status']===5) {
        saveStallholderApplication();
      }

      setTimeout(() => window.scrollTo(0, 0), 2);
  }

  function handleChangeStep(step) {
    if (FormController.getAbsoluteErrorCount(activeStep) !== 0) {
      // FormController.
      FormController.touchAllNodes(FormController.Flow.children[activeStep]);
      askForConfirmation({
      onYes:() => setActiveStep(step)
      });
    } else {
      setActiveStep(step);
    }
  };
  const steps = FormController.Flow.children.filter((step) => step.id !== 'extra').map((item, ii) => (
    <Step key={item.name} onClick={() => handleChangeStep(ii)}>
      <StepLabel StepIconComponent={GetIcon}>{item.name}</StepLabel>
    </Step>
  ));

  const drawStepper = () => {
    return (<><div className={myClasses.stepperWrapper}>
      <Stepper classes={classes} activeStep={activeStep} alternativeLabel={true} connector={<ColorlibConnector />}>
          { steps }

        </Stepper>
      <div className={myClasses.title}>
          <Typography variant="h4">Application</Typography>
          <Typography variant="h6">Progress Bar</Typography>
        </div>
      </div>
      <ConfirmationWindow /></>
    );
  };

  return {
    drawStepper, activeStep, handleChangeStep
  };
}
