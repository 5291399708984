import React from 'react';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Conn_Signing from './Conn_Signing';
// import { PM_Validation } from '../PM_Validator';
import PM_FormController from "components/PM_FormController";
import { PM_Key } from '../PM_Conditionals';
//
// const INITAL_STATE = {
//   username: '',
//   password: '',
//   password2: '',
//   first_name: '',
//   last_name: '',
//   email: '',
//   authenticated: false,
//   submitted: false
// }
//



function SignUp() {
  const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
  <app onSubmit="props.getDoRegister()(state.values);">
  <group>
    <question id="name_first" name="First Name">
      <config type="text" />
      <validator id="isRequired" />
    </question>

    <question id="name_last" name="Surname Name">
      <config type="text" />
      <validator id="isRequired" />
    </question>

    <question id="email" name="Email">
      <config type="text" />
      <validator id="isRequired" />
      <validator id="isEmail">
        <config  message="Not a valid email address" />
      </validator>
      <validator id="isBlacklisted">
        <config message="An account with this email already exists." value="values['.email_isnotequal']" />
      </validator>
    </question>

    <question id="password" name="Password">
      <config type="password" />
      <validator id="isRequired" />
      <validator id="isMinLength">
        <config message="Must be at least 6 characters, Please make it more secure" value="6" />
      </validator>
    </question>

    <question id="password2" name="Password Confirmation">
      <config type="password" />
      <validator id="isRequired" />
      <validator id="isEqual">
        <config message="Your passwords don’t match" dynamicValue="values['.password']" />
      </validator>
    </question>

    </group>
  </app>
  `;




// const { getFieldProps, getFormProps, errors, isFormValid, values, dispatch } = PM_Validation(config);


function getDoRegister() {
  return doRegister;
}
const FormController = PM_FormController({xmlData: xmlData, getDoRegister:getDoRegister});
const {  doRegister  } = Conn_Signing(FormController.dispatch);
// export default class SignUp extends Component {
  // render() {
  const form = (FormController.Flow.children.length > 0 && (
      <form {...FormController.getFormProps()} >
          <Grid container direction="column" alignItems="stretch" spacing={2}>

            <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <PersonIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.name_first'))}
                  variant="standard"
                  fullWidth
                />
            </Grid>
            </Grid>

            <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <PersonIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.name_last'))}
                  variant="standard"
                  fullWidth
                />
            </Grid>
            </Grid>

            <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <EmailIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.email'))}
                  variant="standard"
                  fullWidth
                />
            </Grid>
            </Grid>

            {/* <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <PersonIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.username'))}
                  variant="standard"
                  fullWidth
                />
                </Grid>
            </Grid> */}



            <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <LockIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.password'))}
                  variant="standard"
                  fullWidth
                />
            </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="flex-end">
              <Grid item>
                  <LockOutlinedIcon />
              </Grid>
              <Grid item xs >
                <TextField
                   {...FormController.getFieldProps(FormController.getItemByKey('.password2'))}
                  variant="standard"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item>
            <Box mt={2}>
              <Button variant="contained" fullWidth color="primary" type="submit">
                 Register
               </Button>
              </Box>
           </Grid>

          </Grid>
    </form>
  ));
  return form || <></>;
  }

export default SignUp;
