import React from 'react';
import {  useHistory, Link, Redirect, BrowserRouter as Router } from 'react-router-dom';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

import Conn_Signing from './Conn_Signing';

import PM_FormController from "components/PM_FormController";

import { PM_Key } from '../PM_Conditionals';


function Login() {

  const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
  <app onSubmit="props.getDoLogin()(state.values);">
  <group>
    <question id="email" name="Email">
      <config type="email" />
      <validator id="isRequired" />
      <validator id="isBlacklisted">
        <config message="This email could not be found" value="values['.email_isnotequal']" />
      </validator>
    </question>

    <question id="password" name="Password">
      <config type="password" />
      <validator id="isRequired" />
      <validator id="isBlacklisted">
        <config message="Incorrect Password" value="values['.password_isnotequal']" />
      </validator>
    </question>
    </group>
  </app>
  `;




  // const config = (values) => ({
  //   fields: {
  //     username: {
  //       showErrors: 'blur',
  //       type: "text",
  //       nice_name: "Username",
  //       validators: {
  //         isRequired: { message: 'Please enter your username' },
  //         isNotEqual: { message: 'This username could not be found.', value: values.username_isnotequal }
  //       }
  //     },
  //     password: {
  //       type: 'password',
  //       showErrors: 'blur',
  //       type: "password",
  //       nice_name: "Password",
  //       validators: {
  //         isRequired: { message: 'Please enter your password' },
  //         isNotEqual: { message: 'Incorrect Password', value: values.password_isnotequal }
  //       }
  //     }
  //   },
  //   showErrors: 'hybrid',
  //   onSubmit: e => { doLogin(values);  }
  // });
  //
  function getDoLogin() {
    return doLogin;
  }
  const FormController = PM_FormController({xmlData: xmlData, getDoLogin:getDoLogin });

  const history = useHistory();
  const {  doLogin  } = Conn_Signing(FormController.dispatch);


  const form = (FormController.Flow.children.length > 0 && (
    <form {...FormController.getFormProps()}>
      <Grid container direction="column" alignItems="stretch" spacing={2}>

        <Grid container item spacing={1} alignItems="flex-end">
          <Grid item>
              <PersonIcon />
          </Grid>
          <Grid item xs >
            <TextField
               {...FormController.getFieldProps(FormController.getItemByKey('.email'))}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} item alignItems="flex-end">
          <Grid item>
              <LockIcon />
          </Grid>
          <Grid item xs>
            <TextField
               {...FormController.getFieldProps(FormController.getItemByKey('.password'))}
              variant="standard"
              fullWidth
              />
          </Grid>
        </Grid>

        <Grid item xs={12} >
        <Box mt={2}>
          <Button variant="contained" fullWidth color="primary" type="submit">
             Login
           </Button>
          </Box>
       </Grid>

      </Grid>


    </form>
  ));
  return form || <></>;
}

export default Login;
