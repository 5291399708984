import React from 'react';

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';


import { useFormControllerContext } from "components/PM_FormController";

function InputWrapper(props) {
  const FormController = useFormControllerContext();
  
  let isComplete = FormController.isNodeValid(FormController.findChildById(props.q.targetNode, FormController.Flow));;
  if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
    FormController.dispatch({
      type: 'change',
      payload: {
        [FormController.getKey(props.q)]: isComplete
      }
    });
  }
  return <props.FieldContainer key={FormController.getKey(props.q)} question={props.q} />

  // if (props.q.id === 'name') {
  //   const isComplete = FormController.isNodeValid(FormController.findChildById('personal', FormController.Flow));
  //   if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //     FormController.dispatch({
  //       type: 'change',
  //       payload: {
  //         [FormController.getKey(props.q)]: isComplete
  //       }
  //     });
  //   }
  // } else if(props.q.id === 'layout') {
  //     const isComplete = FormController.isNodeValid(FormController.findChildById('plan', FormController.Flow));
  //     if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //       FormController.dispatch({
  //         type: 'change',
  //         payload: {
  //           [FormController.getKey(props.q)]: isComplete
  //         }
  //       });
  //     }
  // } else if(props.q.id === 'passes') {
  //     const isComplete = true;
  //     if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //       FormController.dispatch({
  //         type: 'change',
  //         payload: {
  //           [FormController.getKey(props.q)]: isComplete
  //         }
  //       });
  //     }
  // } else if(props.q.id === 'images') {
  //     const isComplete = FormController.isNodeValid(FormController.findChildById('photo_01', FormController.Flow));;
  //     if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //       FormController.dispatch({
  //         type: 'change',
  //         payload: {
  //           [FormController.getKey(props.q)]: isComplete
  //         }
  //       });
  //     }
  // } else if(props.q.id === 'gasimdemn') {
  //     const isComplete = FormController.isNodeValid(FormController.findChildById('gas', FormController.Flow));;
  //     if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //       FormController.dispatch({
  //         type: 'change',
  //         payload: {
  //           [FormController.getKey(props.q)]: isComplete
  //         }
  //       });
  //     }
  //
  // } else if(props.q.id === 'electric') {
  //     const isComplete = FormController.isNodeValid(FormController.findChildById('power', FormController.Flow));
  //     if (FormController.state.values[FormController.getKey(props.q)] !== isComplete) {
  //       FormController.dispatch({
  //         type: 'change',
  //         payload: {
  //           [FormController.getKey(props.q)]: isComplete
  //         }
  //       });
  //     }
  //   }
  //
  //
  // return <props.FieldContainer key={FormController.getKey(props.q)} question={props.q} />
};

const ChecklistLayout = (props) => {
  const FormController = useFormControllerContext();



  return (
    <Grid container spacing={2}>

    <Grid item xs={12}>
    <TableContainer component={Paper}>
       <Table  aria-label="simple table">
        <TableBody>
          <InputWrapper q={FormController.getItemByKey('.finalise.name')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.layout')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.electric')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.images')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.stallcl')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.personalcl')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.sitecl')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.businesscl')}  {...props} />
          <InputWrapper q={FormController.getItemByKey('.finalise.menucl')}  {...props} />
        </TableBody>
      </Table>
    </TableContainer>

    </Grid>
  </Grid>

  );

}

export default ChecklistLayout;
