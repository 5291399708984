import { useHistory } from 'react-router-dom';
import axios from 'axios';
// import M from 'materialize-css';

import Config from '../../Config.js';

const instance = axios.create({
  withCredentials: true,
  baseURL: Config.server.backend,
  crossDomain: true
})

function Conn_Application(state, dispatch, showMessage) {
  const history = useHistory();


//   useEffect(() => {
//     getLatestStallholder();
//     // dispatch({
//     //   type: 'change',
//     //   payload: { 'personal.first_name': 'some value' }
//     // });
//   },[]
//
// );


  // function isPageValid(pages) {
  //   let valid = {};
  //   let rtn = {};
  //   pages.map((page) => {
  //     valid[page.name] = [];
  //     page.items.map((item) => {
  //       valid[page.name].push(values[item] != "");
  //     });
  //     rtn[page.name] = true;
  //     valid[page.name].forEach ((val) => {
  //       if (!val) {
  //         rtn[page.name] = false;
  //       }
  //     });
  //   });
  //   return rtn;
  // }



  function getAllMessages(callback) {
    var params = new URLSearchParams();
    params.append('task', 'getAll');

    instance.post("messages.php",params

      ).then((response) => {
        // console.log("response.data");
        // console.log(response.data);
      if ('data' in response.data) {
        // showMessage("Load Messages Successful!", "success");
        callback(response.data.data);
        // setValues({
        //   ...values,
        //   stallholder: new Stallholder(response.data.stallholder)
        //   });
      //  setStallholder(new Stallholder(response.data.stallholder));
            // M.toast({html: 'Application Saved'})
          //setStallholder();
      }
      if ('error' in response.data) {
        showMessage("Error: "+response.data.error, "error");
      }
    });
  }

  function markMessageRead(msg, callback) {
    var params = new URLSearchParams();
    params.append('task', 'markRead');
    params.append('id', msg.id);

    instance.post("messages.php",params

      ).then((response) => {
        // console.log("response.data");
        // console.log(response.data);
      if ('success' in response.data) {
        // showMessage("Load Messages Successful!", "success");
        callback(msg);
        // setValues({
        //   ...values,
        //   stallholder: new Stallholder(response.data.stallholder)
        //   });
      //  setStallholder(new Stallholder(response.data.stallholder));
            // M.toast({html: 'Application Saved'})
          //setStallholder();
      }
      if ('error' in response.data) {
        showMessage("Error: "+response.data.error, "error");
      }
    });
  }


  function saveStallholderApplication() {
    //alert('save stallholder application');
    // axios.post(Config.server.backend+"application3.php",
    //      {
    //         withCredentials: true,
    //         crossDomain: true,
    //         params: {
    //           task: "update",
    //           payload: JSON.stringify(state.values)
    //         }
    //
    //       }
    var bodyFormData = new FormData();
    bodyFormData.set('task', 'update');
    bodyFormData.set('payload', JSON.stringify(state.values));

    axios({
          method: 'post',
          url:   Config.server.backend+"application3.php", 
          withCredentials: true,
          crossDomain: true,
          data: bodyFormData
         }

      ).then((response) => {
        // console.log("response.data");
        // console.log(response.data);
      if ('success' in response.data) {
        // console.log(showMessage);
        showMessage("Save Successful!", "success");
        // setValues({
        //   ...values,
        //   stallholder: new Stallholder(response.data.stallholder)
        //   });
      //  setStallholder(new Stallholder(response.data.stallholder));
            // M.toast({html: 'Application Saved'})
          //setStallholder();
        dispatch({
          type: 'change',
          payload: { id: response.data.success }
        });
      }
      if ('warning' in response.data) {
        showMessage("Warning: Please make sure you are the only person editing this application, data may be lost.", "warning");
        dispatch({
          type: 'change',
          payload: { id: response.data.warning }
        });
      }
      if ('error' in response.data) {
        showMessage("Error: "+response.data.error, "error");
      }
    });
  }
  //
  // function packData(name, data) {
  //   // //alert('pack data '+name);
  //   // let packed = {};
  //   // storeholder_datastructure.map((item) => {
  //   //   if (item.pack != null && item.pack == name && data[item.key] != undefined) {
  //   //     // alert(data[item.key]);
  //   //     packed[item.key.split('.')[1]] = data[item.key];
  //   //   }
  //   // });
  //   // return packed;
  // }
  // function unpackData(name, data) {
  //
  // }
  function getFileInfo(id, question, getKey) {
    axios.get(Config.server.backend+"fileinfo.php",

         {
            withCredentials: true,
            crossDomain: true,
            params: {
              id: id
            }
          }
      ).then((response) => {
       if ('details' in response.data) {
         // dispatch({
         //   type: 'change',
         //   payload: { ...response.data.success }
         // });
         // showMessage('file info'+ response.data.details.name, 'error');
         // console.log(getKey(question)+'_filename');
         dispatch({
              type: 'change',
              payload: { [getKey(question)+'_filename'] : response.data.details.name }
         });

         // M.updateTextFields();
       }
    //   if ('error' in response.data) {
    //     setErrors(response.data.error);
    //   }
    });
  }


  function getImageURL(id) {
    return Config.server.backend+"readfile.php?id="+id;
  }

  function uploadFile(file, fieldKey, isImage = false) {
    var bodyFormData = new FormData();
    bodyFormData.set('submit', 'submit');
    if (isImage) bodyFormData.set('isImage', 'true');
    bodyFormData.append('file', file);
    axios({
          method: 'post',
          url:   Config.server.backend+"upload.php",
          data: bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' },
          withCredentials: true,
          crossDomain: true,
         }
       ).then((response) => {
          if ('id' in response.data) {
            dispatch({
                 type: 'change',
                 payload: { [fieldKey]:response.data.id }
            });


          } else if ('error' in response.data) {
            showMessage("Upload Failed! : "+response.data.error, "error");
          }

       });
  }

  function getLatestByPDFAccessKey(key) {
    var params = new URLSearchParams();
    params.append('payload', key);
    instance.post("pdf_login.php", params
    ).then((response) => {
      if ('success' in response.data) {
        dispatch({
          type: 'change',
          payload: { ...response.data.success }
        });
      }
    });
  }

  function getMenuByStallNumber(stallNumber) {
    axios.get(Config.server.backend + "menu.php",
      {
        withCredentials: true,
        crossDomain: true,
        params: {
          task: 'get',
          id: stallNumber
        }
      }
    ).then((response) => {
      if ('success' in response.data) {

        dispatch({
          type: 'change',
          payload: { ...response.data.success }
        });

        // M.updateTextFields();
      }
    });
  }

  function getLatestStallholder() {
    axios.get(Config.server.backend+"application2.php",

         {
            withCredentials: true,
            crossDomain: true,
            params: {
              task: 'get'
            }
          }
      ).then((response) => {
       if ('success' in response.data) {

         dispatch({
           type: 'change',
           payload: { ...response.data.success }
         });
         console.log(response.data.success);
         if (response.data.success.status === 11) {
           history.push('/dashboard');
         }
         // M.updateTextFields();
       }
       if ('status' in response.data && response.data.status === 'locked') {
           history.push('/auth/locked');
       }
      //  if ('status' in response.data && response.data.status === 'dashboard') {
      //    history.push('/dashboard');
      //  }
      if ('error' in response.data) {
          history.push('/auth');
      }
    });
  }

function finaliseApplication() {
  var bodyFormData = new FormData();
  bodyFormData.set('task', 'finalise');
  bodyFormData.set('payload', JSON.stringify(state.values));

  axios({
        method: 'post',
        url:   Config.server.backend+"application3.php",
        withCredentials: true,
        crossDomain: true,
        data: bodyFormData
       }

    ).then((response) => {
     if ('success' in response.data) {
        history.push('/auth/pdf/'+response.data.success);
       // dispatch({
       //   type: 'change',
       //   payload: { ...response.data.success }
       // });

       // M.updateTextFields();
     }
    // if ('error' in response.data) {
    //     history.push('/auth');
    // }
  });
}

  // function festchUser() {
  //   axios.get("http://localhost:8888/fsh_backend/getUser.php",
  //        {
  //           withCredentials: true,
  //           crossDomain: true
  //         }
  //     ).then((response) => {
  //     if ('username' in response.data) {
  //       setValues({
  //         ...values,
  //         username: response.data.username
  //       });
  //     }
  //     if ('error' in response.data) {
  //       setErrors(response.data.error);
  //     }
  //   });
  // }


  function getLatestDashboard(callback) {
    var params = new URLSearchParams();
    params.append('task', 'getDashboard');

    instance.post("application3.php", params

    ).then((response) => {
      // console.log("response.data");
      // console.log(response.data);
      if ('success' in response.data) {
        // showMessage("Load Messages Successful!", "success");
        callback(response.data.success);
        // setValues({
        //   ...values,
        //   stallholder: new Stallholder(response.data.stallholder)
        //   });
        //  setStallholder(new Stallholder(response.data.stallholder));
        // M.toast({html: 'Application Saved'})
        //setStallholder();
      }
      if ('error' in response.data) {
        // showMessage("Error: " + response.data.error, "error");
      }
    });
  }


  return { getLatestByPDFAccessKey, markMessageRead, getAllMessages, uploadFile, getLatestStallholder, getMenuByStallNumber, saveStallholderApplication, getImageURL, getFileInfo, finaliseApplication, getLatestDashboard  };

}
export default Conn_Application;
