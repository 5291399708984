import React from 'react';

import { Button } from '@material-ui/core';

import { useFormControllerContext } from "components/PM_FormController";
import useConfirmation from 'components/application_forms/Confirmation';
import Conn_Application from "components/application_forms/Conn_Application";

export default function Input_Button(props) {

  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;
  const { finaliseApplication } = Conn_Application(FormController.state, FormController.dispatch);
  const { ConfirmationWindow, askForConfirmation } = useConfirmation({title:'submit and lock application', task: 'submit and lock this application. If you choose to continue you will not be able to login and edit this application any further', onYes:() => {FormController.finalSubmit(finaliseApplication);} });
  let canEdit = ("canEdit" in props && props.canEdit);

  const errorCount = FormController.getAbsoluteErrorCount(7);

  function handleAdd(id) {
    FormController.addInstance(FormController.getItemByKey(id));
  }
  function finalSubmit() {
      askForConfirmation();
  }

  const handleFunction = (e) => {
    eval(question.configs[0].function);
    props.pokeFlow();
  }

  return (
    <>
    <ConfirmationWindow />
      <Button  variant="contained" color="primary" data-c={errorCount} disabled={!canEdit || (question.id === 'finalSubmit' && errorCount !== 0)} onClick={handleFunction}>{question.name}</Button>
    </>
  );
  //  needs to go back in but also need to pass the correct form node to validate
}
