import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Box, AppBar, Divider, Toolbar, TextField, Typography, IconButton, Badge, Drawer, Grid, Button } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';

import FindReplaceIcon from '@material-ui/icons/FindReplace';
import SearchIcon from '@material-ui/icons/Search';

import Conn_Admin from 'components/admin/Conn_Admin';
import FileInput from 'components/admin/FileInput';

const FileSummary = (props) => {
  const { file } = props;
  const { getFileURL } = Conn_Admin(); 
  return (<>

    <TableRow
      key={file.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {file.name}
      </TableCell>
      <TableCell align="right"><IconButton onClick={(e) => { window.open(getFileURL(file.file_id)) }}><SearchIcon fontSize="small" /></IconButton></TableCell>
      <TableCell align="right"><IconButton onClick={() => { props.editCallback(file) }}><FindReplaceIcon fontSize="small" /></IconButton></TableCell>
    </TableRow>

  </>
  );
}

const FileList = (props) => {
  const [files, setFiles] = React.useState([]);
  
  const { getFileList } = Conn_Admin();
 
  const loadDashboardFiles = () => {
    getFileList((res) => {
      setFiles(res);
    });
  }

  React.useMemo(() => {
    loadDashboardFiles();
  }, [props.refresh]);

  
  return (
     <TableContainer component={Paper}>
      <Table aria-label="file manager" size="small">
        <TableHead>
          <TableRow>
            <TableCell>File Title</TableCell>
            <TableCell align="right">View</TableCell>
            <TableCell align="right">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index) => <FileSummary key={'file-'+index} file={file} editCallback={props.editCallback} />) }
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const FileForm = (props) => {

  const [name, setName] = React.useState(props.name || '');
  const [fileID, setFileID] = React.useState(props.file_id || -1);

  const { createFile, onServer } = Conn_Admin();
  const _handleCreate = () => {
    if (props.edit) {
      onServer('updateFile', {id:props.id, name:name, file_id:fileID}, props.callback);
    } else {
      createFile(name, fileID, props.callback);
    }
    // props.setDialogOpen(false);

  }
 

  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField variant="outlined" label="File Title" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
    </Grid>
    <Grid item xs={12} container justify="center">
      <FileInput callback={setFileID} fileID={fileID} />
    </Grid>
    <Grid item xs={12}>
      <Button onClick={_handleCreate} color="primary" variant="contained" fullWidth>
        <Typography variant="h6">Save File Details</Typography>
      </Button>
    </Grid>
  </Grid>);
}


const FileManager = (props) => {
  const [open, setOpen] = React.useState(false);
  const [editProps, setEditProps] = React.useState({});
  const [refresh, setRefresh] = React.useState(0);
  
  const _handleEdit = (file) => {
    setEditProps({ id:file.id, name: file.name, file_id:file.file_id, edit:true});
    setOpen(true);
  }

  return (<>
    <div><FileList editCallback={_handleEdit} refresh={refresh}/></div>

    



    <Button 
      color="secondary" 
      variant="contained"
      onClick={() => setOpen(true)}
    >
      <Typography variant="h6">Add New File</Typography>
    </Button>


    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={'lg'}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Add New File
      </DialogTitle>
      <DialogContent>
        <FileForm callback={() => { setOpen(false); setRefresh(refresh + 1); props.filesChanged(refresh+2) }} {...editProps} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary" variant="contained">
          <Typography variant="h6">cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>

  </>
  );
}
export default FileManager;