import React from 'react';


import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';

import Conn_Signing from './Conn_Signing';

import PM_FormController from "components/PM_FormController";

export default function LoginForgotten(props) {

  const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
  <app onSubmit="props.getDoForgotten()(state.values);">
  <group>
    <question id="email" name="Email Address">
      <config type="text" />
      <validator id="isRequired" />
      <validator id="isBlacklisted">
        <config message="This email address could not be found" value="values['.email_isnotequal']" />
      </validator>
    </question>
    </group>
  </app>
  `;

  // const config = fields => ({
  //   fields: {
  //     // username: {
  //     //   isRequired: { message: 'Please fill out a username' },
  //     // },
  //
  //     email: {
  //       showErrors: 'blur',
  //       type: "text",
  //       nice_name: "Email Address",
  //       validators: {
  //         isRequired: { message: 'Please enter your email address' },
  //         isNotEqual: { message: 'This email address could not be found.', value: fields.email_isnotequal }
  //       }
  //     }
  //   },
  //   showErrors: 'hybrid',
  //   onSubmit: e => { doForgotten(values, dispatch); }
  // });
  function getDoForgotten() {
    return doForgotten;
  }
  const FormController = PM_FormController({xmlData: xmlData, getDoForgotten: getDoForgotten}); //getDoLogin:getDoLogin
  const {  doForgotten  } = Conn_Signing(FormController.dispatch);


  let form = (FormController.Flow.children.length > 0 && (
      <form {...FormController.getFormProps()}>

        <Grid container direction="column" alignItems="stretch" spacing={2}>
          <Grid container item spacing={1} alignItems="flex-end">
            <Grid item>
                <EmailIcon />
            </Grid>
            <Grid item xs >
              <TextField
                {...FormController.getFieldProps(FormController.getItemByKey('.email'))}
                variant="standard"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} >
          <Box mt={2}>
            <Button variant="contained" fullWidth color="primary" type="submit">
               Reset Password
             </Button>
            </Box>
         </Grid>

        </Grid>


      </form>
    ));

    if ('resetCreated' in FormController.state.values) {
      form = <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>An email has been sent to <strong>'{FormController.state.values['.email']}'</strong> with details on how to reset your password.</Typography>
        </Grid>
      </Grid>;
    }

    return form || <></>;

}
