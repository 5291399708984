import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import { useFormControllerContext } from "components/PM_FormController";

const ColumnSelect = (props) => {

  const FormController = useFormControllerContext();

  const handleChange = (e) => {


    props.handleChange({num:props.number,
        id: e.target.value,
        title: titleMap[e.target.value]});
  }



  // const listOfQuestions = FormController.getAllNodes('question', FormController.Flow).filter(q => q.configs[0].type !== 'info').map(question => <>{FormController.getKey(question)} = {question.name}<br /></>);
  const groupedQuestions = FormController.Flow.children.reduce((accum, step) => {
    accum[step.id] = FormController.getAllNodes('question', step).filter(q => q.configs[0].type !== 'info').reduce((accum2, question) => { accum2.push({[FormController.getKey(question)] : question.name}); return accum2; }, []);
    return accum;
  }, { Special: [
    {'.special.delete':'Remove Column'},
    {'.special.stall_name': 'Stall Name Full'},
    {'.special.cost_estimate': 'Cost Estimate'},
    { '.extra.site_number': 'Site Number' },
    {'.extra.approval' : 'Approval Progress'},
    {'.special.all.stall': 'All Stall Data'},
    {'.special.all.personal': 'All Personal Data'},
    {'.special.all.site': 'All Site Data'},
    {'.special.all.business': 'All Business Data'},
    {'.special.all.bank': 'All Bank Data'},
    {'.special.all.menu': 'All Menu Data'},
    { '.special.all.gas': 'All Gas Data' },
  ], System: [{ '.system.id': 'ID' }, { '.system.date': 'Last Updated' }, {'.system.year': "Year"}, { '.system.status': 'Status' }, { '.system.username': 'Username' }, { '.system.fullname': 'Full Name' }, { '.system.updated': 'Last Updated' }] });

  let titleMap =  {};
  Object.keys(groupedQuestions).map((step) => { groupedQuestions[step].map(question => titleMap[Object.keys(question)[0]] = Object.values(question)[0]) });

  const markedupQuestions =
  <Select native defaultValue="" value={props.selected} id="grouped-native-select" onChange={handleChange}>
    {
      Object.keys(groupedQuestions).map(step => (
        <optgroup label={step}>
        {
          groupedQuestions[step].map(question => <option value={Object.keys(question)[0]}>{Object.values(question)[0]}</option>)
        }
        </optgroup>
      ))
    }
  </Select>;

return (
      <FormControl>
        {markedupQuestions}
      </FormControl>
);
}

export default ColumnSelect;
