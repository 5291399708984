import React from 'react';

import ReactDOM from 'react-dom';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, TextField, Select, MenuItem, FormControl } from '@material-ui/core';



import { Editor, EditorState, RichUtils, getDefaultKeyBinding, CompositeDecorator, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { convertToHTML } from 'draft-convert';

import './DraftEditorStyles.css';

import Conn_Admin from 'components/admin/Conn_Admin';
import Config from '../../Config.js';

import './dashboardStyles.css';

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  LINK: {
    // color: 'red'
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

const StyleButton = (props) => {
  const _onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style, e);
  };

  let className = 'RichEditor-styleButton';
  if (props.active) {
    className += ' RichEditor-activeButton';
  }

  return (
    <span className={className} onMouseDown={_onToggle}>
      {props.label}
    </span>
  );
}

const BLOCK_TYPES = [
  { label: 'Heading', style: 'header-one' },
  { label: 'Sub Heading', style: 'header-two' },
  // { label: 'H3', style: 'header-three' },
  // { label: 'H4', style: 'header-four' },
  // { label: 'H5', style: 'header-five' },
  // { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: <FormatBoldIcon />, style: 'BOLD' },
  { label: <FormatItalicIcon />, style: 'ITALIC' },
  { label: <FormatUnderlinedIcon />, style: 'UNDERLINE' },
  // { label: 'Monospace', style: 'CODE' },
  // { label: <InsertLinkIcon />, style: 'LINK' }
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
        )}
      {/* <StyleButton
        key="link"
        active={false}
        label={<InsertLinkIcon />}
        onToggle={(style, e) => { props._promptForLink(e)}}
        style='LINK'
      /> */}
      <span onMouseDown={(e) => props._promptForLink(e,'file')}>
        <AttachFileIcon />
      </span>
      <span onMouseDown={(e) => props._promptForLink(e, 'url')}>
        <InsertLinkIcon />
      </span>
    </div>
  );
};






function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        (
          contentState.getEntity(entityKey).getType() === 'LINK' ||
          contentState.getEntity(entityKey).getType() === 'LINK_URL'
        )
      );
    },
    callback
  );
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  root: {
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};




const URLDialogue = (props) => {

  const [link, setLink] = React.useState('');
  const [files, setFiles] = React.useState([]);

  const { getFileList } = Conn_Admin();

  const { showURLInput, setShowURLInput, showURLInputType, editorRef, _confirmLink } = props;

  const loadDashboardFiles = () => {
    getFileList((res) => {
      setFiles(res);
    });
  }

  React.useMemo(() => {
    loadDashboardFiles();
  }, [props.refresh]);

  const _handleChange = (e) => {
    setLink(e.target.value);
  }

  const _handleClose = () => {
    setShowURLInput(false);
    setTimeout((ref) => ref.current.focus(), 0, editorRef);
  }

  let input = (showURLInputType === 'file') ? (
    <Select
      labelId="demo-customized-select-label"
      id="demo-customized-select"
      value={link}

      styles={{ minWidth: '100%' }}
      onChange={_handleChange}
    >
      {files.map((file, index) => <MenuItem key={'file' + index} value={file.id}>{file.name}</MenuItem>)}
    </Select>
  ) : (
    <input type="text" name="url" value={link} onChange={(e) => { setLink(e.target.value) }} />
  );

  return (
    <Dialog open={showURLInput} onClose={_handleClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5" color="primary">
          Link to {(showURLInputType === 'file') ? 'File' : 'Website'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* <FormControl>
            <TextField 
              id="display_text"
              label="Display Text"
              variant="outlined"
              value={displayText}
              onChange={(e) => { setDisplayText(e.target.value) }}
            />
          </FormControl> */}
        <FormControl fullWidth>
          {input}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => {
          _confirmLink(e, link, showURLInputType, _handleClose);
        }} color="primary">
          Save
        </Button>
        <Button onClick={_handleClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}



const DraftEditor = (props) => {
  
  
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    }
  ]);
  

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(decorator));
  const [showURLInput, setShowURLInput] = React.useState(false);
  const [showURLInputType, setShowURLInputType] = React.useState('');
  const [urlValue, setURLValue] = React.useState('');
  const editorRef = React.useRef(null);
  const { saveDashboard, onServer } = Conn_Admin();

  React.useMemo(() => {
    onServer('getLatestDashbaord', {}, (res)=> {
      _handleOnChange(EditorState.createWithContent(convertFromRaw(JSON.parse(res)), decorator));
      setTimeout((ref) => ref.current.focus(), 0, editorRef);
    });
  }, [])




  /* update the editor state */
  const _handleOnChange = (editorState) => {
    setEditorState(editorState);
  }

  /* used for ctrl +b etc */
  const _handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      _handleOnChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const _mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        _handleOnChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }


  const _toggleBlockType = (blockType) => {
    _handleOnChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  const _toggleInlineStyle = (inlineStyle) => {
    _handleOnChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  const _handleSave = () => {
    const contentState = editorState.getCurrentContent();
    saveDashboard(JSON.stringify(convertToRaw(contentState)), theHtml(), (e) => { console.log('return from save', e) });
  }



/* link handling */

 const _promptForLink = (e, type) => {
    e.preventDefault();

    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setShowURLInput(true);
      setURLValue(url);
      setShowURLInputType(type);
      // this.setState({
      //   showURLInput: true,
      //   urlValue: url,
      // }, () => {
      //   // setTimeout(() => this.refs.url.focus(), 0);
      // });
    }
  }

  const _confirmLink = (e, link, type, callback=()=>{}) => {
    e.preventDefault();

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      (type === 'file') ? 'LINK' : 'LINK_URL',
      'MUTABLE',
      { url: link }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    _handleOnChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    callback();
  }

  const _onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      _confirmLink(e);
    }
  }

  const _removeLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      _handleOnChange(RichUtils.toggleLink(editorState, selection, null));
    }
  }

  const _handleOnURLChange = (e) => {
    setURLValue(e.target.value);
  }



  





  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }





  const theHtml = () => { return convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={Config.server.backend + 'dashboardFile.php?id=' + entity.data.url} target="_blank">{originalText}</a>;
        } else if (entity.type === 'LINK_URL') {
          return <a href={entity.data.url} target="_blank">{originalText}</a>;
        }
        return originalText;
      }
    })(editorState.getCurrentContent())
  };
  


  return (<>
    <URLDialogue editorState={editorState} showURLInput={showURLInput}
      setShowURLInput={setShowURLInput}
      type={showURLInputType}
      editorRef={editorRef}
      _confirmLink={_confirmLink}
      showURLInputType={showURLInputType}
      refresh={props.refresh} />
      

    <div className="RichEditor-root dashboardStyles">
      <BlockStyleControls
        editorState={editorState}
        onToggle={_toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={_toggleInlineStyle}
        _promptForLink={_promptForLink}
      />
      <div className={className} >{/*onClick={this.focus}*/}
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={_handleKeyCommand}
          keyBindingFn={_mapKeyToEditorCommand}
          onChange={_handleOnChange}
          placeholder="Tell a story..."
          spellCheck={true}
          ref={editorRef}
        />
      </div>
    </div>
    <Button color="secondary" variant="contained" onClick={_handleSave}>Save Changes</Button>

    {/* <div dangerouslySetInnerHTML={{ __html: theHtml() }}></div> */}
  </>
  );
}

export default DraftEditor;