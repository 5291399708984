import React from 'react';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontSize: "16px",
    marginBottom: '2em',
    lineHeight: '1.1',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478',
      fontSize: '20px'
    }
  },
  alternate: {
    fontSize: '20px',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478'
    },
    lineHeight: '1.1',
  },
  caption: {
    fontSize: '12px'
  }
});


const Locked = (props) => {

  const classes = useStyles();

  return (
    <Grid item>
      <Typography variant="body2"  className={classes.root} gutterBottom>
         This application has been submitted and is ready to be assessed.
      </Typography>
      <Typography variant="body2"  className={classes.root} gutterBottom>
         You are not able to login and make changes to your application at this time.
      </Typography>
    </Grid>
  );
}

export default Locked;
