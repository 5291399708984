import React from 'react';

import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


import { GeneratePDF, PDFGeneratorMarkup } from "components/application_forms/PDFGeneration";
import { useFormControllerContext, FormControllerProvider } from "components/PM_FormController";
import xmlData from 'config/StallholderApplication';
import { UserFeedbackProvider } from "../UserFeedback";



const useStyles = makeStyles({
  root: {
    fontSize: "16px",
    marginBottom: '2em',
    lineHeight: '1.1',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478',
      fontSize: '20px'
    }
  },
  alternate: {
    fontSize: '20px',
    '& strong': {
      textTransform: 'uppercase',
      color: '#793478'
    },
    lineHeight: '1.1',
  },
  caption: {
    fontSize: '12px'
  }
});

const PDF_Button = (props) => {
  const FormController = useFormControllerContext();
  return <Button variant="contained" fullWidth color="secondary" onClick={() => { GeneratePDF(FormController.GetName()); }}>Download a PDF Copy of your Application</Button>
}

const Locked = (props) => {

  const classes = useStyles();

  return (<>
    <FormControllerProvider xmlData={xmlData}>
      <Grid item>
        <Typography variant="body2" className={classes.root} gutterBottom>
          This application has been submitted and is ready to be assessed.
        </Typography>
        <Typography variant="body2" className={classes.root} gutterBottom>
          You are not able to login and make changes to your application at this time.
        </Typography>
        <Typography variant="body2" className={classes.root} gutterBottom>
          <PDF_Button />
        </Typography>
      </Grid>
      <UserFeedbackProvider>
        <PDFGeneratorMarkup id={props.otp} year="2025" />
      </UserFeedbackProvider>
    </FormControllerProvider>
    </>
  );
}

export default Locked;
