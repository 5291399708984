import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { TextField, Grid, Chip, Button, Typography, Box, Select } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import Conn_Admin from 'components/admin/Conn_Admin';

import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  heading: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 }
}));

const StatusChanger = (props) => {
  const { users, setUsers, year } = props;
  const [status, setStatus] = React.useState(0);
  const Admin_Helpers = useAdminHelpersContext();
  const {changeStatusByUser} = Conn_Admin();
    const handleChange = (e) => {
      setStatus(e.target.value);
  }
  const handleDelete = (chipToDelete) => () => {
    setUsers((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };


  const handleApply =() => {

    changeStatusByUser(users[0].id, status, year, props.callback);
  }
  // <Grid item xs={2}>
  //   Notify Users:
  // </Grid>
  // <Grid item xs={10}>
  // <FormControlLabel disabled control={<Checkbox checked name="notify" />} label="" />
  //
  // </Grid>
  const   options =  Object.keys(Admin_Helpers.getStatusList()).map(id => {return {id:id, name:Admin_Helpers.getStatusList()[id] }});
  return (<Grid container spacing={2}>
    <Grid item xs={4}>
      <Typography>User:</Typography>
    </Grid>
    <Grid item xs={8}>
      {users.map((data) => <Typography>{data.label}</Typography>) }
    </Grid>
    <Grid item xs={4}>
      <Typography>New Status:</Typography>
    </Grid>
    <Grid item xs={8}>
    <Select native defaultValue="" value={status} onChange={handleChange}>
      <option></option>
      {
        options.map(opt => <option value={opt.id}>{opt.name}</option>)
      }
    </Select>
    </Grid>

    <Grid item xs={12}>
    <Button onClick={handleApply} color="primary" variant="contained">
      Apply Change
    </Button>
    </Grid>
  </Grid>);
}


const StatusChangerDialog = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [year, setYear] = React.useState(2025);
  const [dispatcher, setDispatcher] = React.useState({});
  const callback = (data) => {
    dispatcher.dispatch({
      type:'update',
      payload: {
        userid: users[0].id,
        field: '.system.status',
        newValue: data.stats
      }
    });
    setDialogOpen(false)
  };// (props && 'callback' in props) ? props.callback : () => {};
  const classes = useStyles();
  return {
    StatusChangerMarkup: (<>

  <Dialog
  open={dialogOpen}
  TransitionComponent={Transition}
  onClose={() => setDialogOpen(false)}
  maxWidth={'lg'}
  aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" className={classes.heading} >
      Change Application Status
    </DialogTitle>
    <DialogContent>
      <StatusChanger users={users} setUsers={setUsers} year={year} callback={callback} />
    </DialogContent>
    <DialogActions>

      <Button onClick={() => setDialogOpen(false)} color="secondary" variant="contained">
        cancel
      </Button>
    </DialogActions>
  </Dialog></>),
  handleOpenStatusChanger: (users, year, dispatch) => { setYear(year); setUsers(users); setDispatcher({dispatch:dispatch}); setDialogOpen(true); }
}
}

export { StatusChangerDialog };
