import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import { useFormControllerContext } from "components/PM_FormController";

import { ReactComponent as IconDF } from 'icons/food_badges_df.svg';
import { ReactComponent as IconGF } from 'icons/food_badges_gf.svg';
import { ReactComponent as IconNF } from 'icons/food_badges_nf.svg';
import { ReactComponent as IconV } from 'icons/food_badges_v.svg';
import { ReactComponent as IconVG } from 'icons/food_badges_vg.svg';



const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: '500',
    background: "#ee680f",
    fontFamily: 'Rambla',
    '&:not(.embed)': {
      borderRadius: '25px',
      padding: '50px 70px 40px 60px',
    },
    '& h4': {
      fontFamily: 'Rambla',
    },
    '& h4, & h3': {
      color: '#fff',
      fontSize: '2em',
      marginBottom: '1em'
    },
    '& h3': {
      color: '#000',
      marginLeft: '120px',
      textTransform: 'uppercase',
      fontFamily: 'Rambla',
    },
    '& h4': {
      float: 'left'
    },
    '& h5': {
      color: '#fff',
      fontSize: '1em',
      clear: 'both',
      '&::after': {
        content: '',
        display: 'none'
      },
    },
    '& dl': {
      marginBottom: '2em'
    },
    '& dt': {
      fontWeight: 'bold',
      fontSize: '1.0em',
      textTransform: 'uppercase',

    },
    '& dd': {
      marginRight: '120px',
      marginBottom: '1em',
      marginLeft: '0',
      lineHeight: '1.1em',
      fontFamily: 'Rambla'
    },
    '& .features':  {
      float: 'right',
      display: 'flex',
      marginRight: 0,
      marginBottom: 0,
      width: '120px',
      overflow: 'hidden',
      justifyContent: 'flex-end'
    },
    '& svg': {
      float: 'right',
      marginLeft: '5px',
      marginBottom: '5px'
    }
  }
}));



const GetName =(props) => {
  const FormController = useFormControllerContext();
  return (<>
    {FormController.state.values['.stall.stall_name']}
    {(FormController.state.values['.stall.stall_name2'] !== undefined
      & FormController.state.values['.stall.co_branded'] === "yes2")
        ? ' & '+FormController.state.values['.stall.stall_name2'] : ''}
  </>);
};

const MenuPreview = (props) => {
  const FormController = useFormControllerContext();
  const classes = useStyles();

  let foodItems = [];
  let drinkItems = [];

  //console.log(FormController.Flow.children[5].children[0].children);

  FormController.Flow.children[5].children[0].children.forEach(child => {
    const type = FormController.state.values[FormController.getKey(FormController.findChildById('item_type', child))];
    if (type === 'Dish') {
      foodItems.push(child);
    } else {
      drinkItems.push(child);
    }
  });


  const FoodList = foodItems.map(item2 => {
    let item = FormController.findChildById('food_details', item2);
    //FormController.state.values[FormController.getKey(
      // const featureID = FormController.getKey(FormController.findChildById('features', item));
      const featureID = FormController.getKey(item)+'.food_details.features';
      let icons = [];
    if (FormController.state.values[featureID+'.gf']) { icons.push(<IconGF width="19" height="19" />); }
    if (FormController.state.values[featureID+'.vegetarian']) { icons.push(<IconV width="19" height="19"  />); }
    if (FormController.state.values[featureID+'.vegan']) { icons.push(<IconVG width="19" height="19"  />); }
    if (FormController.state.values[featureID+'.dairyfree']) { icons.push(<IconDF width="19" height="19"  />); }
    if (FormController.state.values[featureID+'.nut']) { icons.push(<IconNF width="19" height="19"  />); }
    return <>
      <dd className="features">{ icons }</dd>
      <dt>{FormController.state.values[FormController.getKey(FormController.findChildById('item_title', item2))]}</dt>
      <dd>{FormController.state.values[FormController.getKey(item)+'.food_details.description']}</dd>

      </>;
  });
  const BeveragesList = drinkItems.map(item2 => {
    let item = FormController.findChildById('bev_details', item2);
    return <>
      <dt>{FormController.state.values[FormController.getKey(FormController.findChildById('item_title', item2))]}</dt>
      <dd>{FormController.state.values[FormController.getKey(item)+'.bev_details.description']}</dd>
      </>;
  });
  return <div className={((props.embed) ? 'embed ' : ' ')+classes.root}>
      <Typography variant="h4">{FormController.state.values['.extra.site_number']}</Typography><Typography variant="h3"><GetName /></Typography>
      {foodItems.length > 0 && (<>
        <Typography variant="h5">Food</Typography>
        <dl>
          {FoodList}
        </dl>
        </>
      )}
      {drinkItems.length > 0 && (<>
        <Typography variant="h5">Beverages</Typography>
        <dl>
          {BeveragesList}
        </dl>
        </>
      )}
  </div>;

}

export default MenuPreview;
