import React from 'react';

import { Box, AppBar, Divider, Toolbar, Typography, IconButton, Badge, Drawer, Grid, Paper } from '@material-ui/core';


import Conn_Admin from 'components/admin/Conn_Admin';
import { useFormControllerContext } from "components/PM_FormController";


import DraftEditor from 'components/admin/DraftEditor';
import FileManager from 'components/admin/FileManager';



const EditDashboard = (props) => {

  const [filesUpdated, setFilesUpdated] = React.useState(1);

  return <>
    <Grid container>
      <Grid item xs={8}>
        <DraftEditor refresh={filesUpdated} />
      </Grid>
      <Grid item xs={4}>
        <Box ml={2}>
          <Paper>
            <Box p={3}>
              <Typography variant="h6">File Manager</Typography>
              <FileManager filesChanged={setFilesUpdated} />
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  </>
}

export default EditDashboard;
