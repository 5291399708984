import React from 'react';

import { Grid } from '@material-ui/core';



import { useFormControllerContext } from "components/PM_FormController";
import { PM_Key } from "../PM_Conditionals";

function InputWrapper(props) {
  const FormController = useFormControllerContext();
  const { flags, setFlags } = props;
  return <props.FieldContainer key={FormController.getKey(props.q)} question={props.q} viewMode={props.viewMode} adminView={props.adminView} flags={flags} setFlags={setFlags}/>
};

function SiteVisualiserLayout(props) {
  const FormController = useFormControllerContext();

  return (
      <Grid container spacing={2}>

      <Grid item xs={6}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper q={FormController.getItemByKey('.site.type')}  {...props} />
          </Grid>
          <Grid item xs={12}>
            <InputWrapper  q={FormController.getItemByKey('.site.frontage')}  {...props} />
          </Grid>
          <Grid item xs={12}>
            <InputWrapper  q={FormController.getItemByKey('.site.depth')}  {...props} />
          </Grid>
          <Grid item xs={12}>
            <InputWrapper  q={FormController.getItemByKey('.site.mobile_cooler')}  {...props} />
          </Grid>
          <Grid item xs={12}>
            <InputWrapper  q={FormController.getItemByKey('.site.extra_frontage')}  {...props} />
          </Grid>
          {FormController.shouldDisplay(FormController.getItemByKey('.site.extra_frontage_reason')) && (
          <Grid item xs={12}>
            <InputWrapper  q={FormController.getItemByKey('.site.extra_frontage_reason')}  {...props} />
          </Grid>)}

        </Grid>
      </Grid>
      <Grid item xs={6}>
        <InputWrapper  q={FormController.getItemByKey('.site.visualiser')}  {...props} />
      </Grid>

      </Grid>
  );

}

export default SiteVisualiserLayout;
