import React from 'react';

import { Select, FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';

import { useFormControllerContext } from "components/PM_FormController";


export default function Input_Select(props) {

  const FormController = useFormControllerContext();
  const question = props.question;
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const fieldProps = FormController.getFieldProps(question);
  delete fieldProps.helperText;
  const error = fieldProps.error;
  let canEdit = ("canEdit" in props && props.canEdit);
  fieldProps.error = (fieldProps.error && true);
    return (
      <FormControl variant="outlined" fullWidth>
         <InputLabel>{question.name}</InputLabel>
         <Select {...fieldProps}  disabled={!canEdit}  MenuProps={{
     disableScrollLock: true
   }}>
           {
             question.options.filter(opt => FormController.shouldDisplay(opt)).map((opt) => <MenuItem value={opt.value || opt.name} key={opt.id || opt.name}>{opt.name}</MenuItem> )
           }
         </Select>
         <FormHelperText error={(error && true)}>{error}</FormHelperText>
         <FormHelperText>{question.help_text}</FormHelperText>
       </FormControl>
       )
}
