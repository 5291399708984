import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Typography} from '@material-ui/core';
import { Grid, Button, IconButton,  FormHelperText,  Dialog, DialogTitle, DialogContent, DialogContentText  } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { useFormControllerContext } from "components/PM_FormController";


const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    height: 240,
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",

    backgroundBlendMode: "multiply",
    padding:  theme.spacing(2)+"px "+theme.spacing(3)+"px",
    color: "#fff"
  },

  icons: {
    alignSelf: "flex-end",
    "& > *": {
      marginLeft: theme.spacing(2)
    }
  },
  addPhoto: {
    width: '100%',
    height: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fullPhoto: {
    width: '100%',
    height: '100%'
  },
  heading: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 },
 yes: {
   background: '#793478',
   color: '#fff',
   borderRadius: '15px'
 },
 no: {
   background: '#333',
   color: '#fff',
   borderRadius: '15px'
 },
 bodyCopy: {
   '& p': {
      margin: '1em 0'
   }
 }
}));


export default function Input_Info(props) {
  const [fullPhoto, setFullPhoto] = React.useState(-1);

  const classes = useStyles();
  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;

  const FullImage= (props) => (
    <Dialog
      maxWidth='xl'
      open={fullPhoto === props.num}
      onClose={() => {setFullPhoto(-1)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.heading} disableTypography={true}>
      <Grid direction="row" container justify="space-between" alignItems="center" spacing={0}>
        <Grid item><Typography variant="h6">Example Image</Typography></Grid>
        <Grid item>
          <IconButton edge="end" size="small" color="inherit" onClick={() => {setFullPhoto(-1)}} aria-label="close">
            <CloseIcon fontSize="small"  />
          </IconButton>
        </Grid>
      </Grid>

      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <img src={props.src} className={classes.fullPhoto} width="1000" />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );



  let bodyCopy = <Typography>{question.name}</Typography>;

  // if (question.id==='lanyards') {
  //   const frontage = parseFloat(FormController.state.values['.site.frontage']);
  //   const depth = parseFloat(FormController.state.values['.site.depth']);
  //   const size = frontage * depth;
  //   bodyCopy = question.name;
  //   const lanyards = (size == 36) ? 6 : (size == 18) ? 4 : 2;
  //   bodyCopy = bodyCopy.replace('%%', lanyards);
  // }

  if (question.id === 'power_info2') {
    bodyCopy = <Typography>
    Examples of typical wattages (single phase) are: <br />

    Bain Maries: 2Amps<br />
     Coffee Machine: 10Amps<br />
     Fridge/Freezer: 1Amp <br />
     POS/register: 150 Watts<br />
     Deep Fryer: 10 Amps<br />
     Microwave: 10Amps<br />
     Large Urn: 1000-1500Watts
    </Typography>
  }

  if (question.id === 'examples') {
    bodyCopy = ['/example one_ashgrove.jpg', '/example two_ghostrock.jpg', '/example four_delamere.jpg'].map((src, ii) => {
      return (<>
        <FullImage num={ii} src={src} />
        <Button onClick={() => setFullPhoto(ii)}>Example {ii+1}</Button>
      </>);
    });
    // &lt;a href="/example one_ashgrove.jpg" target="_blank" &gt;EXAMPLE 1 &lt;/a&gt;,
    // &lt;a href="/example two_ghostrock.jpg" target="_blank" &gt;EXAMPLE 2 &lt;/a&gt;,
    // &lt;a href="/example three_cheeselouise.jpg" target="_blank" &gt;EXAMPLE 3 &lt;/a&gt;,
    // &lt;a href="/example four_delamere.jpg" target="_blank" &gt;EXAMPLE 4 &lt;/a&gt;

  }

  if (question.id === 'layoutinfo') {
    bodyCopy = <>
      <Typography>You will also need warm running water from a single spout for handwashing separate to dishwashing - Tip:  Have 2 urns.  1 x for hand washing set on warm temperature and the other for dishwashing.<br />
    We need the complete dimensions of footprint including tow bar.<br />
    We need an accurate scaled drawing of your site.<br />
    Please include in red the exact locations of gas cylinders on your site plan<br />
        <a href="/Storing-and-using-LPG-at-public-events-V3.1.pdf" target="_blank">Guidelines for Storage of LPG at Public Events</a></Typography>

    <FullImage num={1} src='/Picture1.png' />
      <Button onClick={() => setFullPhoto(1)}><Typography>Layout Example 1</Typography></Button>
    <FullImage num={2} src='/site map plan_beverage.png' />
      <Button onClick={() => setFullPhoto(2)}><Typography>Layout Example 2</Typography></Button>

    </>;
  }

  if (question.id === 'info_guide') {
    bodyCopy = <Typography>For more information about applying to be a 2024 Festivale stallholder please view the <a href="/Stallholder information guide 2025_ FINAL.pdf" target="_blank"><strong>Festivale Stallholder Information Guide</strong></a>.</Typography>
  }

  if (question.id === "chairman") {
    bodyCopy = <>
      <Typography>WELCOME<br />TO FESTIVALE’S STALLHOLDER APPLICATION PROCESS FOR 2025</Typography>
      <Typography>January 31, February 1 & 2 2025 will see Festivale back for another incredible year.  After an absolutely bumper year in 2024, we are excited to be planning our next event and look forward to working with you to make Festivale 2025 even more successful than the last. Festivale really is Tasmania’s premier food and beverage event and is designed to showcase the very best of our food, beverages and entertainment.  </Typography>
      <Typography>Events are certainly back to full strength and the committee are geared up for another trail-blazing event when it comes to setting the standard of excellence. </Typography>
      <Typography>To support the incredible work done by all of you – our stallholders, our entertainment sub-committee are working on another stellar lineup of music and comedy to keep our patrons entertained whilst they enjoy your food and beverage offerings.  Ticket sales will go on sale on the 6th September this year and will be supported by a major marketing campaign – we aim to be a fully sold out event prior to the gates opening on the 31st of January.   </Typography>
      <Typography>Festivale 2025 welcomes applications from stallholders with distinctive concepts, innovative menu options, striking stall designs and presentation.  We’re seeking stallholders who prioritise refining a selection of key menu offerings, over delivering complex menus that may not fully showcase your skills. We suggest a maximum of 4 main offerings which can be varied to allow for tastings and share platters.  We also encourage “Taste Plates” of your offerings – this might be food or beverage offerings – the choice is yours.  </Typography>
      <Typography>Applicants who feature direct relationships with Tasmanian producers will be considered favourably. Due to space limitations within the park, we will be looking for applications from stallholders who showcase prime Tasmanian produce and products. Festivale patrons should be able to experience the specialities of Tasmanian produce with a focus on quality when purchasing at the event. There is an expectation that stallholders will provide a variety of speciality premium products and or dishes of suitable and consistent portion sizes that represent great value for money and are presented at a high standard for patrons to purchase. Diversity and price are the most influential factors for our patrons. Festivale requires all food stalls to provide at least one taste plate or entrée size option on their menu priced at no more than $10 (inc GST). </Typography>
      <Typography>Some important points for you to note as you commence your application:<br />
      -	Be sure to save your content as you go through the application process <br />
-	You can start your application and come back to finish it later <br />
-	Please do not allow multiple people to log into your application at the same time – only one person should be logged in at any point in time<br />
-	Each step of the process is shown at the top of each page so you can track your progress.<br />
-	Your menu must be fully input however we do allow changes to this as we understand supply of ingredients can be problematic at times.  Changes to menu items need to be sent through to the Festivale office. <br />
-	Your overview of your offerings will be uploaded to our website and the Festivale App – please ensure this is factual, spelling and grammar are current and it gives people an overview of what your menu will be.  <br />
-	Please ensure you include photos and plans as requested – failure to do so may result in your application not being assessed correctly.   Please note your site plan can be as simple as a hand drawn diagram (with measurements included) as we need to confirm your entire footprint can fit on the site you have applied for.  You will need to include your handwashing facilities within the confines of your site – these cannot be placed outside your marquee/van.  <br />
-	Complete your application with the assumption that we know nothing  anything about your business or offerings. Each year a new panel assess applications and the initial selections are made purely on the information contained in your application. In 2024 we had a wait list for sites due to the number of applications received so please make sure you will give us as much details as possible to ensure your best chance of securing a site. <br />
-	Applications must be completed, and submitted before 5pm on the 26 July.</Typography>
      <Typography>If you have any queries about applying for your Festivale site please contact our office on 03 6334 9990 or <a href="mailto:eventmanager@festivale.com.au">eventmanager@festivale.com.au</a></Typography>
      <Typography>We look forward to receiving your application and you joining us in 2025.</Typography>
      

      {/* <Typography>Kind regards<br />
        David Dunn<br />
        Chairman</Typography> */}
    </>;
  }

  return (<>
    <div className={classes.bodyCopy}>{bodyCopy}</div>
    <FormHelperText dangerouslySetInnerHTML={{ __html: question.help_text }}></FormHelperText>
  </>
  );
}

