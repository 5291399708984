import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import ApplicationPage from './components/pages/ApplicationPage';
import AdminPage from './components/pages/AdminPage';
import SigningPage from './components/pages/SigningPage';
import SummaryPage from './components/pages/SummaryPage';
import DashboardPage from './components/pages/DashboardPage';
import MenuPage from './components/pages/MenuPage';

import styles from 'App.css'; // this loads the fonts

const App = () => (<div />);


const outerTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "#793478",
    },
    secondary: {
      main: "#ee680f",
    },
    error: {
      main: "#ee680f"
    },
    text: {
      secondary: "#000",
      primary: "#000"
    }
  },
  shape: {
    borderRadius: "15px"
  },
  typography: {
    h5: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
      display: "flex",
      alignItems: "center",
      "&::after": {
        content: '""',
        borderTop: "2px solid #ee680f",
        margin: "0 0 0 10px",
        flex: "1 0 20px"
      }
    },
    h6: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
    },
    body1: {
      '& a': {
        color: '#ee680f'
      }
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "30px"
      }
    }
  }

});



const routing = (
  <ThemeProvider theme={outerTheme}>
    <Router>
        <Route path="/" component={App} />
        <Route path="/auth" component={SigningPage} />
        <Route path="/applicationPage" component={ApplicationPage} exact />
        <Route path="/admin" component={AdminPage} />
        <Route path="/summary" component={SummaryPage} />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/menu/:stall" component={MenuPage} />
    </Router>
  </ThemeProvider>
);



ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
