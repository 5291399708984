import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Config from '../../Config.js';

const instance = axios.create({
  withCredentials: true,
  baseURL: Config.server.backend,
  crossDomain: true
})

// export const SigningContext = createContext({});
//
// export const SigningProvider = props => {
//   const context = Signing(props.config);
//   const memoizedContext = useMemo(() => context, [context]);
//   return (
//     <SigningProvider.Provider value={memoizedContext}>
//       {props.children}
//     </SigningProvider.Provider>
//   );
// };

function Conn_Signing(dispatch) {
  const history = useHistory();

  function doLogout() {
    instance.post( "logout.php"
    ).then((response) => {
      history.push('/auth');
    });
  }




  function doUpdatePassword(inValues) {
    var params = new URLSearchParams();
    params.append('password', inValues.password);
    params.append('task', 'changePassword');
    axios.post( Config.server.backend + "changePassword.php",
       params, {
          withCredentials: true,
          crossDomain: true,
        }
      ).then((response) => {
        if ('updated' in response.data && response.data.auth) {

        }
        if ('error' in response.data) {
          dispatch({
             type: 'serverValidate',
             payload: response.data.error
           });
        }

      }).catch(error => {

      });


  }


  function doPasswordReset(inValues, otp) {

    var params = new URLSearchParams();
    params.append('password', inValues['.password']);
    params.append('otpt', otp);
    params.append('task', 'withOTPT');
    axios.post( Config.server.backend + "changePassword.php",
       params, {
          withCredentials: true,
          crossDomain: true,
        }
      ).then((response) => {
        if ('updated' in response.data && response.data.updated) {
          dispatch({
             type: 'change',
             payload: {
               passwordReset: true
             }
           });
        }
        if ('error' in response.data) {
          dispatch({
             type: 'serverValidate',
             payload: response.data.error
           });
        }
        console.log(response);
      }).catch(error => {
          // console.log(error.response);
      });


  }


function doRegister(inValues) {
  // if (!isFormValid) return;


  // return;

  var params = new URLSearchParams();
  params.append('email', inValues['.email']);
  params.append('username', inValues['.username']);
  params.append('password', inValues['.password']);
  params.append('name_first', inValues['.name_first']);
  params.append('name_last', inValues['.name_last']);

  axios.post( Config.server.backend + "register.php",
     params, {
        withCredentials: true,
        crossDomain: true,
      }
    ).then((response) => {
      if ('auth' in response.data && response.data.auth) {
        history.push('/applicationPage');
      }
      if ('error' in response.data) {
        dispatch({
           type: 'serverValidate',
           payload: response.data.error
         });
      }
    });
  // const errors2 = {email: "wrong message here"};
  // dispatch({
  //   type: 'serverValidate',
  //   payload: errors2
  // });
    // setErrors({...errors,email: "hi jimmy"});

    // return {
    //   values: inValues,
    //   serverErrors: errors2
    // }
}

function doForgotten(inValues) {
  var params = new URLSearchParams();
  params.append('email', inValues['.email']);
  axios.post( Config.server.backend + "forgotten.php",
     params, {
        withCredentials: false,
        crossDomain: true,
      }
    ).then((response) => {
      if ('resetCreated' in response.data && response.data.resetCreated) {
        dispatch({
           type: 'change',
           payload: {
             resetCreated: true
         }
       });
      }
      if ('error' in response.data) {
        dispatch({
           type: 'serverValidate',
           payload: response.data.error
         });
      }
    }).catch(error => {
        // console.log(error.response);
    });
}

 function doLogin(inValues){
    var params = new URLSearchParams();
    params.append('email', inValues['.email']);
    params.append('password', inValues['.password']);
    params.append('task', 'login');
    instance.post("signing.php",
       params
      ).then((response) => {
        if ('auth' in response.data) {
          if (response.data.auth > 50) {
            history.push('/admin');
          } else {
            history.push('/applicationPage');
          }
        }
        if ('error' in response.data) {
          dispatch({
             type: 'serverValidate',
             payload: response.data.error
           });
        }
      }).catch(error => {
          // console.log(error.response);
      });
    }

  return {
     doLogin, doRegister, doLogout, doForgotten, doUpdatePassword, doPasswordReset
  }
}

// function doLogin(inValues, dispatch, history){
//
//    var params = new URLSearchParams();
//    params.append('username', inValues.username);
//    params.append('password', inValues.password);
//    axios.post( Config.server.backend + "login.php",
//       params, {
//          withCredentials: true,
//          crossDomain: true,
//        }
//      ).then((response) => {
//        if ('auth' in response.data && response.data.auth) {
//          // dispatch({
//          //   type: 'change',
//          //   payload: { authenticated: true }
//          // });
//          history.push("/applicationPage");
//        }
//        if ('error' in response.data) {
//          dispatch({
//             type: 'serverValidate',
//             payload: response.data.error
//           });
//        }
//      });
//    }
// export { doLogin };
export default Conn_Signing;
