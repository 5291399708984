let Config = {
  "server" : {
    "app": "/",
    "backend": "/backend/", //"/"  http://james-laptop:8888
  }
}
//  
export default Config;


 