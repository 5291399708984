import React from 'react'

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles'

import { Paper, Grid, Box, Typography } from '@material-ui/core';
import { FormControl, InputLabel, OutlinedInput, Select, MenuItem  } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell  } from '@material-ui/core';

import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';
import Conn_Admin from 'components/admin/Conn_Admin';
import { useFormControllerContext } from "components/PM_FormController";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grow: {
    flexGrow: 1,
  },
  buttonDark: {
    background: '#333',
    color: '#fff'
  },
  link: {
    textDecoration: 'none'
  }
}));

const Input = (props) => {
  return <FormControl variant="outlined" size="small" fullWidth>
    <InputLabel>{props.name}</InputLabel>
    <OutlinedInput
      type="text"
      name={props.name}
      label={props.name}
      variant="outlined"
      value={props.value}
      onChange={e => props.setValue(e.target.value)}
      fullWidth
    />
  </FormControl>
}

const Input_Select = (props) => {
  return <FormControl variant="outlined" size="small" fullWidth>
    <InputLabel>{props.name}</InputLabel>
    <Select 
      name={props.name}
      label={props.name}
      value={props.value}
      // variant="outlined"
      onChange={e => props.setValue(e.target.value === 'clear' ? '' : e.target.value)}
    >
      <MenuItem key='clear' value='clear'>Clear selection</MenuItem>
      {props.options.map(opt => <MenuItem value={opt} key={opt}>{opt}</MenuItem>)}

    </Select>

  </FormControl>
}

//quickSearchApps

const QuickSearch = (props) => {

  const classes = useStyles();

  const [year, setYear] = React.useState(localStorage.getItem("year"));
  const [type, setType] = React.useState(localStorage.getItem("type"));
  const [status, setStatus] = React.useState(localStorage.getItem("status"));
  const [name, setName] = React.useState(localStorage.getItem("name"));
  const [applications, setApplications] = React.useState([]);

  const Admin_Helpers = useAdminHelpersContext();
  const FormController = useFormControllerContext();

  const { onServer } = Conn_Admin();

  const loadApps = (applications) => {
    let apps = applications.map(app => {
      // let state = ;
      return {
        user_id: app[0],
        id: app[1],
        status: Admin_Helpers.getStatusList()[app[2]],
        year: app[3],
        type: app[4]['.stall.site_type'],
        name: FormController.GetName({ values: app[4] })
      }
    });
    setApplications(apps);
  }


  const FilterApp = (app) => {

    if (year && app.year != year) {
      return false;
    }
    if (type && app.type != type) {
      return false
    }
    if (status && app.status != status) {
      return false
    }
    if (name && app.name.toLowerCase().lastIndexOf(name.toLowerCase()) == -1) {
      return false;
    }

    return true;
  }

  React.useMemo(() => {
    onServer('quickSearchApps', {}, loadApps);
  }, []);


  return <>
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex">

            <Typography className={classes.title} variant="h6">
              Quick Search
            </Typography>
           
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Input_Select name="Year" options={['2023', '2024', '2025']} value={year} setValue={v => { localStorage.setItem("year", v); setYear(v); }} />
        </Grid>
        <Grid item xs={2}>
          <Input_Select name="Type" options={['food', 'beverage', 'both', 'coffee van']} value={type} setValue={v => { localStorage.setItem("type", v); setType(v); }} />
        </Grid>
        <Grid item xs={2}>
          <Input_Select name="Status" options={Object.values(Admin_Helpers.getStatusList())} value={status} setValue={v => { localStorage.setItem("status", v); setStatus(v); }} />
        </Grid>

        <Grid item xs={6}>
          <Input name="Name" value={name} setValue={v => { localStorage.setItem("name", v); setName(v); }} />
        </Grid>
      </Grid>

      

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={true ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <TableHead
            classes={classes}
            numSelected={0}
            // order={order}
            // orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            // onRequestSort={handleRequestSort}
            // rowCount={1} 
            // {state.filter(passesFilters).length}
            // doEdit={editColumns}
            // headCells={[{id:'one', label:'fishing'}]}
            // addEmptyHeadCell={addEmptyHeadCell}
            // handleEditColumns={handleEditColumns}
            // headerCellsDispatch={headerCellsDispatch}
            // handleSave={handleSaveView}
          >
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {stableSort(state, getComparator(order, orderBy))

              .filter(passesFilters)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (<Row headerCells={headerCells} row={row} id={row['.system.id']} match={props.match} dispatch={dispatch} index={index} key={index} />);
              })} */}
              {/* */}
            
            {applications.filter(FilterApp).map(app => <TableRow component={Link} to={`${props.match.url}/view/${app.user_id}/${app.year}`} hover={true} className={classes.link} >
              <TableCell>{app.year}</TableCell>
              <TableCell>{app.type}</TableCell>
              <TableCell>{app.status}</TableCell>
              <TableCell>{app.name}</TableCell>
              
            </TableRow>) }

            {applications.filter(FilterApp).length == 0 && < TableRow ><TableCell colSpan={4} align='center'>No Matching Applications Found</TableCell></TableRow>}

            
          </TableBody>
        </Table>
      </TableContainer>


    </Paper>

 
  </>
}

export default QuickSearch;