import React from 'react';

import { useFormControllerContext } from "components/PM_FormController";
import { Box, TextField, FormControl,IconButton, FormLabel, FormHelperText, Grid, Typography,Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import FlagIcon from '@material-ui/icons/Flag';

import SiteVisualiserLayout from './SiteVisualiserLayout';
import GroupRepeater from './GroupRepeater';
import MenuRepeater from './MenuRepeater';
import EstimateLayout from './EstimateLayout';
import ChecklistLayout from './ChecklistLayout';

import ApplicationHelpers from 'components/application_forms/ApplicationHelpers';
import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';

import Conn_Application from 'components/application_forms/Conn_Application';


import * as Input from './inputs';

function DrawItem(props) {
  const FormController = useFormControllerContext();

  const { shouldDisplay } = FormController;
  const item = props.item;
  const { viewMode, adminView, hideFlags, PDFView } = props;


  // const FullImage= (props) => (
  //   <Dialog
  //     open={fullPhoto}
  //     onClose={() => {setFullPhoto(false)}}
  //     aria-labelledby="alert-dialog-title"
  //     aria-describedby="alert-dialog-description"
  //   >
  //     <DialogTitle id="alert-dialog-title" className={classes.heading}>View Photo</DialogTitle>
  //     <DialogContent>
  //       <DialogContentText id="alert-dialog-description">
  //         <img src={props.src} className={classes.fullPhoto} />
  //       </DialogContentText>
  //     </DialogContent>
  //   </Dialog>
  // );


  // const instance = ('instance' in props) ? props.instance : -1;
  const headingSize = ('adminView' in props) ? "h4" : "h5";
  if (!shouldDisplay(item)) {
    return <></>;
  }

  const pokeFlow = () => {
    props.updateFlow({...FormController.Flow});
  }

// dirty dirty hack
  if ('repeater' in item && FormController.getKey(item).includes("#0")) {
    let theKey = FormController.getKey(item).split("#")[0];
    // find highest matching count in values
    let theNumber = 0;
    Object.keys(FormController.state.values).forEach((valueKey) => {

      if (valueKey.includes(theKey)) {

        let newVal = parseInt(valueKey.split("#")[1].split(".")[0]);
        if (newVal > theNumber) {
          theNumber = newVal;
        }
      }
    });
    let count = item.parent.children.filter(child => child.id === item.id).length;

    for (let ii=count; ii<=theNumber; ii++) {
       FormController.addInstance(item);
    }
  }




  let display = <></>;

  // if ('repeater' in item && false) {
  //   const count = 0;
  //   // for (let ii=0; ii<=count; ii++) {
  //     display = [...Array(count+1)].map((x,ii) => <DrawItem item={item} FieldContainer={FieldContainer} updateFlow={props.updateFlow} />);
  //   // }
  //     display.push(<Button onClick={handleAdd}>add another item</Button>);
  // } else {
  if ('layout' in item) {
    const layouts = {
      SiteVisualiserLayout: SiteVisualiserLayout,
      MenuRepeaterLayout: MenuRepeater,
      EstimateLayout: EstimateLayout,
      ChecklistLayout: ChecklistLayout
    };
    const TagName = layouts[item.layout+'Layout'];
    display = <><TagName {...props} key={item.id} item={item} items={item.children} FieldContainer={FieldContainer} viewMode={viewMode} adminView={adminView} hideFlags={hideFlags} PDFView={PDFView} /></>;
  } else {

    display = <>{item.children && React.Children.toArray(item.children.map((child) => {
      switch (child.type) {
        case "group": 
          if (shouldDisplay(child)) { //
            return <DrawItem key={FormController.getKey(child)} item={child} updateFlow={props.updateFlow} viewMode={viewMode} adminView={adminView} hideFlags={hideFlags} PDFView={PDFView} />
          }
          return <></>;

        case "question":

          if (shouldDisplay(child)) { //
            return <FieldContainer key={FormController.getKey(child)} question={child} pokeFlow={pokeFlow} viewMode={viewMode} adminView={adminView} hideFlags={hideFlags} PDFView={PDFView} />
          }
          return <></>;
        }
      })
    )}</>;
  }
// }
  return(
    <Grid container item spacing={(props.viewMode) ? 0 : 2} xs={(item.displays.length > 0 && item.displays[0].cols) ? parseInt(item.displays[0].cols) : 12}>

    {item.name && (
     <Grid item xs={12} key={item.name}>
       <Typography variant={headingSize}>{item.name}</Typography>
     </Grid>
     )}
     {(item.description && !props.viewMode) && (<Grid item xs={12}><Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.description }}></Typography></Grid>)}

     {display}

   </Grid>);
}

function processName(question) {
  // const mand = (question.validators.length > 0 && question.validators.every(validator => validator.id === 'isRequired')) ? ' *' : '';
  return <>{injectLinks(question.name)}</>;
}

function launchPowerCalc() {
  // alert('calculate all the power');
}
window['launchPowerCalc'] = launchPowerCalc;

function injectLinks(target) {
  let links = target.match(/(.*?)>(.*?):(.*?)</);
  if (links === null) return target;
  const name = <>{ links[1] }<a onClick={() => window[links[2]]()}>{links[3]}</a></>; //
  return name;
}




function GetInput(props) {
  // const { config,getFieldProps } = useValidatorContext();
  const FormController = useFormControllerContext();

  const { getImageURL } = Conn_Application(FormController.state, FormController.dispatch, () => {});
  const question = props.question;
  // const config = FormController.config;
  const fieldProps = FormController.getFieldProps(question);
  const config = question.configs[0];
  // const item = config.fields[props.fieldKey.getConfigKey()];

  const Admin_Helpers = useAdminHelpersContext();
  const AppHelpers = ApplicationHelpers({ FormController });
 

  const handleChange = (question) => (e) => {
    // setFlags({...flags, [FormController.getKey(question)]:e.target.value});
  }




  const inputAlias = {
    'text': Input.Text,
    'check': Input.Checkbox,
    'date': Input.Date,
    'photo': Input.Photo,
    'file': Input.File,
    'radio': Input.Radio,
    'select': Input.Select,
    'select_grouped': Input.SelectGrouped,
    'slider': Input.Slider,
    'switch': Input.Switch,
    'select_bank': Input.BankList,
    'site_visualiser': Input.SiteVisualiser,
    'button': Input.Button,
    'info': Input.Info,
    'checklist': Input.Checklist
  }

  let canEdit = props.adminView;
  if (!canEdit) {
    canEdit = FormController.allowedToEdit();
  }


  if (props.PDFView) {
    if (['button'].includes(question.configs[0].type)) { return <></> }

    let options = <></>;
    if (question.configs[0].type === 'check') {
      options = question.options.filter(opt => FormController.getValueOf(opt) === true).map(opt => (<>
        <Typography>{opt.name}</Typography>
      </>));
    }

    let inner = <><Typography>{question.name}:</Typography>
      <Typography>{FormController.getValueOf(question)}</Typography></>;

    const field = inputAlias[config.type]({ question: question, pokeFlow: props.pokeFlow, canEdit: canEdit });
    if (config.type === 'info') {
      inner = field;
    } 

    if (question.configs[0].type === 'file') {
      const src = getImageURL(FormController.state.values[FormController.getKey(question)]);
      inner = <img src={src} style={{maxWidth: '200px', maxHeight: '200px'}}/>;
    }

    return <>
      <Box style={{paddingBottom: '20px'}}>
        { inner }
      </Box>
    </>;
  }





  if (props.viewMode) {
    if (['info','button'].includes(question.configs[0].type)) { return <></>}

    let options = <></>;
    if (question.configs[0].type === 'check') {
      options = question.options.filter(opt => FormController.getValueOf(opt) === true).map(opt => (<>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} >
        <Box display="flex" alignItems="center">
          <Typography>{opt.name}</Typography>
        </Box>
        </Grid>
      </>));
    }

    let showFlags = !props.hideFlags;

    return <Grid container>
    <Grid item xs={4}><Typography>{question.name}</Typography></Grid>
    <Grid item xs={8} ><Box display="flex" alignItems="center">
        <Typography>{FormController.getValueOf(question)}</Typography>
    </Box>
    </Grid>
    {/* {options} */}
    </Grid>;
  }

















  //console.log('james '+)


  const field = inputAlias[config.type]({ question: question, pokeFlow:props.pokeFlow, canEdit:canEdit });
  if (config.type == 'text' || config.type.includes('select') || config.type==='button' || config.type ==='info' || config.type === 'checklist') return field;
  return (
    <FormControl variant="outlined" fullWidth >
      <FormLabel>{processName(question)}</FormLabel>
        { field }
      <FormHelperText error={(fieldProps.error && true)}>{fieldProps.error}</FormHelperText>
      <FormHelperText dangerouslySetInnerHTML={{ __html: question.help_text }}></FormHelperText>
    </FormControl>);
}


function FieldContainer(props) {
  // const { config } = useValidatorContext();
  const FormController = useFormControllerContext();
  const question = props.question;
  // const config = FormController.config;
  // const conf = config.fields[props.fieldKey.getConfigKey()];
  // if (!conf) return <>no config</>;
  let size = 6;
  if (question.displays.length > 0) {
    if ("cols" in question.displays[0]) {
      size = parseInt(question.displays[0].cols);
    }
  }
  if ('adminView' in props) {
    size=12;
  }
  return (
    <Grid item xs={size}>
      <GetInput question={question} {...props}  />
    </Grid>
  );
}

export default DrawItem;
