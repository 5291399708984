import React from 'react';


import { useFormControllerContext } from "components/PM_FormController";

const AdminHelpersContext = React.createContext({});

export const AdminHelpersProvider = (props) => {
  const FormController = useFormControllerContext();
  const context = Admin_Helpers({...props, FormController});
  const memoizedContext = React.useMemo(() => context, [context]);
  return <AdminHelpersContext.Provider value={ memoizedContext } >{ props.children }</AdminHelpersContext.Provider>;
};

export function useAdminHelpersContext() {
  return React.useContext(AdminHelpersContext);
}



const Admin_Helpers = (props) => {
  const { FormController } = props;

  const getStatusList = () => {
    return {
      1:'incomplete',
      2: 'complete',//pending //submitted
      // 3:'admin edited',
      // 4:'reopened',
      // 5:'checked',
      // 6:'cancelled',
      10:'declined',
      11:'approved',
      // 15:'bump in',
      16:'waitlist',
      99:'deleted'
    }
  } 


  const getQuestionTitleMap = () => {

    // console.log(flow);
    const groupedQuestions = FormController.Flow.children.reduce((accum, step) => {
      accum[step.id] = FormController.getAllNodes('question', step).filter(q => q.configs[0].type !== 'info').reduce((accum2, question) => { accum2.push({[FormController.getKey(question)] : question.name}); return accum2; }, []);
      return accum;
    }, { Special: [
      {'.special.delete':'Remove Column'},
      {'.special.stall_name': 'Stall Name Full'},
      {'.special.cost_estimate': 'Cost Estimate'},
      {'.extra.site_number' : 'Site Number'},
      {'.extra.approval' : 'Approval Progress'},
      {'.special.all.stall': 'All Stall Data'},
      {'.special.all.personal': 'All Personal Data'},
      {'.special.all.site': 'All Site Data'},
      {'.special.all.business': 'All Business Data'},
      {'.special.all.bank': 'All Bank Data'},
      {'.special.all.menu': 'All Menu Data'},
      {'.special.all.gas': 'All Gas Data'},
    ], System: [{ '.system.id': 'ID' }, { '.system.date': 'Last Updated' }, { '.system.year': 'Year' }, { '.system.status': 'Status' }, { '.system.username': 'Username' }, { '.system.fullname': 'Full Name' }, { '.system.updated': 'Last Updated' }] });

    // console.log(FormController.Flow);
    // console.log(FormController.Flow);

    let QuestionTitleMap =  {};
    Object.keys(groupedQuestions).map((step) => { groupedQuestions[step].map(question => QuestionTitleMap[Object.keys(question)[0]] = Object.values(question)[0]) });
    // console.log(flow);
    // console.log(QuestionTitleMap);
    return QuestionTitleMap;
  }

  function exportToCsv(filename, rows) {
      var processRow = function (row) {
          var finalVal = '';
          for (var j = 0; j < row.length; j++) {
              var innerValue = (row[j] === null ||  row[j] === undefined) ? '' : row[j].toString();
              if (row[j] instanceof Date) {
                  innerValue = row[j].toLocaleString();
              };
              var result = innerValue.replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0)
                  result = '"' + result + '"';
              if (j > 0)
                  finalVal += ',';
              finalVal += result;
          }
          return finalVal + '\n';
      };

      var csvFile = '';
      for (var i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
      } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
  }
  const ExportAsCSV = (name, headers, data) => {
    let csv_rows = [];
    csv_rows = headers.reduce((accum,cell) => {
      if (cell.id.lastIndexOf('.all.') !== -1) {
        let keys = cell.id.split('.');

        let questions = FormController.getAllNodes('question', FormController.findChildById(keys[3], FormController.Flow)).filter(q => q.configs[0].type !== 'info');
        //FormController.
        if (cell.id.lastIndexOf('.menu') !== -1) {

          for (let ii = 0; ii < 40; ii++) {
            questions.forEach(question => accum.push(question.name));
          }
        } else {
          questions.forEach(question => accum.push(question.name));
        }  

      } else if (cell.id.lastIndexOf('.special.gas') !== -1) {

        let questions = FormController.getAllNodes('question', FormController.findChildById('site', FormController.Flow)).filter(q => q.id.startsWith('gas_'));
        questions.forEach(question => accum.push(question.name));
        console.log('question', questions)

      } else {
        accum.push(cell.label);
      }
      return accum;
    }, []);
    csv_rows = data.reduce((accum, row) => {
      let rowArray = [];
      headers.forEach(col => {
        if (col.id.lastIndexOf('.all.') !== -1) {
          let keys = col.id.split('.');

          let questions = FormController.getAllNodes('question', FormController.findChildById(keys[3], FormController.Flow)).filter(q => q.configs[0].type !== 'info');

         

          if (col.id.lastIndexOf('.menu') !== -1) {

            for (let ii = 0; ii<40; ii++) {
              if (Object.keys(row[col.id]).find((val) => val.lastIndexOf('.item#'+ii) !== -1) !== undefined || true) {
                questions.forEach(question => {
                  question.parent.instance = ii;
                  rowArray.push(row[col.id][FormController.getKey(question)] || '');

                });
              }
            }
          } else {
            questions.forEach(question => rowArray.push(row[col.id][FormController.getKey(question)] || ''));
          }
        } else if (col.id.lastIndexOf('.special.gas') !== -1) {

          let questions = FormController.getAllNodes('question', FormController.findChildById('site', FormController.Flow)).filter(q => q.id.startsWith('gas_'));
          questions.forEach(question => rowArray.push(row[col.id][FormController.getKey(question)] || ''));

        } else {
          rowArray.push(row[col.id]);
        }
      });
      accum.push(rowArray);
      return accum;
    }, [csv_rows]);

    // csv_rows = [csv_rows, ...d2];

    exportToCsv(name, csv_rows);
  }


  return {
    getQuestionTitleMap,
    getStatusList,
    ExportAsCSV
  }
}
