import React from 'react';

import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useFormControllerContext } from "components/PM_FormController";


export default function Input_Select(props) {


  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;
  const fieldProps = FormController.getFieldProps(question);


    return (

     <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
             format="DD/MM/yyyy"
             value={FormController.state.values[FormController.getKey(question)] || '01/12/2019'}
             onChange={fieldProps.onChange}
             disablePast={true}
             KeyboardButtonProps={{
               'aria-label': 'change date',
             }}
           />
    </MuiPickersUtilsProvider>

  )
}
