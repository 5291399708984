import React from 'react';

import { useParams } from "react-router-dom";

import xmlData from 'config/StallholderApplication2023';
import { useFormControllerContext, FormControllerProvider } from "components/PM_FormController";
import { useUserFeedback } from "components/UserFeedback";
import Conn_Application from "components/application_forms/Conn_Application";
import MenuPreview from 'components/application_forms/MenuPreview'; 

const MenuPageInner = (props) => {

  let { stall } = useParams();

  const FormController = useFormControllerContext();

  const { state, dispatch, shouldDisplay } = FormController;
  const [flow, setFlow] = React.useState({ ...FormController.Flow });

  const { getMenuByStallNumber } = Conn_Application(state, dispatch, () => {});

  React.useMemo(() => {
    getMenuByStallNumber(stall);
  }, []
  );

  let item = FormController.Flow.children.length && FormController.Flow.children[5].children[0].children[0];

  if (item && 'repeater' in item && FormController.getKey(item).includes("#0")) {
    let theKey = FormController.getKey(item).split("#")[0];
    // find highest matching count in values
    let theNumber = 0;
    Object.keys(FormController.state.values).forEach((valueKey) => {

      if (valueKey.includes(theKey)) {

        let newVal = parseInt(valueKey.split("#")[1].split(".")[0]);
        if (newVal > theNumber) {
          theNumber = newVal;
        }
      }
    });
    let count = item.parent.children.filter(child => child.id === item.id).length;

    for (let ii = count; ii <= theNumber; ii++) {
      FormController.addInstance(item);
    }
  }

  return (FormController.Flow.children.length && <><MenuPreview embed={true} /></>);

}

const MenuPage = (props) => {

  // dirty dirty hack





  return(
    <>
      <FormControllerProvider xmlData={xmlData}>
        <MenuPageInner props={props} />
      </FormControllerProvider>
    </>
  );
}

export default MenuPage;