import React from 'react';


import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ListView from "./ListView";
import Conn_Admin from 'components/admin/Conn_Admin';
import { useFormControllerContext } from "components/PM_FormController";

import QuickSearch from './QuickSearch';

const Overview = (props) => {

  const [views, setViews] = React.useState([]);

  const FormController = useFormControllerContext();
  const { getViews } = Conn_Admin();


  function handleViewsLoaded(data) {
    setViews(data);
  }
  const addNewDataView = () => {
    setViews([...views, {id:0, title:'Untitled Data View', columns:'.system.id|.special.stall_name', filters:'[]' }]);
  }
  // const addNewDataView = () => {
  //    // saveView('', 'Untitled Data View', [{id:'{id:'.system.id'},{id:'.special.all.stall'}'},{id:'.special.all.stall'}], 'james', "[]", handleCreatedView);
  // };

  React.useMemo(() => {
     if (FormController.Flow.children.length !== 0) {
       getViews(handleViewsLoaded);
     }
  }, [FormController.Flow]);

  const allViews = views.map((view, ii) => <ListView {...props} view={view} num={ii} key={ii} />);

  return <>
    <QuickSearch {...props} />
    {allViews}
    <Button onClick={addNewDataView}>Add New Data View</Button>
    <Link to={`/admin/dashboard`}><Button variant="contained">Dashboard Editor</Button></Link>
  </>
}

export default Overview;
