import React from 'react';

import { Select, FormControl, FormHelperText, InputLabel, MenuItem, ListSubheader } from '@material-ui/core';

import { useFormControllerContext } from "components/PM_FormController";


export default function Input_SelectGrouped(props) {

  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;
  const fieldProps = FormController.getFieldProps(question);
  let canEdit = ("canEdit" in props && props.canEdit);

  delete fieldProps.helperText;

  let options = [];
  question.children.filter(child => child.type === 'option_group').forEach(option_group => {
    options.push(<ListSubheader key={option_group.name}>{option_group.name}</ListSubheader>);
    option_group.options.forEach(option => {
      options.push(<MenuItem key={option.name} value={option.name}>{option.name}</MenuItem>);
    });
  });

  return (
      <FormControl variant="outlined" fullWidth>
           <InputLabel>{question.name}</InputLabel>
           <Select {...fieldProps} disabled={!canEdit}>
             { [...options] }
           </Select>
       </FormControl>
     )
}
