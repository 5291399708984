import React from 'react';

import { makeStyles, createTheme, MuiThemeProvider  } from '@material-ui/core/styles';
import { Grid, Button, Card, CardMedia, CardContent, Paper, Typography, FormHelperText, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Conn_Application from 'components/application_forms/Conn_Application';
import useConfirmation from 'components/application_forms/Confirmation';

import { useFormControllerContext } from "components/PM_FormController";

import  { useUserFeedback } from "components/UserFeedback";

const useStyles = makeStyles({
  root: {
    padding: '8px 16px'
  },
  media: {
    height: 240,
  },
  actionButton: {
    background: '#793478',
    borderRadius: "50%",
    padding: '10px',
    color: '#fff'
  }
});

const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      text: {
        color: "#000",
        '&::after': {
          display: 'none'
        }
      }
    }
  }
});

export default function Input_Photo(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  // const { values, errors, getFieldProps, config } = useValidatorContext();

  const FormController = useFormControllerContext();
  let canEdit = ("canEdit" in props && props.canEdit);
  const { showMessage } = useUserFeedback();
  const { uploadFile, getImageURL, getFileInfo } = Conn_Application(FormController.state, FormController.dispatch, showMessage);

  const question = props.question;

  const removeFile =(fieldKey) => {
    FormController.dispatch({
      type: 'change',
      payload: { [FormController.getKey(question)]: null,
        [FormController.getKey(question)+'_filename']: null
    }});
    showMessage('file successfully removed', 'info')
  };

  const { ConfirmationWindow, askForConfirmation } = useConfirmation({title:'remove file', task: 'delete file from your application', onYes:() => {removeFile(props.fieldKey)} });


  const [extra, setExtra] = React.useState({[props.fieldKey] : {}});




  const addFunctionality = () => (<>
    <Button
      variant="contained"
      color="primary"
      endIcon={<NoteAddIcon />}
      onClick={() => setOpen(true)}
      disabled={!canEdit}
    >
      Add Files
    </Button>
  <MuiThemeProvider theme={theme}>
    <DropzoneDialog
      acceptedFiles={['image/jpg', 'image/jpeg', 'application/pdf']}
      cancelButtonText={"cancel"}
      submitButtonText={"save and upload"}
      maxFileSize={50000000}
      open={open}
      onClose={() => setOpen(false)}
      onSave={(files) => {
        uploadFile(files[0], FormController.getKey(question), false);
        setOpen(false);
      }}
      dialogTitle="Select File"
      dropzoneText="Drag and drop file here, or click"
      showPreviews={true}
      showFileNamesInPreview={true}
      disabled={!canEdit}
   />
   </MuiThemeProvider>
   </>
 );

    return (
      <Paper className={classes.root}>
        <ConfirmationWindow />
      { FormController.state.values[FormController.getKey(question)] ? (
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography noWrap>{FormController.state.values[FormController.getKey(question)+'_filename'] || getFileInfo(FormController.state.values[FormController.getKey(question)], question, FormController.getKey)}</Typography>
          </Grid>
          <Grid item xs={1} container alignContent="flex-end">
            <VisibilityIcon className={classes.actionButton} onClick={ () => {window.open(getImageURL(FormController.state.values[FormController.getKey(question)]))}} />
                    </Grid>
            <Grid item xs={1} container alignContent="flex-end">
          <DeleteForeverIcon className={classes.actionButton} disabled onClick={ () => {askForConfirmation()} } />
          </Grid>
        </Grid>

      ) : addFunctionality() }
        <FormHelperText>jpg or pdf. max size 5mb</FormHelperText>
        {/* errors[props.fieldKey] && <p>{errors[props.fieldKey]}</p>*/}
      </Paper>
    )
  }
