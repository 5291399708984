import React from 'react';

import {  Link, Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { Button, AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { createTheme, ThemeProvider  } from '@material-ui/core/styles';


import { ReactComponent as Logo } from 'icons/Festivale Stacked 2025 Logo_Grey.svg';

import { CookiesProvider } from 'react-cookie';

import { FormControllerProvider } from "components/PM_FormController";
import xmlData from 'config/StallholderApplication';
import {  AdminHelpersProvider } from 'components/admin/Admin_Helpers';
import  { UserFeedbackProvider } from "../UserFeedback";


import Conn_Signing from '../signing/Conn_Signing';


import Overview from 'components/admin/Overview';
import EditDashboard from 'components/admin/EditDashboard';
import ViewApplication from 'components/admin/ViewApplication';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
   display: 'none',
   [theme.breakpoints.up('md')]: {
     display: 'flex',
   },
 },
 logo: {
  padding: "32px",
  background: "#fff",
  position: "absolute",
  top: "0",
  left: 0+"px",
  borderBottomLeftRadius: "30px",
  borderBottomRightRadius: "30px",
  transformOrigin: "0 -20px",

},
logoShadow: {
 padding: "32px",
 background: "transparent",
 position: "absolute",
 zIndex: 1,
 top: "0",
 left: theme.spacing(3),
 borderBottomLeftRadius: "30px",
 borderBottomRightRadius: "30px",
 transformOrigin: "0 -20px",
 boxShadow: "0 0 10px rgba(0,0,0,.5)",
 transition: "all 0.5s ease-out"
},
header: {
  "& * ": {
      transition: "all 0.5s ease-out"
  },
  zIndex: 10,
  borderBottom: '10px solid #fff',
  borderRight: '10px solid #fff',
  borderBottomRightRadius: '30px',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  width: 'auto'
},
headerIcon: {
  background: "#fff",
  color: "#793478",
  padding: "8px",
  marginLeft: "8px",
  "&:hover": {
    background: "#ee680f"
  }
},

buttonDark: {
  background: '#333',
  color: '#fff'
},

page: {
  padding: theme.spacing(5)
}

}));


const outerTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "#793478",
    },
    secondary: {
      main: "#ee680f",
    },
    error: {
      main: "#ee680f"
    },
    text: {
      secondary: "#000",
      primary: "#000"
    }
  },
  shape: {
    borderRadius: "15px"
  },
  typography: {
    h5: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
      display: "flex",
      alignItems: "center",
        "&::after": {
          content: '""',
          borderTop: "2px solid #ee680f",
          margin: "0 0 0 10px",
          flex: "1 0 20px"
        }
      },
      h6: {
        fontFamily: 'Rambla',
        textTransform: 'uppercase',
        fontSize: '24px'
      },
      h4: {
        fontFamily: 'Rambla',
        fontWeight: 'bold',
        fontSize: '18px'
      },
      body1: {
        '& a': {
          color: '#ee680f'
        }
      }
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "30px",
          padding: "1px 50px"
        }
      }
    }

});



export default function AdminPage(props) {

  const [ scrollPos, setScrollPos ] = React.useState(0);

  const classes = useStyles();
  const { doLogout } = Conn_Signing();
  return (
  <ThemeProvider theme={outerTheme}>
    <FormControllerProvider xmlData={xmlData}>
      <UserFeedbackProvider>
        <AdminHelpersProvider>
          <CookiesProvider>

          <div style={{position: "sticky", top: 0, zIndex: 9}}>
            <div className={classes.logoShadow} style={{ transform: 'scale('+(0.54+0.46*scrollPos)+')'}}>
              <Logo width="116px"   />
            </div>
          </div>

         <AppBar  position="sticky" className={classes.header}  >

           <Toolbar>
             <div className={classes.logo} style={{ transform: 'scale('+(0.54+0.46*scrollPos)+')'}}>
               <Link to="/admin"><Logo width="116px"   /></Link>
             </div>

             <Typography variant="h6" noWrap style={{ paddingLeft: (98+82*scrollPos)+'px'}} >
               Stallholders Application Admin Area
             </Typography>
             <div className={classes.grow} />

             <Typography variant="h6" noWrap  style={{ paddingRight: '24px'}} >
               Welcome
             </Typography>


               <Button variant="contained" className={classes.buttonDark} onClick={()=>{doLogout()}}>
               <Typography variant="h6" noWrap >
                 Logout
                </Typography>
               </Button>


                   </Toolbar>
             </AppBar>

            <div className={classes.page}>
              <Switch>
                <Route path={`${props.match.path}/view/:id/:year?`} component={ViewApplication} />
                <Route path={`${props.match.path}/dashboard`} component={EditDashboard} />
                <Route path={`${props.match.path}/edit/:id/:year?`} render={(props) => <ViewApplication {...props} doEdit={true}/>} />
                <Route path={`${props.match.path}/`}  component={Overview} />
              </Switch>
            </div>
          </CookiesProvider>
        </AdminHelpersProvider>
      </UserFeedbackProvider>
    </FormControllerProvider>
  </ThemeProvider>
   );
}
