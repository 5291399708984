import React from 'react';



import { RadioGroup, FormControl, FormControlLabel, FormLabel, FormHelperText, Radio } from '@material-ui/core';

import {  makeStyles } from '@material-ui/core/styles';

import { useFormControllerContext } from "components/PM_FormController";
import { useValidatorContext } from "components/PM_Validator2";

const useStyles = makeStyles({
  root: {
    "& span.label": {
      // textTransform: "uppercase",
    }
  }
});


export default function Input_Radio(props) {

  const classes = useStyles();
    // const { values, getFieldProps, config } = useValidatorContext();
    const FormController = useFormControllerContext();
    const question = props.question;
    // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
    const fieldProps = FormController.getFieldProps(question);
    delete fieldProps.helperText;
    fieldProps.className += ' '+classes.root;
    const options = question.options;
    let canEdit = ("canEdit" in props && props.canEdit);

    return (
      <FormControl fullWidth>
        <RadioGroup aria-label={question.name} {...fieldProps}>
        {
          options.filter((opt) => FormController.shouldDisplay(opt)).map((option) =>
            <FormControlLabel
              value={option.value}
              key={FormController.getKey(option) + option.value}
              control={<Radio color="primary" />}
              label={<><span className="label">{option.name}</span> <FormHelperText>{option.description}</FormHelperText></>}
              disabled={!canEdit}
            />)
        }
        </RadioGroup>
      </FormControl>
   );
}
