import React from 'react';
import Banks from 'config/banks';


import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField, Select, FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';

  import { useFormControllerContext } from "components/PM_FormController";

export default function Input_BankList(props) {

  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;
  const fieldProps = FormController.getFieldProps(question);
  delete fieldProps.helperText;
    return (
      <FormControl variant="outlined" fullWidth>
      <Autocomplete
        freeSolo
        options={Banks}

        renderInput={(params) => (
          <TextField {...params} label={question.name} variant="outlined" {...fieldProps}  />
        )}
      />

         </FormControl>
       )
}
