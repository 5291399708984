import React from 'react';

import {  makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import { useFormControllerContext } from "components/PM_FormController";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "2fr 2fr",
    gridTemplateRows: "1fr 1fr 2fr",
    gridGap: "1px",
    position: "relative",
    height: "500px",
    width: "100%",

    "& > *": {
      background: '#eee',
      position: "relative",
      "&::after": {
        position: 'absolute',
        right: '-40px',
        top: '-0.5em'
      },
      "&::before": {
        position: 'absolute',
        right: '-20px',
        bottom: '-1.5em'
      },
      "&:nth-child(3)::after": {
        content: "'6.0m'",
      },
      "&:nth-child(5)::after": {
        content: "'4.5m'",
      },
      "&:nth-child(7)::after": {
        content: "'3.0m'",
      },
      "&:nth-child(6)::before": {
        content: "'3.0m'",
      },
      "&:nth-child(7)::before": {
        content: "'6.0m'",
      },

      "&.footprint": {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 2,
        width: "0%",
        height: "0%",
        boxSizing: "border-box",
        background: "#ee680fCC",
        border: "0px solid #333",
        borderBottomColor: "#77ad30 ",
        transition: "all 0.5s ease-out",
        borderRadius: "15px",
        "&.w1": { width: "50%", borderWidth: "10px" },
        "&.w2": { width: "75%", borderWidth: "10px"  },
        "&.w3": { width: "100%", borderWidth: "10px"  },
        "&.h1": { height: "50%", borderWidth: "10px"  },
        "&.h2": { height: "75%", borderWidth: "10px"  },
        "&.h3": { height: "100%", borderWidth: "10px"  },
        "&.extra_frontage": { borderTopColor: "#77ad30"  },
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        textTransform: "uppercase",
        color: "#fff",
        overflow: "hidden"
      }
    }
  }
}));

export default function Input_SiteVisualiser(props) {

  const classes = useStyles();
  const FormController = useFormControllerContext();


   const variant = (v) => {
     switch(v) {
        case '3.0' : return '1';
        case '4.5' : return '2';
        case '6.0' : return '3';
        default: return '0';
      }
   }

   const width = ' w'+variant(FormController.state.values['.site.frontage']);
   const depth = ' h'+variant(FormController.state.values['.site.depth']);
   const extra_frontage = (FormController.state.values['.site.extra_frontage'] == "Yes") ? ' extra_frontage' : '';


    return (
      <div className={classes.root}>
        <div className={"footprint" + width + depth + extra_frontage}>
          <Typography>Front</Typography>
        </div>

        <div></div>
        <div></div>
        <div></div>


        <div></div>
        <div></div>
        <div></div>
      </div>
       )
}
