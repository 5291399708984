import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {  makeStyles } from '@material-ui/core/styles';


import {  Grid, Button, Typography, TextField, Select } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, OutlinedInput, FormControl, InputLabel } from '@material-ui/core';

import Config from 'Config.js';

import xmlData from 'config/StallholderApplication';
import xmlData23 from 'config/StallholderApplication2023';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useFormControllerContext, FormControllerProvider } from "components/PM_FormController";

import Conn_Admin from "components/admin/Conn_Admin";
import DrawItem from 'components/application_forms/DrawItem';

import { useUserFeedback } from "components/UserFeedback";


import { NotesList, NoteDialog } from "components/admin/Notes";
import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';
import { GeneratePDF, PDFGeneratorMarkup } from "components/application_forms/PDFGeneration";
import { StatusChangerDialog } from './StatusChanger';

// import {MessagingDialog} from './_Messaging';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  accordion: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: "#fff"
  },
  siteNum: {
    marginBottom: theme.spacing(4),
  },
  reversed: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: '#793478',
    '& *': {
      color: '#fff'
    }
  },
  form: {
    width: '100%',
    padding: theme.spacing(6),
    'box-sizing': 'border-box',
    '& .MuiFormHelperText-root': {
      color: '#888',
      fontSize: "10px",
      marginLeft: 0,
      "&.Mui-error": {
        color: "#ee680f",
        fontWeight: "bold"
      }
    }
  },
  button: {
   margin: theme.spacing(1)
 },
 magicAppbar: {
   zIndex: 4,
   borderBottom: "9px solid #fff",
   height: "73px",
   marginBottom: theme.spacing(4)
 },
 theButtons: {
   position: 'relative',
   zIndex: 8
 },
 headerIcon: {
   background: "#ee680f",
   color: "#793478",
   padding: "8px",
   marginLeft: "8px",
   "&:hover": {
     background: "#ee680f"
   }
 },
 small: {
  fontSize: '10px'
 }
}));



const GetName = (props) => {
  const FormController = useFormControllerContext();
  return (<>
    {FormController.state.values['.stall.stall_name']}
    {(FormController.state.values['.stall.stall_name2'] !== undefined
      & FormController.state.values['.stall.co_branded'] === "yes2")
      ? ' & ' + FormController.state.values['.stall.stall_name2'] : ''}
  </>);
};


const SavingMarkup = (props) => {
  const history = useHistory();
  const { saveStallholderApplication } = Conn_Admin();
  const FormController = useFormControllerContext();
  const saveCallback =() => {
    history.push(props.match.url.replace(/edit/, 'view'));
  }
  const handleSave = (e) => {
    //saveStallholderApplication
    saveStallholderApplication(props.user_id, props.match.params.year,  FormController.state, saveCallback);
  }
  return <Grid item xs={12} container alignItems="flex-start" justify="flex-end" direction="row">
  <Button onClick={handleSave} variant="contained" style={{background: '#77ad30', color: '#fff'}}>
  <Typography variant="h6">Save Changes</Typography></Button></Grid>;
}



const ViewApplication = (props) => {

const data = (props.match.params.year === 2023) ? xmlData23 : xmlData;

  return <FormControllerProvider xmlData={data}>
  <ViewApplicationInner {...props} />
</FormControllerProvider>

}

const ViewApplicationInner = (props) => {
  const { AddNoteMarkup, handleOpenAddNote } = NoteDialog();
  const { StatusChangerMarkup, handleOpenStatusChanger } = StatusChangerDialog();
  const FormController = useFormControllerContext();

  const {  dispatch, shouldDisplay } = FormController;
  const [flow, setFlow] = React.useState({...FormController.Flow});
  const [listRefresh, setListRefresh] = React.useState(0);
  const [resetLink, setResetLink] = React.useState('');
  const [emailOpen, setEmailOpen] = React.useState(false);
  const [siteNumberOpen, setSiteNumberOpen] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');

  const { showMessage } = useUserFeedback();

  const { saveStallholderApplication } = Conn_Admin(); 

  const { getApplicationById, onServer } = Conn_Admin();
  const doEdit = ('doEdit' in props && props.doEdit);


  //const { doForgotten } = Conn_Signing(FormController.dispatch);
  // const handleUnlock = (id, label, message, sendCallback) => {
  //   // handleOpenMessaging([{id:id, label:label}], message, sendCallback);
  // }

  

  const doReset = () => {
    onServer('doReset', { id: props.match.params.id }, setResetLink);
  }

  const GetStatus = (props) => {
    const Admin_Helpers = useAdminHelpersContext();
    const { values } =  props;

    return (<>
      {Admin_Helpers.getStatusList()[values['.system.status']]}
    </>);
  }

  // This was   React.useMemo, with empty dependency array, it may not work now
  React.useMemo(() => {
    // getApplicationById(props.match.params.id, (data) => { dispatch({
    //    type: 'change',
    //    payload: { ...data }
    //  })
    onServer('getById', { id: props.match.params.id, year: props.match.params.year }, data => {
      dispatch({
       type: 'change',
       payload: { ...data }
     })
     onServer('getEmailById', { id: props.match.params.id }, (res) => { setUserEmail(res); });
   });
  }, []);

  const handleRefreshList = () => {
    setListRefresh(listRefresh + 1);
  };
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  accordion: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: "#fff"
  },
  siteNum: {
    marginBottom: theme.spacing(4),
  },
  reversed: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: '#793478',
    '& *': {
      color: '#fff'
    }
  },
  form: {
    width: '100%',
    padding: theme.spacing(6),
    'box-sizing': 'border-box',
    '& .MuiFormHelperText-root': {
      color: '#888',
      fontSize: "10px",
      marginLeft: 0,
      "&.Mui-error": {
        color: "#ee680f",
        fontWeight: "bold"
      }
    }
  },
  button: {
   margin: theme.spacing(1)
 },
 magicAppbar: {
   zIndex: 4,
   borderBottom: "9px solid #fff",
   height: "73px",
   marginBottom: theme.spacing(4)
 },
 theButtons: {
   position: 'relative',
   zIndex: 8
 },
 headerIcon: {
   background: "#ee680f",
   color: "#793478",
   padding: "8px",
   marginLeft: "8px",
   "&:hover": {
     background: "#ee680f"
   }
 },
 small: {
  fontSize: '10px'
 }
}));
  const classes = useStyles();

  let screen_tabs = FormController.Flow.children.map((screen, index) => {
    return (

      <Accordion  className={classes.paper}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading} variant="h6">{screen.name}</Typography>
        <div className={classes.grow} />
      </AccordionSummary>
      <AccordionDetails>
          <FormContainer key={screen.name} classes={classes}>
          {
             screen.children
              .filter((group) => {
               return shouldDisplay(group) }) //&& group.children.filter((n) => shouldDisplay(n)).length > 0;
              .map((group) => (


                  <DrawItem item={group} updateFlow={setFlow} viewMode={!doEdit} adminView={true} {...props}  />

            ))
          }
              </FormContainer>
          </AccordionDetails>

        </Accordion>

    );
  });

  const handleUpdate = (email) => {
    onServer(
      'updateEmailById', 
      { id: props.match.params.id, email:email }, 
      (res) => { setEmailOpen(false); showMessage("Email address updated", "success") }, 
      (res) => {  showMessage("Error: "+res.error, "error") }
    );
  }


  // const downloadAllRSAs = (name) => {
  //   onServer(
  //     'downloadRSAs',
  //     { id: props.match.params.id }
  //   );
  // }

  const EditEmailMarkup = (props) => {
    const [email, setEmail] = React.useState(props.email);
  return <>
    <Dialog open={emailOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5" color="primary">
          Update User Email
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField fullWidth variant="outlined" value={email} onChange={(e, val) => {
          setEmail(e.target.value)
            }}
           />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => { setEmailOpen(false); }} >
          Cancel
        </Button>
        <Button color="secondary" onClick={() => handleUpdate(email)} >
          Update Email
        </Button>
      </DialogActions>
    </Dialog>
  </>
  };

  const EditSiteNumberMarkup = () => {
  
    const { saveStallholderApplication } = Conn_Admin();
    const FormController = useFormControllerContext();
    const saveCallback = () => {
      setSiteNumberOpen(false);
    }
    const handleSave = (e) => {
      //saveStallholderApplication
      saveStallholderApplication(props.user_id, FormController.state, saveCallback);
    }

  return <>
    <Dialog open={siteNumberOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5" color="primary">
          Update Site Number
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          type="text"
          label="Site Number"
          onChange={(e, val) => {
            dispatch({
              type: 'change',
              payload: {
                ['.extra.site_number']: e.target.value
              }
            })
          }}
          value={FormController.state.values['.extra.site_number'] || ' '}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => { setSiteNumberOpen(false); }} >
          Cancel
        </Button>
        <Button color="secondary" onClick={handleSave} >
          Update Site Number
        </Button>
      </DialogActions>
    </Dialog>
  </>;
  }



  const PasswordReset = () => {
    if (resetLink) {
      return (<>
        <Grid item xs={4}>
          <Typography>Reset Link</Typography>
        </Grid>
        <Grid item xs={12}>
          <a href={resetLink} target="_blank"><Typography className={classes.small}>{resetLink}</Typography></a>
        </Grid>
      </>);
    } else {
      return (<>
        <Grid item xs={12}>
          <Button fullWidth onClick={doReset} variant="contained">Reset Password</Button>
        </Grid>
      </>);
    }
  } 
  

  return (
    <>
      
      <Grid container spacing={1}>
        {/* {(!doEdit && <> */}
        <Grid item container xs={4} className={classes.paper}>
          <Grid item container justifyContent="center" xs={12} >
            <Typography variant="h6" justify="center">
              Application Information
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>Stall Name:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography><GetName /> ({props.match.params.year})</Typography>
          </Grid>

            <Grid item xs={4}>
              <Typography>Site Number:</Typography>
            </Grid>
            <Grid item xs={8}>
            {(doEdit) ? (<>
              {/* <Grid item xs={12} container justify="flex-end" className={classes.siteNum}> */}
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  // label="Site Number"
                  onChange={(e, val) => {
                    dispatch({
                      type: 'change',
                      payload: {
                        ['.extra.site_number']: e.target.value
                      }
                    })
                  }}
                  value={FormController.state.values['.extra.site_number'] || ' '}
                />
              {/* </Grid> */}
            </>) : <Typography>{FormController.state.values['.extra.site_number']}</Typography>}
              
            </Grid>

          
            <Grid item xs={4}>
              <Typography>Assigned Stall Type:</Typography>
            </Grid>
            <Grid item xs={8}>
            {(doEdit) ? (<>
              {/* <Grid item xs={12} container justify="flex-end" className={classes.siteNum}> */}
                <TextField
                  variant="outlined"
                  type="text"
                size="small"
                  // label="Site Number"
                  onChange={(e, val) => {
                    dispatch({
                      type: 'change',
                      payload: {
                        ['.extra.assigned_stall']: e.target.value
                      }
                    })
                  }}
                value={FormController.state.values['.extra.assigned_stall'] || ' '}
                />
              {/* </Grid> */}
            </>) : <Typography>{FormController.state.values['.extra.assigned_stall']}</Typography>}
              
            </Grid>

            <Grid item xs={4}>
              <Typography>Approval Progress:</Typography>
            </Grid>
            <Grid item xs={8}>
            {(doEdit) ? (<>
              {/* <Grid item xs={12} container justify="flex-end" className={classes.siteNum}> */}

            <Select native defaultValue="" value={FormController.state.values['.extra.approval'] || ''} onChange={(e, val) => {
                    dispatch({
                      type: 'change',
                      payload: {
                        ['.extra.approval']: e.target.value
                      }
                    })
                  }}>
              <option value=""></option>
              <option value="Checked">Checked</option>
              <option value="Approved">Approved</option>
              <option value="Wait List">Wait List</option>
              <option value="Declined">Declined</option>              
              <option value="Cancelled">Cancelled</option>              
            </Select>
              
              {/* </Grid> */}
            </>) : <Typography>{FormController.state.values['.extra.approval']}</Typography>}
              
            </Grid>
          

          <Grid item xs={4}>
            <Typography>Status:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography><GetStatus values={FormController.state.values} flow={flow} /></Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>Stall Type:</Typography>
          </Grid>
          <Grid item xs={8}>
              <Typography>{FormController.state.values['.stall.site_type']}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>Primary Contact:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {FormController.state.values['.personal.name_first']} {FormController.state.values['.personal.name_last']}<br />
              <a href={'tel:' + FormController.state.values['.personal.mobile']}>{FormController.state.values['.personal.mobile']}</a><br />
              <a href={'mailto:' + FormController.state.values['.personal.email']}>{FormController.state.values['.personal.email']}</a><br />
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>Last Update:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {FormController.state.values['.system.updated']} 
            </Typography>
          </Grid>
        </Grid>



        <Grid item container xs={3} className={classes.paper} spacing={0}>
          <Grid item container justifyContent="center" xs={12} > 
            <Typography variant="h6" >
              Actions
            </Typography>
          </Grid>

          {(!doEdit) && <Grid item xs={12}>
            <Button fullWidth component={Link} to={props.match.url.replace(/view/, 'edit')} variant="contained">Edit This Application</Button>
          </Grid>}

          {/* <Grid item xs={12}>
            <Button fullWidth onClick={() => { handleOpenStatusChanger([{ id: props.id, label: <GetName/> }], props.dispatch); }} variant="contained">Change Status  </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Button fullWidth onClick={() => { setEmailOpen(true); }} variant="contained">Update Email Address</Button>
          </Grid>

          <PasswordReset />

          <Grid item xs={12}> 
              <Button fullWidth onClick={() => { GeneratePDF(FormController.GetName(), props.match.params.year); }} variant="contained">Download as PDF </Button>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth href={Config.server.backend + "readrsas.php?id=" + props.match.params.id} variant="contained">Download All RSA's</Button>
          </Grid>
        

        </Grid>

          <Grid item xs={5} className={classes.paper} >
            <Grid container justifyContent="center">
              <NotesList id={props.match.params.id} refresh={listRefresh}/>
              <Button onClick={() => { handleOpenAddNote(props.match.params.id, () => { handleRefreshList(); })}} variant="contained">
                <Typography variant="h6">Add New Admin Note</Typography>
              </Button>
            </Grid>
        </Grid>
        {/* </>)} */}
        

        <Grid item container xs={12}>
          { screen_tabs }
        </Grid>
        
        <Grid item container xs={12}>
        {(doEdit) ? <SavingMarkup user_id={props.match.params.id} {...props} /> : '' }
        </Grid>
      </Grid>
      {AddNoteMarkup(handleRefreshList)}

      <EditEmailMarkup email={userEmail}/>
      <EditSiteNumberMarkup />

      <PDFGeneratorMarkup id={props.match.params.id} />

      {StatusChangerMarkup}
    </>
  );

}

function FormContainer(props) {
    const FormController = useFormControllerContext();
  // const { getFormProps, numberOfErrorFileds } = useValidatorContext();

  return (
    <form {...FormController.getFormProps()} >
      {props.children}
    </form>
  )
}

export default ViewApplication;
