import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Box, AppBar, Divider, Toolbar, Typography, IconButton, Badge, Drawer, Grid, Button } from '@material-ui/core';


import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  magicbr: {
    whiteSpace: 'pre-line',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

  footer: {
    height: "243px",
    border: '10px solid #fff',
    borderBottom: 0,
    background: "#333",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    margin: "0 " + theme.spacing(1) + 'px',
    padding: '32px 64px',
    boxSizing: 'border-box',
    position: 'absolute',
    zIndex: 5,
    width: 'calc(100% - 16px)',
    boxSizing: 'border-box',
    bottom: 0
  },
  footerLayout: {
    display: 'grid',
    gridTemplateColumns: '22fr 22fr 22fr',
    '& > div:nth-child(2)': {
      justifySelf: 'center'
    },
  },
  save_the_date: {
    border: '5px solid #000',
    borderRadius: '25px',
    background: '#793478',

    display: 'flex'
  },
  info: {
    color: '#fff',
    fontSize: '20px',
    lineHeight: '1.1',
    marginBottom: '16px',
    paddingTop: '30px',
    '& strong': {
      color: '#ee680f',
      textTransform: 'uppercase'
    }
  },
  assistance: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    textTransform: 'uppercase',
    '& ul': {
      listStyle: 'none',
      margin: '1em 0',
      padding: 0,
      '& li': {
        margin: '.8em 0',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        '& a': {
          color: '#fff',
          textDecoration: 'none',
          marginLeft: '32px',
          textTransform: 'lowercase',
          lineHeight: '1.0'
        }
      }
    }
  },
  credit: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    '& a': {
      color: '#77ad30',
      textDecoration: 'none'
    },
  },
  submissions: {
    color: '#fff',
    fontSize: '12px'
  },
  tandc: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '30px'
  },

  footerShard: {
    width: '277px',
    height: '335px',
    position: 'absolute',
    bottom: '60px',
    right: '-17px',
    zIndex: 10,
    pointerEvents: 'none'
  },
  footerShardLeft: {
    width: '710px',
    height: '334px',
    position: 'absolute',
    bottom: '60px',
    left: '-17px',
    zIndex: 1,
    pointerEvents: 'none'
  },
  headerShard: {
    width: '477px',
    height: '255px',
    position: 'fixed',
    top: '40%',
    right: '0px',
    zIndex: 1
  },
  bgShard: {
    width: '709px',
    height: '365px',
    position: 'fixed',
    top: '20%',
    left: '-17px',
    zIndex: 1
  },
  toTop: {
    position: 'absolute',
    right: '30px',
    top: '30px',
    color: '#fff',
    background: '#793478',
    borderRadius: '50%',
    padding: '10px'
  },
  saveThe: {
    width: 910 / 2 + 'px',
    height: 130 / 2 + 'px'
  }
}));

const toTop = () => {
  window.scrollTo(0, 0);
  return false;
}


const ApplicationFooter = (props) => {

  const classes = useStyles();

  return (
    <>
      <img src={process.env.PUBLIC_URL + '/shard_footer_left_yellow.png'} className={classes.footerShardLeft} />

      <Box boxShadow={12} className={classes.footer} spacing={16}>
        <img src={process.env.PUBLIC_URL + '/shard_footer_right.png'} className={classes.footerShard} />
        <div className={classes.footerLayout} id="dirtyFooterHook">
          <div>
            <Typography className={classes.info}>
              <strong>Festivale 2025</strong> applications for Stallholders<br />
              closes on <strong>26 July 2024 at 5:00pm</strong>.
            </Typography>
          </div>
          <div>
            <div className={classes.save_the_date}>
              <img src={process.env.PUBLIC_URL + '/festivale_2025_save_the_date.png'} className={classes.saveThe} />

            </div>
            <Typography className={classes.tandc}>
              Stallholder <a href="/Stallholder Application TC Final_web version 2025_V1.pdf">Terms &amp; Conditions</a>
            </Typography>
          </div>
          <div>
            <div className={classes.assistance}>
              <Typography>Need assistance or have any questions, <br />then let us know:</Typography>
              <ul>
                <li><PhoneIcon /><Typography><a href="tel:63349990">6334 9990</a></Typography></li>
                <li><MailOutlineIcon /><Typography><a href="mailto:eventmanager@festivale.com.au">eventmanager@festivale.com.au</a></Typography></li>
              </ul>
            </div>

            <Typography className={classes.credit} align="right">
              created by <a href="https://purposemarketing.com.au">PURPOSE Marketing</a>
            </Typography>
          </div>
        </div>
        <ArrowUpwardIcon className={classes.toTop} onClick={toTop} />
      </Box>
    </>
  );
}

export default ApplicationFooter;