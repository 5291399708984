import React from 'react';


import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

import { useValidatorContext } from "components/PM_Validator2";
import { useFormControllerContext } from "components/PM_FormController";

const useStyles = makeStyles((theme) => ({
  formgroup: {
    display: 'flex',
    'flex-direction': 'row',
    '& > *': {
      // width: '150px'
    }
  },
}));

export default function Input_Checkbox(props) {

  const classes = useStyles();
  const FormController = useFormControllerContext();
  // const item = FormController.config.fields[props.fieldKey.getConfigKey()];
  const question = props.question;
  let canEdit = ("canEdit" in props && props.canEdit);

    const handleChange = (opt) => {
      return (e) => {
        const optKey = FormController.getKey(opt);
        let parentValue = FormController.state.values[FormController.getKey(opt.parent)] || '';
        if (e.target.checked && !parentValue.includes(optKey)) {
          parentValue += '|'+optKey;
        }
        if (!e.target.checked && parentValue.includes(optKey)) {
          parentValue = parentValue.replace('|'+optKey, '');
        }
        FormController.dispatch({
          type: 'change',
          payload: {
            [optKey]: e.target.checked,
            [FormController.getKey(opt.parent)]: parentValue
          }
        });
      }
    };

    return (

        <FormGroup className={classes.formgroup}>
        { question.options.map((opt) =>
          <FormControlLabel
              control={<Checkbox name={FormController.getKey(opt)} color="primary" onChange={handleChange(opt)} checked={FormController.state.values[FormController.getKey(opt)] || false} />}
              label={opt.name}
              key={opt.value}
              labelPlacement="end"
              disabled={!canEdit}
            />)
          }
          </FormGroup>

   );
}
