import React from 'react';
import {  Link, Redirect,Route, BrowserRouter as Router } from 'react-router-dom';

import { makeStyles, withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button, Container, Typography, Grid, Paper, Tab, Tabs, Box } from '@material-ui/core';



import Conn_Signing from '../signing/Conn_Signing';

import Login from '../signing/Login';
import SignUp from '../signing/SignUp';
import Welcome from '../signing/Welcome';
import LoginForgotten from '../signing/LoginForgotten';
import Reset from '../signing/Reset';
import Locked from '../signing/Locked';
import PDFDownload from '../signing/PDFDownload';
import { PM_Validation } from '../PM_Validator2';
import { ReactComponent as Logo } from 'icons/Festivale Stacked 2025 Logo_Grey.svg';
import { ReactComponent as BG } from 'icons/Badge_vector_square.svg';

const outerTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "#793478",
    },
    secondary: {
      main: "#ee680f",
    },
    error: {
      main: "#ee680f"
    },
    text: {
      secondary: "#000",
      primary: "#000"
    }
  },
  shape: {
    borderRadius: "15px"
  },
  typography: {
    h5: {
      fontFamily: 'Rambla',
      textTransform: 'uppercase',
      display: "flex",
      alignItems: "center",
        "&::after": {
          content: '""',
          borderTop: "2px solid #ee680f",
          margin: "0 0 0 10px",
          flex: "1 0 20px"
        }
      },
      h6: {
        fontFamily: 'Rambla',
        textTransform: 'uppercase',
      },
      h4: {
        fontFamily: 'Rambla',
        fontWeight: 'bold',
        fontSize: '18px'
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "30px"
        }
      }
    }

});



const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundImage: 'url('+process.env.PUBLIC_URL +'/festivale_welcome_background.png)',
    backgroundColor: '#000',
    backgroundPosition: '50% 50%',

  },
  logo: {
    width: '253px',
    height: '253px',
    position: 'absolute',
    top: '-200px',
    left: '50%',
    marginLeft: '-128px',
    zIndex: '2'
  },
  theLogo: {
    width: '110px',
    position: 'absolute',
    top: '45px',
    left: '73px',
    zIndex: '2'
  },
  theBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  contined: {
    height: '100%'
  },
  container: {
    padding: '32px',
  },
  title: {
    position: 'relative',

    color: "#333",
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  header: {
    height: '80px',
    background: '#793478',
    position: 'relative'
  },
  footer: {
    height: '60px',
    background: '#333',
  },
  wrapper: {
      width: "820px",
      position: 'relative'
  },
  paper: {
    overflow: 'hidden',
    maxWidth: "820px",
    border: '11px solid #fff',
    borderRadius: '30px',

  },
  register: {
    background: '#333',
    textTransform: 'uppercase',
    color: '#fff',
    borderRadius: '15px',
    fontSize: '24px'
  },
  noUnderline: {
    textDecoration: 'none'
  },
  login: {
    background: '#ee680f',
    textTransform: 'uppercase',
    color: '#fff',
    borderRadius: '15px',
    fontSize: '24px',
    textDecoration: 'none'
  },
  mainBody: {
    padding: '0 32px 32px 32px !important'

  },
  forgotten: {
    fontSize: '12px',
    color: '#793478',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  ["@media (max-width:600px)"] : {
    buttons: {
      display: 'none'
    },
    mainBody: {
      maxWidth: '100%',
      flexBasis: '100%',
    }

  }
});



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid item xs
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} >
          {children}
        </Box>
      )}
    </Grid>
  );
}

function SigningPage(props) {

  const [value, setValue] = React.useState(1);

   const handleChange = (event, newValue) => {
     setValue(newValue);
   };


  return (
      <ThemeProvider theme={outerTheme}>
        <Route path={`${props.match.path}/reset/:otp`} render={(props) =>
         <Page {...props} match={props.match} title="Reset Password" loginButton welcomeButton component={Reset}>

         </Page>}>
        </Route>
        <Route path={`${props.match.path}/locked`}>
         <Page match={props.match} title="Application Locked" loginButton welcomeButton>
          <PDFDownload />
         </Page>
        </Route>
        <Route path={`${props.match.path}/forgotten`}>
         <Page match={props.match} title="Forgotten Password" loginButton welcomeButton>
           <LoginForgotten />
         </Page>
        </Route>
        <Route path={`${props.match.path}/register`}>
         <Page match={props.match} title="Register" loginButton welcomeButton>
           <SignUp />
         </Page>
        </Route>
        <Route path={`${props.match.path}/login`}>
        <Page match={props.match} title="Login" welcomeButton registerButton>
           <Login />
         </Page>
        </Route>


      <Route path={`${props.match.path}/pdf/:otp`} render={(props) =>
        <Page {...props} match={props.match} title="Application Completed" loginButton welcomeButton component={PDFDownload}>
        </Page>}>
        </Route>
      

        <Route path={`${props.match.path}/`} exact>
         <Page match={props.match} title="Welcome" loginButton registerButton>
           <Welcome />
         </Page>
        </Route>
  </ThemeProvider>
  )
}
//              <props.component otp={props.match.params.otp} />
const Page = (props) => {

  const body = (props.component) ? <props.component otp={props.match.params.otp} /> : props.children;

  const classes = useStyles();
  return (
    <Grid container className={classes.root} justify="center" alignItems="center" >

      <Box  className={classes.wrapper}>
        <Box className={classes.logo}>
          <Logo class={classes.theLogo} />
          <BG class={classes.theBackground} />
        </Box>
        <Paper className={classes.paper}>
          <Grid container spacing={4}>
            <Grid  item xs={12} className={classes.header} />
            <Grid item xs={12}>
              <Typography variant="h4" align="center" className={classes.title}>{props.title}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.mainBody}>
              {body}
            </Grid>
            <Grid
              item xs={4}
              spacing={4}
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              className={classes.buttons}
            >
              {props.registerButton && <Grid item>
                <Link to={`/auth/register`} className={classes.noUnderline}>
                  <Button variant="contained" fullWidth className={classes.register}>Register</Button>
                </Link>
              </Grid>}
              {props.loginButton && <Grid item>
                <Link to={`/auth/login`} className={classes.noUnderline}>
                  <Button variant="contained" fullWidth className={classes.login}>Login</Button>
                </Link>
              </Grid>}
              {props.welcomeButton &&
                <Grid item>
                  <Link to={`/auth/`} className={classes.noUnderline}>
                    <Button variant="contained" fullWidth className={classes.login}>Welcome</Button>
                  </Link>
                </Grid>}
                <Grid item align="center">
                  <Link to={`/auth/forgotten`} className={classes.noUnderline}>
                    <Typography className={classes.forgotten}>Forgotten Password</Typography>
                  </Link>
                </Grid>
            </Grid>

            <Grid  item xs={12} className={classes.footer} />
          </Grid>

        </Paper>
      </Box>
    </Grid>

  );
}

/*

*/

export default SigningPage;
