// import React from 'react';

const ApplicationHelpers = (props) => {

  const FormController = props.FormController;

  function createRow(name, cost) {
    return { name, cost };
  }

  const getEstimateData = (values = FormController.state.values, year=2025) => {
    let rows = [];
    if (year == 2025) {


      const basicCost = {
        'food': {
          '3.0': {
            '3.0': 1500,
            '6.0': 1900,
          },
          '6.0': {
            '3.0': 2400,
            '6.0': 2900
          }
        },
        'both': {
          '3.0': {
            '3.0': 2800,
            '6.0': 3600,
          },
          '6.0': {
            '3.0': 4200,
            '6.0': 4300
          },
        },
        'beverage': {
          '3.0': {
            '3.0': 3000,
            '6.0': 3800
          },
          '6.0': {
            '3.0': 4400,
            '6.0': 5300
          }
        }



      }

      const typeMap = {
        'food': 'Food Only',
        'beverage': 'Beverage Only',
        'both': 'Food and Beverage',
        'coffee': 'Coffee Van'
      }

      const siteType = values['.site.type'];
      const cobrand = values['.stall.co_branded'];
      const type = values['.stall.site_type'];
      const frontage = values['.site.frontage'];
      const depth = values['.site.depth'];

      if (siteType === '') {
        rows.push(createRow('no site type selected!', ''));
      } else {

        if (siteType === 'caravan') {
          if (type === 'food') {
            rows.push(createRow('Food Van 6m x 3m', 3700));
          } else if (type === 'coffee') {
            rows.push(createRow('Coffee Van 6m x 3m', 600));
          } else if (type === 'both') {
            rows.push(createRow('Food & Beverage Van 6m x 3m', 3700));
          } else {
            rows.push(createRow('Beverage Van 6m x 3m', 3700));
          }
        } else {

          if (type === undefined) {
            rows.push(createRow('You must select a stall type!', ''));
          } else {
            if (frontage === undefined || depth === undefined) {
              rows.push(createRow('You must specify the dimentions of your site!', ''));
            } else {
              if (type === '' || !(frontage in basicCost[type]) || !(depth in basicCost[type][frontage])) {
                rows.push(createRow('invalid site dimentions!', ''));
              } else {
                rows.push(createRow(typeMap[type] + ' site - ' + frontage + 'm x ' + depth + 'm', basicCost[type][frontage][depth]));
              }
            }
          }
          
        }

      }

      const noGas = ['gas_fri_85', 'gas_sat_85', 'gas_sun_85', 'gas_fri_15', 'gas_sat_15', 'gas_sun_15', 'gas_fri_45', 'gas_sat_45', 'gas_sun_45'].every(item => FormController.state.values['.site.gas.' + item] === 0 || FormController.state.values['.site.gas.' + item] === undefined || FormController.state.values['.site.gas.' + item] === '0');
      if (!noGas) {
        rows.push(createRow('Gas fee', 230)); //185
      }
      const outlets = values['.site.power.outlets'];
      if (outlets !== undefined && outlets > 0) {
        rows.push(createRow(outlets + ' Additional Power outlets', outlets * 190));//170
      }

      const additional_marqee = values['.site.additional_marqee'];
      if (additional_marqee !== undefined && additional_marqee === 'yes') {
        rows.push(createRow('Additonal Back of House marquee', 345));//170
      }

      const adjust = values['.finalise.cost_adjust'];
      if (adjust !== undefined && adjust !== 0) {
        rows.push(createRow(values['.finalise.cost_adjust_reason'], adjust * 1));//170
      }

      rows.push(createRow('Passes included: 2x 3 days passes ', 0));

      rows.push(createRow("Total (inc GST)",
        rows.reduce((accum, row) => { return accum + row.cost; }, 0))
      )
    }   else if (year == 2024) {


      const basicCost = {
        'food': {
          '3.0': {
            '3.0': 1425,
            '6.0': 1780,
          },
          '6.0': {
            '3.0': 2260,
            '6.0': 2730
          }
        },
        'both': {
          '3.0': {
            '3.0': 2662,
            '6.0': 3388,
          },
          '6.0': {
            '3.0': 3993,
            '6.0': 4100
          },
        },
        'beverage': {
          '3.0': {
            '3.0': 2662,
            '6.0': 3388
          },
          '6.0': {
            '3.0': 3993
          }
        }



      }

      const typeMap = {
        'food': 'Food Only',
        'beverage': 'Beverage Only',
        'both': 'Food and Beverage',
        'coffee': 'Coffee Van'
      }

      const siteType = values['.site.type'];
      const cobrand = values['.stall.co_branded'];
      const type = values['.stall.site_type'];
      const frontage = values['.site.frontage'];
      const depth = values['.site.depth'];

      if (siteType === '') {
        rows.push(createRow('no site type selected!', ''));
      } else {

        if (siteType === 'caravan') {
          if (type === 'food') {
            rows.push(createRow('Food Van 6m x 3m', 2350));
          } else if (type === 'coffee') {
            rows.push(createRow('Coffee Van 6m x 3m', 1980));
          } else if (type === 'both') {
            rows.push(createRow('Food & Beverage Van 6m x 3m', 3000));
          } else {
            rows.push(createRow('Beverage Van 6m x 3m', 3000));
          }
        } else {

          if (type === undefined) {
            rows.push(createRow('You must select a stall type!', ''));
          } else {
            if (frontage === undefined || depth === undefined) {
              rows.push(createRow('You must specify the dimentions of your site!', ''));
            } else {
              if (type === '' || !(frontage in basicCost[type]) || !(depth in basicCost[type][frontage])) {
                rows.push(createRow('invalid site dimentions!', ''));
              } else {
                rows.push(createRow(typeMap[type] + ' site - ' + frontage + 'm x ' + depth + 'm', basicCost[type][frontage][depth]));
              }
            }
          }
          
        }

      }

      const noGas = ['gas_fri_85', 'gas_sat_85', 'gas_sun_85', 'gas_fri_15', 'gas_sat_15', 'gas_sun_15', 'gas_fri_45', 'gas_sat_45', 'gas_sun_45'].every(item => FormController.state.values['.site.gas.' + item] === 0 || FormController.state.values['.site.gas.' + item] === undefined || FormController.state.values['.site.gas.' + item] === '0');
      if (!noGas) {
        rows.push(createRow('Gas fee', 185)); //185
      }
      const outlets = values['.site.power.outlets'];
      if (outlets !== undefined && outlets > 0) {
        rows.push(createRow(outlets + ' Additional Power outlets', outlets * 170));//170
      }

      const additional_marqee = values['.site.additional_marqee'];
      if (additional_marqee !== undefined && additional_marqee === 'yes') {
        rows.push(createRow('Additonal Back of House marquee', 315));//170
      }

      const adjust = values['.finalise.cost_adjust'];
      if (adjust !== undefined && adjust !== 0) {
        rows.push(createRow(values['.finalise.cost_adjust_reason'], adjust * 1));//170
      }

      rows.push(createRow('Passes included: 2x 3 days passes ', 0));

      rows.push(createRow("Total (inc GST)",
        rows.reduce((accum, row) => { return accum + row.cost; }, 0))
      )

      

    } else if (year == 2023) {

      const basicCost = {
        'food': {
          '3.0' : {
            '3.0' : 1320,
            '4.5' : 1430,
            '6.0' : 1650,
          },
          '6.0' : {
            '3.0' : 2090,
            '4.5' : 2200,
            '6.0' : 2530
          }
        },
        'both': {
          '3.0' : {
            '3.0' : 2420,
            '6.0' : 3080,
          },
          '6.0' : {
            '3.0' : 3520,
            '6.0' : 5390
          },
        },
        'beverage' : {
          '3.0' : {
            '3.0' : 2420,
            '6.0' : 3080
          },
          '6.0' : {
            '3.0' : 3520,
            '6.0' : 3960
          }
        }



      }

      const typeMap = {
        'food': 'Food Only',
        'beverage' : 'Beverage Only',
        'both' : 'Food and Beverage',
        'coffee': 'Coffee Van'
      }

      const siteType = values['.site.type'];
      const cobrand = values['.stall.co_branded'];
      const type = values['.stall.site_type'];
      const frontage =values['.site.frontage'];
      const depth = values['.site.depth'];
      
      if (siteType === '') {
        rows.push(createRow('no site type selected!', ''));
      } else {

        if (siteType === 'caravan') {
          if (type === 'food') {
            rows.push(createRow('Food Van - POA', 2200));
          } else if (type === 'coffee') {
            rows.push(createRow('Coffee Van - POA', 1980));
          } else if (type === 'both') {
              rows.push(createRow('Food & Beverage Van - POA', 2750));
          } else {
            rows.push(createRow('Beverage Van - POA', 2750));
          }
        } else {

          if (cobrand !== 'no') {
            // is cobranded
            rows.push(createRow('Co-Branded site - '+frontage+'m x '+depth+'m', (depth === '3.0') ? 4400 : 5390));
          } else {
            if (type === undefined) {
              rows.push(createRow('You must select a stall type!', ''));
            } else {
              if (frontage === undefined || depth === undefined) {
                rows.push(createRow('You must specify the dimentions of your site!', ''));
              } else {              
                if (type === '' || !(frontage in basicCost[type]) || !(depth in basicCost[type][frontage])) {
                  rows.push(createRow('invalid site dimentions!', ''));
                } else {
                  rows.push(createRow(typeMap[type]+' site - '+frontage+'m x '+depth+'m', basicCost[type][frontage][depth]));
                }
              }
            }
          }
      }
      
      }

    const noGas = ['gas_fri_85', 'gas_sat_85', 'gas_sun_85', 'gas_fri_15', 'gas_sat_15', 'gas_sun_15', 'gas_fri_45', 'gas_sat_45', 'gas_sun_45'].every(item => FormController.state.values['.site.gas.'+item] === 0 || FormController.state.values['.site.gas.'+item] === undefined || FormController.state.values['.site.gas.'+item] === '0');
      if (!noGas) {
        rows.push(createRow('Gas fee', 185)); //185
      }
      const outlets = values['.site.power.outlets'];
      if (outlets !== undefined && outlets > 0) {
        rows.push(createRow(outlets + ' Additional Power outlets', outlets *170));//170
      }

      const adjust = values['.finalise.cost_adjust'];
      if (adjust !== undefined && adjust !== 0) {
        rows.push(createRow(values['.finalise.cost_adjust_reason'], adjust*1));//170
      }

      rows.push(createRow("Total (inc GST)",
        rows.reduce((accum, row) => { return accum + row.cost; }, 0))
      )

    }
    return rows;
  }

    const toCurrency =(num) => {
      if (typeof num !== 'number') { return ''; }
      return(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }


  return {
    toCurrency,
    getEstimateData
  }
}

export default ApplicationHelpers;
