import React from 'react';

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';


import { useFormControllerContext } from "components/PM_FormController";
import { PM_Key } from "../PM_Conditionals";
import ApplicationHelpers from "./ApplicationHelpers";


function InputWrapper(props) {
  const FormController = useFormControllerContext();
  const { flags, setFlags } = props;
  return <props.FieldContainer key={FormController.getKey(props.q)} question={props.q} viewMode={props.viewMode} adminView={props.adminView} flags={flags} setFlags={setFlags} />
};


function EstimateLayout(props) {
  const FormController = useFormControllerContext();

  const {getEstimateData,toCurrency} = ApplicationHelpers({FormController:FormController});

  const rows = getEstimateData(FormController.state.values, props.match?.params?.year);


  return (
      <Grid container spacing={0}  >
      {props.adminView && <>
      <Grid item xs={12}>
        <InputWrapper q={FormController.getItemByKey('.finalise.cost_adjust')}  {...props} />
      </Grid>
      <Grid item xs={12}>
        <InputWrapper q={FormController.getItemByKey('.finalise.cost_adjust_reason')}  {...props} />
      </Grid>
        </>}

      <TableContainer component={Paper}>
           <Table  aria-label="simple table">
             <TableHead>
               <TableRow>
                 <TableCell><strong>Item</strong></TableCell>
                 <TableCell align="right"><strong>Cost (inc GST)</strong></TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {rows.map((row, ii) => {
                const last = ii === rows.length -1;
                const name = (last) ? <strong>{row.name}</strong> : row.name;
                const cost = (last) ? <strong>${toCurrency(row.cost)}</strong> : <>${toCurrency(row.cost)}</>;
                 return (
                 <TableRow key={row.name}>
                   <TableCell component="th" scope="row">
                     {name}
                   </TableCell>
                   <TableCell align="right">{cost}</TableCell>
                 </TableRow>);
                }
               )}
             </TableBody>
           </Table>
         </TableContainer>

      </Grid>
  );

}

export default EstimateLayout;
