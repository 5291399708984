import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { TextField, Grid, Button, Typography, Box, Divider } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

import Moment from 'moment';

import Conn_Admin from 'components/admin/Conn_Admin';
import FileInput from 'components/admin/FileInput';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  heading: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 },
 fileLink: {
   textDecoration: 'underline',
   fontWeight: 'bold',
   textAlign: 'right',
   cursor: 'pointer'
 },
notes: {
  height: '200px',
  overflowY: 'scroll',
  marginBottom: '20px',
  width: '100%'
}
}));

const NoteForm = (props) => {

  const { target_user_id,  sendCallback } = props;
  const [note, setNote] = React.useState('');
  const [fileID, setFileID] = React.useState(-1);

  const { createNote } = Conn_Admin();
  const handleCreate = () => {
    createNote(target_user_id, note, fileID, sendCallback);
    props.setDialogOpen(false);
  }

  // const handleDelete = (chipToDelete) => () => {
  //   setToUsers((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  // };
//
  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField variant="outlined" label="Message" value={note} onChange={(e) => setNote(e.target.value)}  fullWidth multiline rows={8}/>
    </Grid>
    <Grid item xs={12} container justify="center">
      <FileInput callback={setFileID} fileID={fileID} />
    </Grid>
    <Grid item xs={12}>
      <Button onClick={handleCreate} color="primary" variant="contained" fullWidth>
        <Typography variant="h6" >Add Note</Typography>
      </Button>
    </Grid>
  </Grid>);
}

const NoteDialog = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [target_user_id, setTarget_user_id] = React.useState(0);
  // const [sendCallback, setSendCallback] = React.useState((props && 'sendCallback' in props) ? props.sendCallback : () => {  });
  const classes = useStyles();
  return {
    AddNoteMarkup: (sendCallback) => { return(<>

    <Dialog
    open={dialogOpen}
    TransitionComponent={Transition}
    onClose={() => setDialogOpen(false)}
    maxWidth={'lg'}
    aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.heading} >
          Add Note to Application 
      </DialogTitle>
      <DialogContent>
          <NoteForm target_user_id={target_user_id} sendCallback={sendCallback} setDialogOpen={setDialogOpen} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)} color="secondary" variant="contained">
          <Typography variant="h6">cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog></>)},
    handleOpenAddNote: (target_user_id) => {
      setTarget_user_id(target_user_id);
      setDialogOpen(true);
    }
  }
}

const NoteSummary = (props) => {
  const classes = useStyles();

  const { getFileURL, getFileInfo } = Conn_Admin();
  const { noteDetail } = props;
  const [fileName, setFileName] = React.useState('');

  React.useMemo(() => {
    if (noteDetail.file_id !== -1) {
      getFileInfo(noteDetail.file_id, (details) => { setFileName(details.name); });
    }
  }, []);

  return <>
    <Grid item xs={3}><Typography>{Moment(noteDetail['date']).format('DD-MMM-YYYY ')}</Typography></Grid>
    <Grid item xs={9}><Typography><strong>{noteDetail['author']}</strong></Typography></Grid>
    {(noteDetail.file_id !== -1) && <Grid item xs={12}><Typography className={classes.fileLink} onClick={() => { window.open(getFileURL(noteDetail.file_id)); }}>{fileName}</Typography></Grid>}
    <Grid item xs={12}><Typography>{noteDetail['message']}</Typography></Grid>
    <Grid item xs={12}><Divider variant="middle" /></Grid>
  </>;
}

const NotesList = (props) => {
  const classes = useStyles();
  const [notes, setNotes] = React.useState([]);
  const { getNotesByTarget } = Conn_Admin();
  const {id} = props;
  const loadNotes = (userID) => {
    getNotesByTarget(userID, setNotes);
  };

  React.useMemo(() => {
    loadNotes(id);
  }, [props.refresh]);

  return (<>
    <Grid container xs={12}>
      <Grid container item justifyContent="center">
      <Typography  variant="h6" >
        Admin Notes
      </Typography>
    </Grid>
    <Grid item xs={12}><Divider variant="middle" /></Grid>
    <Grid container className={classes.notes}>
        { notes.map(note => <NoteSummary noteDetail={note} /> ) }
    </Grid>
    </Grid></>);
}


export { NotesList, NoteDialog };
