import React from 'react';

import { makeStyles } from '@material-ui/core/styles';


import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions  } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  heading: {
    background: '#793478',
    color: '#fff',
    borderBottom: '5px solid #fff',
   boxShadow: "0 0 10px rgba(0,0,0,.5)",
 },
 yes: {
   background: '#793478',
   color: '#fff',
   borderRadius: '15px'
 },
 no: {
   background: '#333',
   color: '#fff',
   borderRadius: '15px'
 }
}));


export default function useConfirmation(props) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({});
  const classes = useStyles();
  const handleClose = () => { setOpen(false); };
  const showConfirmation = () => { setOpen(true) };
  return { askForConfirmation: (newVals) => { setValues(newVals || props); showConfirmation(); },
    ConfirmationWindow: () => (<Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title" className={classes.heading}>{values && values.title || props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to {values &&  values.task || props.task} ?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className={classes.yes} onClick={() => { (values && values.onYes) ? values.onYes() : props.onYes(); handleClose();}} color="primary">
        Yes
      </Button>
      <Button className={classes.no} onClick={handleClose} autoFocus>
        No
      </Button>
    </DialogActions>
  </Dialog>)};

}
