import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { TextField, Grid, Button, Typography, Box, Modal } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';


import { PM_Key } from '../PM_Conditionals';
import Conn_Signing from '../signing/Conn_Signing';

import PM_FormController,{ useFormControllerContext } from "components/PM_FormController";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));



export default function ChangePassword(props) {
  const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const xmlData = `<?xml version="1.0" encoding="UTF-8" ?>
  <app id="login" onSubmit="props.getDoUpdatePassword()(state.values);">
    <question id="password" name="New Password">
      <config type="password" />
      <validator id="isRequired" />
      <validator id="isMinLength" message="Please make it more secure" length="6" />
    </question>

    <question id="password_confirmation" name="Password Confirmation">
      <config type="password" />
      <validator id="isRequired" />
      <validator id="isEqual" message="Your passwords don’t match" value="state.values.password"  />
    </question>
  </app>
  `;


  const config = fields => ({
    fields: {
      // username: {
      //   isRequired: { message: 'Please fill out a username' },
      // },

      password: {
        showErrors: 'blur',
        nice_name: "New Password",
        type: "password",
        validators: {
          isRequired: { message: 'Please enter a value for password' },
          isMinLength: { length: 6, message: 'Please make it more secure' }
        }
      },
      password2: {
        showErrors: 'blur',
        nice_name: "Password Confirmation",
        type: "password",
        validators: {
          isRequired: { message: 'Please enter a value for password one more time' },
          isEqual: { value: fields.password, message: 'Your passwords don\’t match' },
        }
      }
    },
    showErrors: 'hybrid',
    onSubmit: e => {
      doUpdatePassword(FormController.state.values);
    return false;  }
  });

  const FormController = PM_FormController({xmlData: xmlData});
  // const { getFieldProps, getFormProps, errors, isFormValid, values, dispatch } = PM_Validation(config);

const {  doUpdatePassword  } = Conn_Signing(FormController.dispatch);

  const handleOpen = () => {
     setOpen(true);
   };

   const handleClose = () => {
    setOpen(false);
  };

  const fieldProps = {}; //;FormController.getFieldProps(new PM_Key('password'));
   const body = (
      <form {...FormController.getFormProps}>
      <Grid container direction="column" alignItems="stretch" spacing={2}>
        <Grid item >

        </Grid>
          <Grid container item spacing={1} alignItems="flex-end">
            <Grid item>
                <LockIcon />
            </Grid>
            <Grid item xs >
            {
              //{...FormController.getFieldProps(new PM_Key('password'))}
            }
              <TextField

                variant="standard"
                fullWidth
              />
          </Grid>
          </Grid>
          <Grid container item spacing={1} alignItems="flex-end">
            <Grid item>
                <LockOutlinedIcon />
            </Grid>
            <Grid item xs >
            {
              // {...FormController.getFieldProps(new PM_Key('password2'))}
            }
              <TextField

                variant="standard"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item>
          <Box mt={2}>
            <Button variant="contained" fullWidth color="primary" type="submit" disabled={!FormController.isFormValid}>
               Update Password {(FormController.isFormValid) ? 'yes' : 'no'}
             </Button>
            </Box>
         </Grid>
        </Grid>
      </form>

);

  return {
    ChangePasswordDialogue: (

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <Typography variant="h5" color="primary">
              Change Password
            </Typography>
          </DialogTitle>
          <DialogContent>
            {body}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}  color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
  ),
  ChangePasswordOpener: handleOpen
  };

}
