const Banks = [
  "86 400 Bank",
  "AMP Bank Limited",
  "AWA Alliance Bank",
  "Arab Bank Australia Ltd",
  "Australia and New Zealand Banking Group Limited (ANZ)",
  "Australian Central Credit Union Ltd (trading as People's Choice Credit Union)",
  "Australian Military Bank (formerly Australian Defence Credit Union)",
  "Auswide Bank (formerly Wide Bay Building Society)",
  "B&E Limited (trading as Bank of us)",
  "BDCU Alliance Bank",
  "BOQ Specialist",
  "Bananacoast Community Credit Union Ltd",
  "Bank Australia (formerly bankmecu)",
  "Bank of China (Australia) Ltd",
  "Bank of Melbourne",
  "Bank of Queensland Limited",
  "Bank of Sydney Ltd",
  "Bank of Western Australia Limited (trading as Bankwest)",
  "BankSA",
  "BankVic",
  "Bendigo and Adelaide Bank Limited",
  "Big Sky Building Society Limited",
  "Border Bank",
  "CAPE Credit Union Limited",
  "CIRCLE Alliance Bank",
  "Central Coast Credit Union Ltd",
  "Central Murray Credit Union Limited",
  "Central West Credit Union Limited",
  "Citibank Australia and Citi",
  "Coastline Credit Union Limited",
  "Commonwealth Bank of Australia",
  "Community Alliance Credit Union Limited",
  "Community CPS Australia Limited (trading as Beyond Bank Australia)",
  "Community First Credit Union Limited",
  "Community Mutual Limited (trading as Regional Australia Bank)",
  "Community Sector Banking",
  "Credit Union Australia Ltd",
  "Credit Union SA Ltd",
  "Defence Bank",
  "Delphi Bank (formerly Bank of Cyprus Australia Limited)",
  "Dnister Ukrainian Credit Co-operative Limited",
  "EECU Limited (trading as Nexus Mutual)",
  "Esanda",
  "Family First Credit Union Limited",
  "Fire Service Credit Union Limited",
  "Firefighters & Affiliates Credit Co-operative Limited",
  "Firefighters Mutual Bank",
  "First Choice Credit Union Ltd",
  "First Option Credit Union Limited",
  "Ford Co-operative Credit Society Limited",
  "G&C Mutual Bank",
  "Gateway Bank",
  "Goulburn Murray Credit Union Co-operative Limited",
  "Greater Bank",
  "HSBC Bank Australia Ltd",
  "Heritage Bank",
  "Heritage Isle Credit Union Limited",
  "Holiday Coast Credit Union Ltd",
  "Horizon Credit Union Ltd",
  "Hume Bank",
  "Hunter United Employees' Credit Union Limited",
  "IMB Bank",
  "ING Bank (Australia) Ltd (trading as ING Direct)",
  "Judo Bank",
  "Laboratories Credit Union Limited",
  "Lithuanian Co-operative Credit Society 'Talka' Limited",
  "Lysaght Credit Union Ltd",
  "MCU Ltd",
  "MacArthur Credit Union Ltd",
  "Macquarie Bank Limited",
  "Macquarie Credit Union Limited",
  "Maitland Mutual Building Society Limited (The Mutual)",
  "Members Equity Bank Limited (trading as ME Bank)",
  "My Credit Union Limited",
  "MyState Bank",
  "National Australia Bank Limited (NAB)",
  "Newcastle Permanent Building Society Limited",
  "Northern Inland Credit Union Limited",
  "Nova Credit Union Limited",
  "Orange Credit Union Limited",
  "Police & Nurses Ltd (trading as P&N Bank)",
  "Police Bank",
  "Police Credit Union Limited",
  "Pulse Credit Union Limited",
  "QPCU limited (trading as QBANK)",
  "Qudos Mutual Limited (trading as Qudos Bank)",
  "Queensland Country Credit Union Limited",
  "Queenslanders Credit Union Limited",
  "RACQ Bank",
  "RSL Money",
  "Rabobank Australia Limited",
  "Railways Credit Union Limited (trading as MOVE)",
  "Reliance Bank",
  "Rural Bank Limited",
  "SERVICE ONE Alliance Bank",
  "Select Encompass Credit Union Ltd",
  "South West Slopes Credit Union Ltd",
  "South-West Credit Union Co-Operative Limited",
  "Southern Cross Credit Union Ltd",
  "St.George Bank Limited",
  "Summerland Financial Services Limited (trading as Summerland Credit Union)",
  "Suncorp Bank",
  "Sydney Credit Union Ltd",
  "Teachers Mutual Bank Limited",
  "The Broken Hill Community Credit Union Ltd",
  "The Capricornian Ltd",
  "The Gympie Credit Union Ltd",
  "The Rock",
  "Traditional Credit Union Limited",
  "Transport Mutual Credit Union Limited",
  "Tyro Payments",
  "UBank",
  "UniBank",
  "Unity Bank Limited",
  "Up Bank",
  "Victoria Teachers Ltd (trading as Victoria Teachers Mutual Bank)",
  "Volt Bank",
  "WAW Credit Union Co-Operative Limited",
  "Warwick Credit Union Ltd",
  "Westpac Banking Corporation",
  "Woolworths Employees' Credit Union Limited",
  "Xinja"
]
export default Banks;
