import React from 'react';

import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Button, Card, CardMedia, CardContent, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import useConfirmation from 'components/application_forms/Confirmation';

import Conn_Admin from 'components/admin/Conn_Admin';

import { useUserFeedback } from "components/UserFeedback";

const useStyles = makeStyles({
  root: {
    padding: '8px 16px',
    width: '100%'
  },
  media: {
    height: 240,
  },
  actionButton: {
    background: '#793478',
    borderRadius: "50%",
    padding: '10px',
    color: '#fff'
  }
});

const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      text: {
        color: "#000",
        '&::after': {
          display: 'none'
        }
      }
    }
  }
});

export default function FileInput(props) {
  const classes = useStyles();
  const { showMessage } = useUserFeedback();
  const { uploadFile, getFileURL, getFileInfo } = Conn_Admin();
  
  const [open, setOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  // const { values, errors, getFieldProps, config } = useValidatorContext();
  const { callback, fileID } = props;

  React.useMemo(() => {
    getFileInfo(fileID, (details) => { setFileName(details.name); });
  }, [fileID]);



  const removeFile = (fieldKey) => {
    // FormController.dispatch({
    //   type: 'change',
    //   payload: {
    //     [FormController.getKey(question)]: null,
    //     [FormController.getKey(question) + '_filename']: null
    //   }
    // });
    showMessage('file successfully removed', 'info');
    callback(-1);
  };

  const { ConfirmationWindow, askForConfirmation } = useConfirmation({ title: 'remove file', task: 'delete this file', onYes: () => { removeFile(props.fieldKey) } });


  // const [extra, setExtra] = React.useState({ [props.fieldKey]: {} });


 

  const addFunctionality = (props) => (<>
    <Button
      variant="contained"
      color="primary"
      endIcon={<NoteAddIcon />}
      onClick={() => setOpen(true)}
    >
      Add File
    </Button>
    <MuiThemeProvider theme={theme}>
      <DropzoneDialog
        acceptedFiles={['image/jpg', 'image/jpeg', 'application/pdf']}
        cancelButtonText={"cancel"}
        submitButtonText={"save and upload"}
        maxFileSize={25000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={(files) => {
          uploadFile(files[0], 'some_key', false, showMessage, callback);
          setOpen(false);
        }}
        dialogTitle="Select File"
        dropzoneText="Drag and drop file here, or click"
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </MuiThemeProvider>
  </>
  );


/*
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography noWrap>{FormController.state.values[FormController.getKey(question) + '_filename'] || getFileInfo(FormController.state.values[FormController.getKey(question)], question, FormController.getKey)}</Typography>
          </Grid>
          <Grid item xs={1} alignContent="flex-end">
            <VisibilityIcon className={classes.actionButton} onClick={() => { window.open(getImageURL(FormController.state.values[FormController.getKey(question)])) }} />
          </Grid>
          <Grid item xs={1} alignContent="flex-end">
            <DeleteForeverIcon className={classes.actionButton} disabled onClick={() => { askForConfirmation() }} />
          </Grid>
        </Grid>

*/


  return (
    <Paper className={classes.root} >
      {(fileID > 0) ? (
        <Grid container alignItems="center" >
          <Grid item xs={10}>
            <Typography noWrap>{fileName}</Typography>
          </Grid>
          <Grid item xs={1} alignContent="flex-end">
            <VisibilityIcon className={classes.actionButton} onClick={() => { window.open(getFileURL(fileID)) }} />
          </Grid>
          <Grid item xs={1} alignContent="flex-end">
            <DeleteForeverIcon className={classes.actionButton} onClick={() => { askForConfirmation() }} />
          </Grid>
        </Grid>
      ) : addFunctionality(props)}

      {/* errors[props.fieldKey] && <p>{errors[props.fieldKey]}</p>*/}
      <ConfirmationWindow />
    </Paper>
  )
}
