import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Box, AppBar, Divider, Toolbar, Typography, IconButton, Badge, Drawer, Grid, Button } from '@material-ui/core';
import { List, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';

import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import $ from 'jquery';

import { ReactComponent as Logo } from 'icons/Festivale Stacked 2025 Logo_Grey.svg';
import { useFormControllerContext, FormControllerProvider } from "components/PM_FormController";

import Conn_Signing from '../signing/Conn_Signing';



const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  magicbr: {
    whiteSpace: 'pre-line',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  logo: {
    padding: "32px",
    background: "#fff",
    position: "absolute",
    top: "0",
    left: 0 + "px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
    transformOrigin: "0 -20px",

  },
  logoShadow: {
    padding: "32px",
    background: "transparent",
    position: "absolute",
    zIndex: 1,
    top: "0",
    left: 0 + "px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "30px",
    transformOrigin: "0 -20px",
    boxShadow: "0 0 10px rgba(0,0,0,.5)",
    transition: "all 0.5s ease-out"
  },
  header: {
    "& * ": {
      transition: "all 0.5s ease-out"
    },
    zIndex: 10,
  },
  headerIcon: {
    background: "#fff",
    color: "#793478",
    padding: "8px",
    marginLeft: "8px",
    "&:hover": {
      background: "#ee680f"
    }
  },
  
  
  headerShard: {
    width: '477px',
    height: '255px',
    position: 'fixed',
    top: '40%',
    right: '0px',
    zIndex: 1,
    pointerEvents: 'none',
  },
  bgShard: {
    width: '709px',
    height: '365px',
    position: 'fixed',
    top: '20%',
    left: '-17px',
    zIndex: 1,
    pointerEvents: 'none',
  }
}));

const GetName = (props) => {
  const FormController = useFormControllerContext();
  return (<>
    {FormController.state.values['.stall.stall_name']}
    {(FormController.state.values['.stall.stall_name2'] !== undefined
      & FormController.state.values['.stall.co_branded'] === "yes2")
      ? ' & ' + FormController.state.values['.stall.stall_name2'] : ''}
  </>);
};

function Username() {
  const FormController = useFormControllerContext();
  return (
    <>
      {FormController.state.values['.personal.name_first']} {FormController.state.values['.personal.name_last']}
    </>
  )
}


export default function ApplicationHeader(props) {
  
  const [scrollPos, setScrollPos] = React.useState(0);
  const [state, setState] = React.useState({ open: false });


  const classes = useStyles();
  const menuId = 'primary-search-account-menu';

  if (!('small' in props)) {
    $(document).on("scroll", function () {
      setScrollPos(1 - Math.min(124, $(document).scrollTop()) / 124);
    });
    $(document).ready(() => setScrollPos(1 - Math.min(124, $(document).scrollTop()) / 124));
  }


  const toggleUserDrawer = open => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, open: open });
  };


  const { doLogout } = Conn_Signing();
  const handleLogout = () => { doLogout(); };

  return (<>
        <div style={{ position: "sticky", top: 0, zIndex: 9 }}>
          <div className={classes.logoShadow} style={{ transform: 'scale(' + (0.54 + 0.46 * scrollPos) + ')' }}>
            <Logo width="116px" />
          </div>
        </div>


        <Drawer anchor="right" open={state.open} onClose={toggleUserDrawer(false)}>
          <List>
            <ListItem button key={0}>
              <ListItemIcon><AccountCircle /></ListItemIcon>
              <ListItemText primary={<Username />} />
            </ListItem>
          </List>

          <Divider />
          {
            // <List>
            //   <ListItem button key={0}>
            //     <ListItemIcon><LockOpenIcon /></ListItemIcon>
            //     <ListItemText primary="Change Password" onClick={handleRequestChangePassword} />
            //   </ListItem>
            // </List>
          }
          <List>
            <ListItem button key={0}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="Logout" onClick={handleLogout} />
            </ListItem>
          </List>
        </Drawer>

        <AppBar position="sticky" className={classes.header}>
          <Toolbar>
            <div className={classes.logo} style={{ transform: 'scale(' + (0.54 + 0.46 * scrollPos) + ')' }}>
              <Logo width="116px" />
            </div>

            <Typography variant="h6" noWrap style={{ paddingLeft: (98 + 82 * scrollPos) + 'px' }} >
              Stallholders Application for Festivale 2025 : <GetName />
            </Typography>

            <div className={classes.grow} />
              <IconButton
                className={classes.headerIcon}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={toggleUserDrawer(true)}
                color="inherit"
                >
                  <AccountCircle />
              </IconButton>
          </Toolbar>
        </AppBar>

        <img src={process.env.PUBLIC_URL + '/shard_header.png'} className={classes.headerShard} />
        <img src={process.env.PUBLIC_URL + '/shard_background.png'} className={classes.bgShard} />

    
    </>);
}