import React from 'react';


import NumberFormat from 'react-number-format';

import {  InputAdornment,OutlinedInput, FormControl, InputLabel, FormHelperText, makeStyles } from '@material-ui/core';
import { useFormControllerContext } from "components/PM_FormController";

import WordCounter from '../WordCounter';



export default function Input_Text(props) {

  const useStyles = makeStyles((theme) => ({
    wordCount: {
      position: 'relative',
      textAlign: 'right',
      top: '-20px',
      pointerEvents: 'none'

    }
  }));

  const classes = useStyles();

  const FormController = useFormControllerContext();
  let extra = {};
  const question = props.question;
  // const config = question.configs[0];//FormController.config.fields[props.fieldKey.getConfigKey()];
  const fieldProps =  FormController.getFieldProps(question);
  delete fieldProps.helperText;
  let canEdit = ("canEdit" in props && props.canEdit);
  let wordCounter = "";
  let useNumberFormat = {};
  if (question.displays.length > 0 && question.displays[0].children.filter(child => child.type === "numberFormat").length > 0) {
    useNumberFormat = {...question.displays[0].children.filter(child => child.type === "numberFormat")[0], customInput:OutlinedInput};
  }

  // const useNumberFormat = (question.displays.length > 0 && "numberFormat" in question.displays[0]) ? {...question.displays[0].numberFormat, customInput:{OutlinedInput}} : {};

  function processName(question) {
    // const mand = (question.validators.length > 0 && question.validators.every(validator => validator.id === 'isRequired')) ? ' *' : '';
    return <>{question.name}</>;
  }


  const InputType = (Object.keys(useNumberFormat).length !== 0 ) ? NumberFormat : OutlinedInput;

  if (question.displays.length > 0) {
    if ("multiline" in question.displays[0]) {
      extra = { "multiline":true, rows: question.displays[0].multiline};
    }
    if ("prefix" in question.displays[0]) {
      extra = { ...extra, startAdornment: <InputAdornment position="start">{question.displays[0].prefix}</InputAdornment>}
    }
    if ("word_counter" in question.displays[0]) {
      wordCounter = <WordCounter field={fieldProps.value} limit={question.displays[0].word_counter} />;
      const onChangeRef = fieldProps.onChange;
      fieldProps.onChange = (e) => {
        const words = e.target.value.split(' ').length;
        if (words > question.displays[0].word_counter) return;
        onChangeRef(e, e.target.value);
      }
    }
    if ("placeholder" in question.displays[0]) {
      extra = {...extra, placeholder:question.displays[0].placeholder, focused:true};
    }
  }
  /*

  InputProps={{
              startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
            }}
  */

    return (
      <FormControl  variant="outlined" fullWidth>
        <InputLabel error={(fieldProps.error && true)}>{processName(question)}</InputLabel>
        <InputType
          {...useNumberFormat}
          
          disabled={!canEdit}
          {...fieldProps}
          error={(fieldProps.error && true)}
          variant="outlined"
          fullWidth
          {...extra}
  
        />

        <FormHelperText className={classes.wordCount}>{wordCounter}</FormHelperText>
        <FormHelperText error={(fieldProps.error && true)}>{fieldProps.error}</FormHelperText>
        <FormHelperText>{question.help_text}</FormHelperText>

        </FormControl>
    )
  }
