import React from 'react';

import { Button, Grid, FormControl, Select, TextField } from '@material-ui/core';

import ColumnSelect from './ColumnSelect';

import { useFormControllerContext } from "components/PM_FormController";
import { useAdminHelpersContext } from 'components/admin/Admin_Helpers';

class FilterData {

  constructor(target = '.system.id', comparitor = '', chainer='', value='') {
    this._target = target;
    this._comparitor = comparitor;
    this._chainer = chainer;
    this._value = value;
  }

  get target() { return this._target; }
  set target(value) { this._target = value; }

  get comparitor() { return this._comparitor; }
  set comparitor(value) { this._comparitor = value; }

  get chainer() { return this._chainer; }
  set chainer(value) { this._chainer = value; }

  get value() { return this._value; }
  set value(value) { this._value = value; }

  isValid() {
    return (this._target !== '');
  }

  compare(toCompare) {
    // console.log(this.comparitor);
    // console.log(this.value);
    // console.log(toCompare);
    if (this.comparitor === 'isEqual') {
      return (toCompare == this.value);
    }
    if (this.comparitor === 'isNotEqual') {
      return (toCompare != this.value);
    }
  }

}

const filterReducer = (filters, action) => {
  switch(action.type) {
    case 'create':
      let newFilter = new FilterData();
      if ('payload' in action) {
        newFilter = new FilterData(
          action.payload._target,
          action.payload._comparitor,
          action.payload._chainer,
          action.payload._value
        );
      }
      return [...filters, newFilter];
    case 'update':
      filters[action.payload.num][action.payload.target] = action.payload.val;
      return [...filters];
    case 'remove':
      filters.splice(action.payload.num, 1);
      return [...filters];

  }
}


const FilterTarget = (props) => {
  const handleChange = (e) => {
    props.handleChange({
        num:props.number,
        value: e.target.value,
        target: 'target'
      });
  }
  return (
  <Select native defaultValue="" value={props.selected}  onChange={handleChange} fullWidth  disabled={props.disabled}>
  <option>Please Choose</option>
  { props.options.filter(opt => opt.id && opt.id.lastIndexOf('.all.') === -1).map(opt => <option value={opt.id}>{opt.label}</option>) }
  </Select>
);
}

const FilterComparitor = (props) => {
  const handleChange = (e) => {
    props.handleChange({
        num:props.number,
        value: e.target.value,
        target: 'comparitor'});
  }
  return <>
  <Select native defaultValue="isEqual" value={props.selected}  onChange={handleChange} fullWidth  disabled={props.disabled}>
    <option>Please Choose</option>
    <option value="isEqual">is equal to</option>
    <option value="isNotEqual">is not equal to</option>
  </Select>
</>;
}


const FilterChainer = (props) => {

  return <>
  <Select native defaultValue="" value={props.selected}  onChange={props.handleChange} fullWidth  disabled={props.disabled}>
    <option value="and">and</option>
  </Select>
  </>

}

const FilterValue = (props) => {
  const FormController = useFormControllerContext();
  const Admin_Helpers = useAdminHelpersContext();


  const handleChange = (e) => {
    props.handleChange({
      num:props.number,
      value: e.target.value,
      target: 'value'});
  }

  let options = [];
  if (props.filter.target !== ''){
    if (props.filter.target.lastIndexOf('.system.') !== -1) {
      if (props.filter.target === '.system.status') {
        options =  Object.keys(Admin_Helpers.getStatusList()).map(id => {return {id:id, name:Admin_Helpers.getStatusList()[id] }});

        // options = [{id:1, name:'incompleted'}, {id:2, name:'completed'}];
      }
    } else if (props.filter.target.lastIndexOf('.special.') !== -1) {

    } else {
      const question = FormController.getNodeByKey(props.filter.target);
      if (["radio", "select", "check"].includes(question.configs[0].type)) {
        options = question.options;
      }
    }
  }
  if (options.length) {
    return (
      <Select native defaultValue="" value={props.filter.value} onChange={handleChange}   disabled={props.disabled}>
        <option>Please Choose</option>
        {
          options.map(opt => <option value={opt.name}>{opt.name}</option>)
        }
      </Select>
    );
  }

  return(<><TextField  onChange={handleChange} value={props.filter.value} label="Fiter Value"  disabled={props.disabled} /></>);
}


const Filters = (props) => {

  const [filters, filterDispatch] = React.useReducer(filterReducer, []);

  const { disabled } = props;

  const handleCreate = (e) => {
    filterDispatch({
      type: 'create'
    });

  }


  const handleFilterChange = (data) => {
    filterDispatch({
      type: 'update',
      payload: {
        num: data.num,
        val: data.value,
        target: data.target
      }
    });
  }

  const handleRemove =(num) => (e) => {
    filterDispatch({
      type: 'remove',
      payload: {
        num: num
      }
    });
  }

  const loadFilters = (inString) => {
    let data = JSON.parse(inString);
    data.forEach(filter => {
      filterDispatch({
        type: 'create',
        payload: filter
      });
    });
  };

  const filterList = filters.map((filter, ii) => (
    <>
      <Grid item xs={1}>
        {(ii !== 0) && <FilterChainer number={ii} selected={filter.chainer} disabled={disabled} />}
      </Grid>
      <Grid item xs={4}>
        <FilterTarget number={ii} options={props.headerCells} selected={filter.target} handleChange={handleFilterChange}  disabled={disabled} />
      </Grid>
      <Grid item xs={3}>
        <FilterComparitor number={ii} selected={filter.comparitor}  handleChange={handleFilterChange}  disabled={disabled} />
      </Grid>
      <Grid item xs={3}>
        <FilterValue number={ii} filter={filter} selected={filter.comparitor} handleChange={handleFilterChange}  disabled={disabled} />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={handleRemove(ii)} style={{color: (props.disabled) ? '#aaa' : '#E83530'}}  disabled={disabled}>remove</Button>
      </Grid>
    </>
  ));


  // const toString = () => {
  //   alert(JSON.stringify(filters));
  // }


  const passesFilters = (data) => {

    let pass = true;
    filters.forEach(filter => {
      // console.log(filter);
      // console.log(data[filter.target]+'<');
      // console.log(filter.target);
      // console.log(data);
      if (filter.isValid()) {

        if (!filter.compare(data[filter.target])) {
          pass=false;
        }
      }
    });
    return pass;
  }

  return {
    loadFilters,
    passesFilters,
    filters,
    FilterMarkup: (
    <>
    <Grid container>
      <Grid item xs={1}>
        <Button onClick={handleCreate} style={{color: (props.disabled) ? '#aaa' :  '#ee680f'}} disabled={disabled}>Add new filter</Button>
      </Grid>
      <Grid container item xs={5}>
        {filterList}
      </Grid>
    </Grid>

    </>
  )};

}

export default Filters;
