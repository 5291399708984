import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const UserFeedbackContext = React.createContext({});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function UserFeedbackProvider(props) {

    const [state, setState] = React.useState({open: false, message: '', type: ''});

    const showMessage = (message, type) => {
      setState({open: true, message: message, type: type});
    };


    const handleClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setState({...state, open: false});
    };

  const context = [showMessage];
  const value = React.useMemo(() => context, [context]);
  return (
    <UserFeedbackContext.Provider value={value} {...props}>
      {props.children}
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.type}>
          {state.message}
        </Alert>
      </Snackbar>
    </UserFeedbackContext.Provider>
  );
};

export function useUserFeedback() {
  const context = React.useContext(UserFeedbackContext);
  const [showMessage] = context;
  return {
    showMessage
  }
}
