import React from 'react';

import {  makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';


import { useFormControllerContext } from "components/PM_FormController";
import Conn_Application from "components/application_forms/Conn_Application";
import DrawItem from 'components/application_forms/DrawItem';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  accordion: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6
  },
  reversed: {
    padding: theme.spacing(4),
    width: '100%',
    'box-sizing': 'border-box',
    marginBottom: theme.spacing(4),
    border: "5px solid #793478",
    borderRadius: "15px",
    position: "relative",
    zIndex: 6,
    background: '#793478',
    '& *': {
      color: '#fff'
    }
  },
  form: {
    width: '100%',
    padding: theme.spacing(6),
    'box-sizing': 'border-box',
    '& .MuiFormHelperText-root': {
      color: '#888',
      fontSize: "10px",
      marginLeft: 0,
      "&.Mui-error": {
        color: "#ee680f",
        fontWeight: "bold"
      }
    }
  },
  button: {
   margin: theme.spacing(1)
 },
 magicAppbar: {
   zIndex: 4,
   borderBottom: "9px solid #fff",
   height: "73px",
   marginBottom: theme.spacing(4)
 },
 theButtons: {
   position: 'relative',
   zIndex: 8
 },
 headerIcon: {
   background: "#ee680f",
   color: "#793478",
   padding: "8px",
   marginLeft: "8px",
   "&:hover": {
     background: "#ee680f"
   }
 },
 pdf: {
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    borderTop: "2px solid black",
    marginTop: "20px"
 }
}));





// const ViewApplication = (props) => {

// return <>
// {/* <FormControllerProvider xmlData={xmlData}> */}
//   <ViewApplicationInner {...props} />
// {/* </FormControllerProvider> */}
// </>
// }



const ViewApplication = (props) => {
  const FormController = useFormControllerContext();
  const { state, dispatch, shouldDisplay } = FormController;
  const [flow, setFlow] = React.useState({...FormController.Flow});
  const  showMessage  = () => {};
  const { getLatestByPDFAccessKey } = Conn_Application(state, dispatch, showMessage);

  const shouldDisplayPDF = (group) => {
    if (group.displays.length > 0 && 'hideInPDF' in group.displays[0]) return false;
    return shouldDisplay(group);
  }

// was  React.useMemo
  React.useMemo(() => {
    if ('id' in props) {
      getLatestByPDFAccessKey(props.id);
    }
  }, []); //props

  const classes = useStyles();

  let screen_tabs = FormController.Flow?.children.filter((screen, index) => {
    return (screen.id !== 'extra' && screen.id !== 'welcome')
  }).map((screen, index) => {
    return (
      <>
        <Typography className={classes.pdf} variant="h4">{screen.name}</Typography><br />
        <FormContainer key={screen.name} classes={classes}>
        {
          screen.children
            .filter((group) => { 
              return shouldDisplayPDF(group) }) //&& group.children.filter((n) => shouldDisplay(n)).length > 0;
            .map((group) => (
              <DrawItem item={group} updateFlow={setFlow} viewMode={true} adminView={true} hideFlags={true} PDFView={true} />
          ))
        }
        </FormContainer>
      </>
    );
  });

  return (
    <>
      { screen_tabs }
    </>
  );

}

function FormContainer(props) {
    const FormController = useFormControllerContext();

  return (
    <form {...FormController.getFormProps()} >
      {props.children}
    </form>
  )
}

export default ViewApplication;
